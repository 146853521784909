import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CategoryListEntry from "../components/CategoryListEntry";
import {getCategories} from "../actions/category.action";
import BasePages from "./BasePages";

const CategoriesList = () => {
    const dataCategory = useSelector((state) => state.categoryReducer);
    const dataPartner = useSelector((state) => state.partnerReducer);
    const currentCity = useSelector((state) => state.cityReducer);
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    const [categoriesCity, setCategoriesCity] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getCategories());
    }, []);

    useEffect(() => {
        if (contentCurrentCity._id === undefined) {
            localStorage.setItem("contentCityMemo", "true");
        }
    }, [contentCurrentCity]);

    useEffect(() => {
        if (Array.isArray(dataCategory) && Array.isArray(dataPartner)) {
            let cityActiveCategories = [];
            dataCategory.forEach((cat) => {
                dataPartner.forEach((part) => {
                    if (
                        part.city === currentCity._id &&
                        part.category.includes(cat.name) &&
                        part.isActive
                    ) {
                        cityActiveCategories = [...cityActiveCategories, cat.name];
                    }
                });
            });
            setCategoriesCity(cityActiveCategories);
        }
    }, [dataCategory, dataPartner]);

    const handleFormChange = (e) => {
        e.preventDefault();
        const searchValue = e.target.value.toLowerCase();
        setSearchInput(searchValue);
        if (Array.isArray(dataCategory)) {
            const filteredCategories = dataCategory.filter((category) => {
                const hasPartners = dataPartner.some((partner) =>
                    partner.category.includes(category.name) && partner.isActive
                );
                return category.name.toLowerCase().includes(searchValue) && category.isActive && hasPartners;
            });
            setCategoriesCity(filteredCategories.map(cat => cat.name));
        }
    };

    return (
        <BasePages navPath={"accueil"}>
            <h1>Catégories</h1>
            <section className="categories-list">
                <div className="category-searchbar-container">
                    <input
                        type="text"
                        placeholder="Entrez le nom d'une catégorie que vous souhaitez chercher..."
                        onChange={(e) => handleFormChange(e)}
                    />
                </div>
                {Array.isArray(dataCategory) &&
                    dataCategory
                        .filter((category) => category.isActive)
                        .filter((category) => categoriesCity.includes(category.name))
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((category) => <CategoryListEntry key={category._id} category={category}/>)}
            </section>
        </BasePages>
    );
};

export default CategoriesList;