import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    resetReducerCityUploads,
    setBackgroundCity,
    setLogoCity,
} from "../actions/cityUploads.action";
import {setCity} from "../actions/city.action";
import {isEmpty, generatesubDomain} from "../assets/Utils";
import {pictureCompressor} from "../assets/utils/pictureCompressor";

const AdminNewCity = () => {
    const dataCityManagement = useSelector((state) => state.cityReducer);
    const [logoFile, setLogoFile] = useState(null);
    const [backgroundFile, setBackgroundFile] = useState(null);
    const [socialMediaNewCity, setSocialMediaNewCity] = useState({});
    const [newCity, setNewCity] = useState({});
    const dataUploadCity = useSelector((state) => state.cityUploadsReducer);
    const dispatch = useDispatch();

    const handleLogoChanged = (e) => {
        const file = e.target.files[0];
        setLogoFile(file);
    };

    const handleBackgroundChanged = (e) => {
        const file = e.target.files[0];
        setBackgroundFile(file);
    };

    const handleSocialMediaChanged = (e) => {
        const input = e.target;
        if (isEmpty(input.value)) {
            const tempSocialMedia = {...socialMediaNewCity};
            delete tempSocialMedia[input.name];
            setSocialMediaNewCity(tempSocialMedia);
            return;
        }
        setSocialMediaNewCity({
            ...socialMediaNewCity,
            [input.name]: input.value,
        });
    };

    const getCoordinates = async (city, postalCode) => {
        const apiKey = "3e4b994188404404a254eb09e60b8d1b";
        const query = `${city}, ${postalCode}`;
        const url = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(query)}&key=${apiKey}`;

        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.results.length > 0) {
                const {lat, lng} = data.results[0].geometry;
                return [lat, lng];
            }
            console.log("Coordinates not found for the city and postal code:", query);
        } catch (error) {
            console.error("Error:", error);
        }
        return null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.target;
        const cityName = form[1].value;
        const postalCode = form[2].value;

        let cityCoordinates = await getCoordinates(cityName, postalCode);
        if (!cityCoordinates) {
            console.log("Coordinates not found for the city:", cityName, "and postal code:", postalCode);
            cityCoordinates = [0, 0];
            return;
        }
        console.log("Coordinates found for the city:", cityName, "and postal code:", postalCode, ":", cityCoordinates);

        if (logoFile) {
            let formDataLogo = await pictureCompressor(logoFile);
            dispatch(await setLogoCity(formDataLogo));
        }

        if (backgroundFile) {
            let formDataPicture = await pictureCompressor(backgroundFile);
            dispatch(await setBackgroundCity(formDataPicture));
        }

        const newCity = {
            country: form[0].value,
            name: cityName,
            postalCode,
            coordinates: cityCoordinates,
            acronym: form[3].value,
            socialMedia: socialMediaNewCity,
            subDomain: generatesubDomain(cityName, dataCityManagement),
            isActive: false,
        };
        setNewCity(newCity);
        form.reset();
    };

    useEffect(() => {
        if(!isEmpty(newCity) && dataUploadCity.logo && dataUploadCity.background) {
            const newCitySubmit = {
                ...newCity,
                logo: dataUploadCity.logo.fileId,
                picture: dataUploadCity.background.fileId,
            }
            dispatch(setCity(newCitySubmit));
            setLogoFile(null);
            setBackgroundFile(null);
            setSocialMediaNewCity({});
            dispatch(resetReducerCityUploads());

        }
    }, [newCity, dataUploadCity.logo, dataUploadCity.background]);

    return (
        <section className="admin-new-cities">
            <h2>Création d'une nouvelle ville</h2>
            <form autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
                <div className="info-new-city">
                    <h3>Informations</h3>
                    <label htmlFor="country">Pays :</label>
                    <input
                        type="text"
                        id="country"
                        name="country"
                        defaultValue="France"
                        placeholder="Pays"
                        required
                    />
                    <label htmlFor="name">Nom :</label>
                    <input type="text" id="name" placeholder="Nom de la ville" required/>
                    <label htmlFor="postalCode">Code postal :</label>
                    <input
                        type="text"
                        id="postalCode"
                        placeholder="Code postal"
                        required
                    />
                    <label htmlFor="acronym">Acronyme :</label>
                    <input
                        type="text"
                        id="acronym"
                        pattern="[A-Z]{2,4}"
                        title="Acronyme de la ville (2 à 4 lettres en majuscules)"
                        placeholder="Acronyme"
                    />
                </div>
                <div className="files-upload">
                    <div className="input-container-uploads">
                        <h3>Fichiers</h3>
                        <div className="picture-container">
                            <label htmlFor="picture">
                                {!backgroundFile
                                    ? "Importer une image d'arrière plan"
                                    : "Modifier l'image d'arrière plan"}
                            </label>
                            <input
                                type="file"
                                name="file"
                                id="picture"
                                accept="image/*"
                                onChange={handleBackgroundChanged}
                                hidden
                            />
                            <div className="render-uploads-new-city">
                                {backgroundFile && (
                                    <div className="background-new-city">
                                        <h4>Image d'arrière plan</h4>
                                        <img
                                            src={URL.createObjectURL(backgroundFile)}
                                            alt="Arrière plan"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="logo-container">
                            <label htmlFor="logo">
                                {!logoFile ? "Importer un logo" : "Modifier le logo"}
                            </label>
                            <input
                                type="file"
                                name="file"
                                id="logo"
                                accept="image/*"
                                onChange={handleLogoChanged}
                                hidden
                            />
                        </div>
                    </div>
                    <div className="render-uploads-new-city">
                        {logoFile && (
                            <div className="logo-new-city">
                                <h4>Logo</h4>
                                <img
                                    src={URL.createObjectURL(logoFile)}
                                    alt="Logo"
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="social-media-new-city">
                    <h3>Réseaux sociaux</h3>
                    <label htmlFor="google">Google :</label>
                    <input
                        type="text"
                        id="google"
                        name="google"
                        pattern="https?://.+"
                        title="Exemple: https://www.google.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                    <label htmlFor="facebookp">Page Facebook :</label>
                    <input
                        type="text"
                        id="facebookp"
                        name="facebookp"
                        pattern="https?://.+"
                        title="Exemple: https://www.facebook.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                    <label htmlFor="facebookg">Groupe Facebook :</label>
                    <input
                        type="text"
                        id="facebookg"
                        name="facebookg"
                        pattern="https?://.+"
                        title="Exemple: https://www.facebook.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                    <label htmlFor="instagram">Instagram :</label>
                    <input
                        type="text"
                        id="instagram"
                        name="instagram"
                        pattern="https?://.+"
                        title="Exemple: https://www.instagram.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                    <label htmlFor="twitter">Twitter :</label>
                    <input
                        type="text"
                        id="twitter"
                        name="twitter"
                        pattern="https?://.+"
                        title="Exemple: https://www.twitter.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                    <label htmlFor="pinterest">Pinterest :</label>
                    <input
                        type="text"
                        id="pinterest"
                        name="pinterest"
                        pattern="https?://.+"
                        title="Exemple: https://www.pinterest.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                    <label htmlFor="tiktok">TikTok :</label>
                    <input
                        type="text"
                        id="tiktok"
                        name="tiktok"
                        pattern="https?://.+"
                        title="Exemple: https://www.tiktok.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                    <label htmlFor="youtube">Youtube :</label>
                    <input
                        type="text"
                        id="youtube"
                        name="youtube"
                        pattern="https?://.+"
                        title="Exemple: https://www.youtube.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                    <label htmlFor="linkedin">Linkedin :</label>
                    <input
                        type="text"
                        id="linkedin"
                        name="linkedin"
                        pattern="https?://.+"
                        title="Exemple: https://www.linkedin.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                </div>
                <input type="submit" value="Créer la ville"/>
            </form>
        </section>
    );
};

export default AdminNewCity;