import axios from "axios";

export const SET_AUDIO_GUIDED_TOUR = "SET_AUDIO_GUIDED_TOUR";
export const DELETE_AUDIO_GUIDED_TOUR = "DELETE_AUDIO_GUIDED_TOUR";
export const RESET_AUDIO_GUIDED_TOUR = "RESET_AUDIO_GUIDED_TOUR";

const API_URL = process.env.REACT_APP_API_URL;

export const setAudioGuidedTour = (audioGuidedTour) => {
    return async (dispatch) => {
        try {
            const res = await axios.post(
                `${API_URL}/upload/upload`,
                audioGuidedTour,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            dispatch({type: SET_AUDIO_GUIDED_TOUR, payload: res.data});
            return res.data;
        } catch (err) {
            console.log(err);
        }
    };
};

export const deleteAudioGuidedTour = (audioGuidedTour) => {
    return async (dispatch) => {
        try {
            const res = await axios.delete(
                `${API_URL}/upload/upload/${audioGuidedTour}`
            );
            dispatch({type: DELETE_AUDIO_GUIDED_TOUR, payload: res.data});
            return res.data;
        } catch (err) {
            console.log(err);
        }
    };
};

export const resetAudioGuidedTour = () => {
    return (dispatch) => {
        dispatch({type: RESET_AUDIO_GUIDED_TOUR});
    };
};