import axios from "axios";
import {UPDATE_USER} from "./user.action";

export const GET_NEWS = "GET_NEWS";
export const SET_NEWS = "SET_NEWS";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";

const API_URL = process.env.REACT_APP_API_URL;

export const getNews = () => {
    return async (dispatch) => {
        return axios
            .get(`${API_URL}/news`)
            .then((res) => {
                dispatch({type: GET_NEWS, payload: res.data});
            })
            .catch((err) => console.log(err));
    };
};

export const setNews = (news, userConnect) => {
    return async (dispatch) => {
        return axios
            .post(`${API_URL}/news`, news)
            .then((res) => {
                const newsId = res.data._id;
                dispatch({type: SET_NEWS, payload: res.data});

                const {password, ...userWithoutPassword} = userConnect.user;
                const updatedUser = {
                    ...userWithoutPassword,
                    news: [...userWithoutPassword.news, newsId]
                };

                return axios
                    .put(`${API_URL}/user/${userConnect.user._id}`, updatedUser)
                    .then((res) => {
                        dispatch({type: UPDATE_USER, payload: res.data});
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
    };
};

export const updateNews = (news) => {
    return async (dispatch) => {
        return axios
            .put(`${API_URL}/news/${news._id}`, news)
            .then((res) => {
                dispatch({
                    type: UPDATE_NEWS,
                    payload: {...res.data, ...news},
                });
            })
            .catch((err) => console.log(err));
    };
};

export const deleteNews = (news) => {
    return async (dispatch) => {
        return axios
            .delete(`${API_URL}/news/${news._id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_NEWS,
                    payload: news._id,
                });
            })
            .catch((err) => console.log(err));
    };
};