import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteGuidedTour, updateGuidedTour } from "../actions/guidedTour.action";
import { isEmpty } from "../assets/Utils";
import {
    deletePictureGuidedTour,
    resetPictureGuidedTour,
    setPictureGuidedTour,
} from "../actions/pictureGuidedTour.action";
import {
    deleteAudioGuidedTour,
    resetAudioGuidedTour,
    setAudioGuidedTour,
} from "../actions/contentGuidedTour.action";
import axios from "axios";
import { pictureCompressor } from "../assets/utils/pictureCompressor";

const AdminUpdateGuidedTour = ({ contentCityId, guidedTour, closePopup }) => {
    const [updatingGuidedTour, setUpdatingGuidedTour] = useState(guidedTour);
    const [dataCountries, setDataCountries] = useState([]);
    const [searchCountry, setSearchCountry] = useState("");
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [newAudio, setNewAudio] = useState([]);
    const [allAudioUploaded, setAllAudioUploaded] = useState({});
    const audioUploaded = useSelector((state) => state.contentGuidedTourReducer);
    const [currentCountryUploaded, setCurrentCountryUploaded] = useState("");
    const [pictureUpdateGuidedTour, setPictureUpdateGuidedTour] = useState(guidedTour.picture);
    const contentUpdateGuidedTour = useSelector((state) => state.pictureGuidedTourReducer);
    const [isFormValid, setIsFormValid] = useState(true);
    const [isAppleDevice, setIsAppleDevice] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        axios.get("https://restcountries.com/v3.1/all").then((res) => setDataCountries(res.data));
    }, []);

    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const appleDevices = ["iphone", "ipad", "macintosh"];
        setIsAppleDevice(appleDevices.some((device) => userAgent.includes(device)));
    }, []);

    const handleRemoveCountry = async (country) => {
        setSelectedCountry(selectedCountry.filter((c) => c.cca3 !== country.cca3));
        const updatedAudio = { ...allAudioUploaded };
        delete updatedAudio[country.cca3];
        setAllAudioUploaded(updatedAudio);
    };

    useEffect(() => {
        if (!isEmpty(audioUploaded)) {
            setAllAudioUploaded({
                ...allAudioUploaded,
                [currentCountryUploaded]: audioUploaded,
            });
            setNewAudio([...newAudio, audioUploaded]);
        }
    }, [audioUploaded]);

    useEffect(() => {
        if (guidedTour) {
            setUpdatingGuidedTour(guidedTour);
            setAllAudioUploaded(guidedTour.contentAudio || {});
            setSelectedCountry(
                Object.keys(guidedTour.contentAudio || {})
                    .map((cca3) => {
                        return dataCountries.find((country) => country.cca3 === cca3);
                    })
                    .filter(Boolean)
            );
        }
    }, [guidedTour, dataCountries]);

    useEffect(() => {
        if (!isEmpty(contentUpdateGuidedTour)) {
            setPictureUpdateGuidedTour(contentUpdateGuidedTour);
        }
    }, [contentUpdateGuidedTour]);

    const handleIsFormValid = () => {
        if (!isEmpty(updatingGuidedTour.name)) {
            setIsFormValid(true);
            return;
        }
        setIsFormValid(false);
    };

    const handlePictureUploaded = async (e) => {
        const picture = e.target.files[0];
        let formData = await pictureCompressor(picture);
        dispatch(await deletePictureGuidedTour(pictureUpdateGuidedTour));
        dispatch(await setPictureGuidedTour(formData));
    };

    const audioCompressor = async (audio) => {
        console.log(`Taille de l'audio avant compression : ${audio.size / 1024} Ko`);

        const formData = new FormData();
        formData.append("file", audio);
        formData.append("upload_preset", "my_audio_preset");
        formData.append("resource_type", "video");

        try {
            const response = await axios.post(
                "https://api.cloudinary.com/v1_1/dzyujvgoj/video/upload",
                formData
            );

            console.log("Audio compressé disponible à :", response.data.secure_url);
            return response.data.secure_url;
        } catch (err) {
            console.error("Erreur de compression audio :", err);
            throw new Error("Erreur lors de la compression de l'audio.");
        }
    };

    const handleAudioUploaded = async (e, countryCca3) => {
        const audio = e.target.files[0];
        try {
            const compressedAudioUrl = await audioCompressor(audio);
            const response = await axios.get(compressedAudioUrl, { responseType: 'blob' });
            const compressedAudioFile = new File([response.data], audio.name, { type: audio.type });

            console.log(`Taille de l'audio après compression : ${compressedAudioFile.size / 1024} Ko`);

            const formData = new FormData();
            formData.append("file", compressedAudioFile);
            setCurrentCountryUploaded(countryCca3);

            dispatch(setAudioGuidedTour(formData));
        } catch (error) {
            console.error("Erreur lors du traitement de l'audio :", error);
        }
    };

    const handleOpenAudio = (langCca3) => {
        const audioUrl =
            "https://api.lesbonsplans.pro" +
            "/upload/upload/audio/" +
            allAudioUploaded[langCca3];
        window.open(audioUrl, "_blank");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isFormValid) return;
        const form = e.target;

        const pictureInput = document.getElementById("picture-guided-tour");
        if (!pictureInput.files.length && !updatingGuidedTour.picture) {
            alert("Please upload a picture.");
            return;
        }

        let updatesGuidedTour = {};
        let isChanged = false;

        if (updatingGuidedTour.name !== guidedTour.name) {
            updatesGuidedTour = { ...updatesGuidedTour, name: updatingGuidedTour.name };
            isChanged = true;
        }

        if (pictureUpdateGuidedTour !== guidedTour.picture) {
            updatesGuidedTour = { ...updatesGuidedTour, picture: pictureUpdateGuidedTour };
            isChanged = true;
        }

        if (allAudioUploaded !== guidedTour.contentAudio) {
            Object.keys(guidedTour.contentAudio).forEach((audioId) => {
                if (!allAudioUploaded.hasOwnProperty(audioId)) {
                    dispatch(deleteAudioGuidedTour(guidedTour.contentAudio[audioId]));
                }
            });
            updatesGuidedTour = { ...updatesGuidedTour, contentAudio: allAudioUploaded };
            isChanged = true;
        }

        const latitude = parseFloat(form["latitude-guided-tour"].value.replace(",", "."));
        const longitude = parseFloat(form["longitude-guided-tour"].value.replace(",", "."));
        if (latitude !== guidedTour.coordinates[0] || longitude !== guidedTour.coordinates[1]) {
            updatesGuidedTour = { ...updatesGuidedTour, coordinates: [latitude, longitude] };
            isChanged = true;
        }

        if (isChanged) {
            updatesGuidedTour = { ...updatesGuidedTour, _id: updatingGuidedTour._id };
            dispatch(updateGuidedTour(updatesGuidedTour));
        }

        dispatch(resetAudioGuidedTour());
        dispatch(resetPictureGuidedTour());
        closePopup();
    };

    const handleDeleteAudio = () => {
        newAudio.forEach((audio) => {
            dispatch(deleteAudioGuidedTour(audio));
        });
    };

    const handleConfirmClick = () => {
        const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette visite guidée ?");
        if (!isConfirmed) {
            return;
        }
        dispatch(deleteGuidedTour(guidedTour, contentCityId));
        closePopup();
    };

    return (
        <section className="popup">
            <div className="popup-update-container">
                <div className="popup-update-header">
                    <h2>Modifier la visite guidée</h2>
                    <div className="cancel-button" onClick={() => {
                        handleDeleteAudio();
                        closePopup();
                    }}>
                        <i className="fas fa-times"></i>
                        <p>Annuler</p>
                        <i className="bx bx-x-circle"></i>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className="form-admin-update-guided-tour">
                    <div className="update-guided-tour-setup">
                        <div className="info-update-guided-tour">
                            <h3>Informations de la visite guidée</h3>
                            <div className="info-update-guided-tour-container">
                                <label htmlFor="name-guided-tour">Nom de la visite</label>
                                <input
                                    type="text"
                                    id="name"
                                    value={updatingGuidedTour.name}
                                    onChange={(e) =>
                                        setUpdatingGuidedTour({
                                            ...updatingGuidedTour,
                                            name: e.target.value,
                                        })
                                    }
                                    required
                                />
                                <label htmlFor="coordinates-guided-tour">Latitude du lieu</label>
                                <input
                                    type="text"
                                    id="latitude-guided-tour"
                                    name="latitude-guided-tour"
                                    value={updatingGuidedTour.coordinates[0]}
                                    onChange={(e) => {
                                        setUpdatingGuidedTour({
                                            ...updatingGuidedTour,
                                            coordinates: [
                                                e.target.value,
                                                updatingGuidedTour.coordinates[1],
                                            ],
                                        });
                                        handleIsFormValid();
                                    }}
                                    required
                                />
                                <label htmlFor="coordinates-guided-tour">Longitude du lieu</label>
                                <input
                                    type="text"
                                    id="longitude-guided-tour"
                                    name="longitude-guided-tour"
                                    value={updatingGuidedTour.coordinates[1]}
                                    onChange={(e) => {
                                        setUpdatingGuidedTour({
                                            ...updatingGuidedTour,
                                            coordinates: [
                                                updatingGuidedTour.coordinates[0],
                                                e.target.value,
                                            ],
                                        });
                                        handleIsFormValid();
                                    }}
                                    required
                                />
                            </div>
                            <section className="update-guided-tour-content">
                                <h3>Contenu</h3>
                                {!isEmpty(pictureUpdateGuidedTour) && (
                                    <img
                                        src={
                                            "https://api.lesbonsplans.pro/upload/upload/image/" +
                                            pictureUpdateGuidedTour
                                        }
                                        alt="image visite"
                                    />
                                )}
                                <input
                                    type="file"
                                    id="picture-guided-tour"
                                    name="picture-guided-tour"
                                    accept="image/*"
                                    onChange={handlePictureUploaded}
                                    hidden
                                />
                                <button
                                    type="button"
                                    className="label-btn"
                                    onClick={() =>
                                        document.getElementById("picture-guided-tour").click()
                                    }
                                >
                                    Modifier l'image
                                </button>
                            </section>
                            <section className="guided-tour-audio">
                                <div className="searchbar-country">
                                    <label htmlFor="language-audio">Chercher un pays :</label>
                                    <input
                                        type="text"
                                        id="language-audio"
                                        value={searchCountry}
                                        onChange={(e) => setSearchCountry(e.target.value)}
                                    />
                                </div>
                                <div className="flags-container">
                                    {dataCountries &&
                                        dataCountries
                                            .filter((country) =>
                                                country.translations.fra.common
                                                    .toLowerCase()
                                                    .includes(searchCountry.toLowerCase())
                                            )
                                            .filter(
                                                (country) =>
                                                    !selectedCountry.some((c) => c.cca3 === country.cca3)
                                            )
                                            .sort((a, b) => b.population - a.population)
                                            .slice(0, 30)
                                            .map((country) => (
                                                <div
                                                    key={country.cca3}
                                                    onClick={() => {
                                                        setSelectedCountry([...selectedCountry, country]);
                                                        setSearchCountry("");
                                                    }}
                                                >
                                                    <label htmlFor={country.cca3}>
                                                        {country.translations.fra.common}
                                                    </label>
                                                    <img
                                                        src={country.flags.png}
                                                        alt={"Drapeau " + country.translations.fra.common}
                                                    />
                                                </div>
                                            ))}
                                </div>
                                <div className="audio-each-language">
                                    {!isEmpty(selectedCountry) &&
                                        selectedCountry.map((country) => (
                                            <div key={country.cca3}>
                                                <i
                                                    onClick={() => handleRemoveCountry(country)}
                                                    className="bx bx-x-circle"
                                                ></i>
                                                <div className="center-audio-country">
                                                    <label htmlFor={country.cca3}>
                                                        {country.translations.fra.common}
                                                        <img
                                                            src={country.flags.png}
                                                            alt={"Drapeau " + country.translations.fra.common}
                                                        />
                                                    </label>
                                                    {allAudioUploaded[country.cca3] && (
                                                        <>
                                                            {isAppleDevice ? (
                                                                <button
                                                                    onClick={() => handleOpenAudio(country.cca3)}
                                                                    className="download-button"
                                                                >
                                                                    ▶ Ouvrir l'audio
                                                                </button>
                                                            ) : (
                                                                <audio controls>
                                                                    <source
                                                                        src={
                                                                            "https://api.lesbonsplans.pro" +
                                                                            "/upload/upload/audio/" +
                                                                            allAudioUploaded[country.cca3]
                                                                        }
                                                                        type="audio/mp3"
                                                                    />
                                                                </audio>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                <input
                                                    type="file"
                                                    id={country.cca3}
                                                    name={country.cca3}
                                                    accept="audio/*"
                                                    onChange={(e) => handleAudioUploaded(e, country.cca3)}
                                                    hidden
                                                />
                                            </div>
                                        ))}
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className="popup-footer">
                        <input type="submit" id="valid" value="Sauvegarder" />
                        <button type="button" className="popup-delete-button" onClick={handleConfirmClick}>Supprimer</button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default AdminUpdateGuidedTour;