import axios from "axios";

export const SET_LOGO_CITY = "SET_LOGO_CITY";
export const SET_BACKGROUND_CITY = "SET_BACKGROUND_CITY";
export const DELETE_ELEMENT_CITY = "DELETE_ELEMENT_CITY";
export const RESET_REDUCER_CITY_UPLOADS = "RESET_REDUCER_CITY_UPLOADS";

const API_URL = process.env.REACT_APP_API_URL;

export const setLogoCity = (data) => {
  return async (dispatch) => {
    return axios
      .post(`${API_URL}/upload/city`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({ type: SET_LOGO_CITY, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const setBackgroundCity = (data) => {
  return async (dispatch) => {
    return axios
      .post(`${API_URL}/upload/city`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({ type: SET_BACKGROUND_CITY, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const deleteElementCity = (id) => {
  return async (dispatch) => {
    return axios
      .delete(`${API_URL}/upload/city/${id}`)
      .then((res) => {
        dispatch({ type: DELETE_ELEMENT_CITY, payload: id });
      })
      .catch((err) => console.log(err));
  };
};

export const resetReducerCityUploads = () => {
  return async (dispatch) => {
    dispatch({ type: RESET_REDUCER_CITY_UPLOADS });
  };
};