import React from 'react';

const FlashMessage = ({ message, onClose, backgroundColor = 'green' }) => {

    return (
        <div className="flash-message" style={{ backgroundColor }}>
            <p>{message}</p>
            <button onClick={() => onClose()}>X</button>
        </div>
    );
};

export default FlashMessage;