import {
  GET_NEWS,
  SET_NEWS,
  UPDATE_NEWS,
  DELETE_NEWS,
} from "../actions/news.action";

const initialState = [];

export default function newsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NEWS:
      return action.payload;
    case SET_NEWS:
      return [action.payload, ...state];
    case UPDATE_NEWS:
      return state.map((news) =>
          news._id === action.payload._id ? action.payload : news
      );
    case DELETE_NEWS:
      return state.filter((news) => news._id !== action.payload);
    default:
      return state;
  }
}