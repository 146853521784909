import React from "react";
import {useLocation} from "react-router-dom";
import BasePages from "./BasePages";

const IframeLink = () => {
    const location = useLocation();
    const iframeLink = location.state?.Link;
    const title = location.state?.title;
    const headerTitle = location.state?.headerTitle;
    const headerRedirect = location.state?.headerRedirect;


    return (
        <BasePages navPath={headerRedirect ? headerRedirect : "decouverte"} stringBtn={headerTitle ? headerTitle : "Retourner à Découverte"}>
            <section className="iframe-link">
                <h1>{title}</h1>
                <div className="button-container">
                    <button className="btn btn-link" onClick={() => window.open(iframeLink, '_blank')}>
                        Si la page ne s'affiche pas ou qu'elle s'affiche mal, cliquez ici.
                    </button>
                </div>
                <section className="iframe-container">
                    <iframe title="actualites" src={iframeLink} frameBorder="0"></iframe>
                </section>
            </section>
        </BasePages>
    );
};

export default IframeLink;