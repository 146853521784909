import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {getDistance} from "geolib";
import PaginatedList from '../components/PaginatedList';
import useLocation from '../assets/utils/useLocation';
import PartnerListEntry from "../components/PartnerListEntry";
import BasePages from "./BasePages";
import {isEmpty} from "../assets/Utils";

const PartnersList = () => {
    const {citysubDomain, categorysubDomain} = useParams();
    const [currentCity, setCurrentCity] = useState({});
    const [currentCategory, setcurrentCategory] = useState({});
    const [filteredPartner, setFilteredPartner] = useState([]);
    const currentLocation = useLocation();
    const [sortedPartner, setSortedPartner] = useState([]);

    useEffect(() => {
        const fetchCity = async () => {
            try {
                let correctedSubDomain = citysubDomain;
                if (correctedSubDomain === 'lagrandemotte') {
                    correctedSubDomain = 'la-grande-motte';
                }
                if (correctedSubDomain === 'legrauduroi') {
                    correctedSubDomain = 'le-grau-du-roi';
                }
                const response = await fetch(`https://api.lesbonsplans.pro/city/search?subDomain=${correctedSubDomain}`);
                const data = await response.json();
                setCurrentCity(data);
            } catch (error) {
                console.error("Error fetching city data:", error);
            }
        };

        fetchCity();
    }, [citysubDomain]);

    useEffect(() => {
        const fetchCategory = async () => {
            if (!isEmpty(currentCity)) {
                try {
                    const response = await fetch(`https://api.lesbonsplans.pro/category/search?subDomain=${categorysubDomain}`);
                    const data = await response.json();
                    setcurrentCategory(data);
                } catch (error) {
                    console.error("Error fetching category data:", error);
                }
            }
        };

        fetchCategory();
    }, [categorysubDomain, currentCity]);

    useEffect(() => {
        const fetchPartner = async () => {
            if (!isEmpty(currentCity) && !isEmpty(currentCategory) && currentCategory.name) {
                try {
                    const response = await fetch(`https://api.lesbonsplans.pro/partner/searchByCityAndCategory?cityId=${currentCity._id}&categoryName=${currentCategory.name}`);
                    const data = await response.json();
                    setFilteredPartner(data.filter(part => part.isActive));
                } catch (error) {
                    console.error('Error fetching partners:', error);
                }
            }
        };

        fetchPartner();
    }, [currentCity, currentCategory]);

    useEffect(() => {
        if (!isEmpty(filteredPartner)) {
            const partnersWithDistance = filteredPartner.map((partner) => {
                if (currentLocation && partner.coordinates && partner.coordinates.length === 2 && partner.coordinates[0] !== null && partner.coordinates[1] !== null) {
                    const distance = getDistance(
                        {latitude: currentLocation.latitude, longitude: currentLocation.longitude},
                        {latitude: partner.coordinates[0], longitude: partner.coordinates[1]}
                    ) / 1000;
                    return {...partner, distance};
                } else {
                    return {...partner, distance: null};
                }
            });

            const sortedPartners = partnersWithDistance.sort((a, b) => {
                if (a.distance !== null && b.distance !== null) {
                    return a.distance - b.distance;
                } else if (a.distance === null && b.distance === null) {
                    return a.name.localeCompare(b.name);
                } else {
                    return a.distance !== null ? -1 : 1;
                }
            });

            setSortedPartner(sortedPartners);
        }
    }, [filteredPartner, currentLocation]);

    return (
        <BasePages navPath="categories" stringBtn="Retourner aux catégories">
            <h1>{currentCategory.name}</h1>
            <section className="partners-list">
                <PaginatedList
                    items={sortedPartner}
                    renderItem={(part) => <PartnerListEntry key={part._id} partner={part}
                                                            distance={part.distance ? part.distance.toFixed(2) : 'N/A'}/>}
                />
            </section>
        </BasePages>
    );
};

export default PartnersList;