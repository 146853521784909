import React, {useEffect, useState} from 'react';
import CitySelector from "../components/CitySelector";
import {resetReducer} from "../actions/partnerUploads.action";
import {useDispatch} from "react-redux";
import {createUser} from "../actions/user.action";
import {customStylesCitySelector} from "../assets/utils/frontSelect";
import Select from "react-select";
import StatusUser from "../components/StatusUser";
import {showFlashMessage} from "../actions/flashMessage.action";
import BasePagesBackoffice from "./BasePagesBackoffice";

const NewAccountUser = () => {
    const [formState, setFormState] = useState({
        selectedTypeUser: null,
        lastName: "",
        firstName: "",
        phoneNumber: "",
        email: "",
        codePostal: "",
        country: "",
        password: "",
        confirmPassword: "",
        passwordError: "",
        general_conditions: false,
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const [countries, setCountries] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const dispatch = useDispatch();

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleChangeCitySelector = (value) => {
        setFormState((prevState) => ({...prevState, valueCitySelector: value}));
    };

    const handleTypeUserChange = (e) => {
        const {id, checked} = e.target;
        if (id === "isUser" && checked) {
            document.getElementById("isProfessional").checked = false;
        } else if (id === "isProfessional" && checked) {
            document.getElementById("isUser").checked = false;
        }
        setFormState((prevState) => ({...prevState, selectedTypeUser: checked ? id : null}));
    };

    const handlePasswordChange = (e) => {
        const password = e.target.value;
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        let passwordError = "";
        if (!passwordPattern.test(password)) {
            passwordError = "Le mot de passe doit comporter au moins 8 caractères, dont 1 majuscule, 1 minuscule, 1 chiffre et 1 ponctuation";
        } else if (formState.confirmPassword && password !== formState.confirmPassword) {
            passwordError = "Les mots de passe ne correspondent pas";
        }
        setFormState((prevState) => ({...prevState, password, passwordError}));
    };

    const handleConfirmPasswordChange = (e) => {
        const confirmPassword = e.target.value;
        let passwordError = "";
        if (formState.password && confirmPassword !== formState.password) {
            passwordError = "Les mots de passe ne correspondent pas";
        }
        setFormState((prevState) => ({...prevState, confirmPassword, passwordError}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isFormValid === false) {
            return;
        }
        if (formState.password !== formState.confirmPassword) {
            setFormState((prevState) => ({...prevState, passwordError: "Les mots de passe ne correspondent pas"}));
            return;
        }

        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        if (!passwordPattern.test(formState.password)) {
            setFormState((prevState) => ({
                ...prevState,
                passwordError: "Le mot de passe doit comporter au moins 8 caractères, dont 1 majuscule, 1 minuscule, 1 chiffre et 1 ponctuation"
            }));
            return;
        }

        const newUser = {
            lastName: formState.lastName,
            firstName: formState.firstName,
            phoneNumber: formState.phoneNumber,
            email: formState.email,
            codePostal: formState.codePostal,
            country: formState.country,
            password: formState.password,
            general_conditions: formState.general_conditions,
            userLevels: formState.selectedTypeUser === "isUser" ? 4 : (formState.selectedTypeUser === "isProfessional" ? 5 : null),
        };

        setFormState((prevState) => ({...prevState, tempNewUser: newUser}));
        console.log(newUser);
        dispatch(createUser(newUser));
        resetForm();
    };

    const resetForm = () => {
        setFormState({
            selectedTypeUser: null,
            lastName: "",
            firstName: "",
            phoneNumber: "",
            email: "",
            codePostal: "",
            country: "",
            password: "",
            confirmPassword: "",
            passwordError: "",
            general_conditions: false,
        });

        setIsFormValid(false);
        document.getElementById("form-new-user").reset();
        dispatch(resetReducer());
        dispatch(showFlashMessage("Un email vous a été envoyé. Cliquez sur le bouton qu'il contient afin de valider votre compte.",
            "green", 5000, "/connexion"));
    };

    useEffect(() => {
        const isValid = formState.password &&
            formState.confirmPassword &&
            formState.passwordError === "" &&
            formState.selectedTypeUser !== null &&
            formState.lastName &&
            formState.firstName &&
            formState.phoneNumber &&
            formState.email &&
            formState.codePostal &&
            formState.country &&
            formState.general_conditions;

        setIsFormValid(isValid);
    }, [formState]);

    useEffect(() => {
        fetch("https://restcountries.com/v3.1/all")
            .then(response => response.json())
            .then(data => {
                const countryNames = data.map(country => country.name.common).sort();
                setCountries(countryNames);
            })
            .catch(error => console.error("Erreur lors de la récupération des pays :", error));
    }, []);

    return (
        <BasePagesBackoffice navPath={"connexion"} stringBtn={"Retourner à la page de connexion"} navBarAdmin={false}>
            <section className="new-account-user">
                <h2>Votre inscription</h2>
                <form id="form-new-user" onSubmit={handleSubmit} className="form-new-account-user">
                    <StatusUser
                        selectedTypeUser={formState.selectedTypeUser}
                        handleTypeUserChange={handleTypeUserChange}
                        openModal={openModal}
                        closeModal={closeModal}
                        isModalOpen={isModalOpen}
                    />
                    <div
                        className={`info-new-user ${formState.selectedTypeUser === "isUser" ? "center-content" : ""}`}>
                        {formState.selectedTypeUser !== null && (
                            <div className="info-personnels-new-user">
                                <input type="text" placeholder="Nom" autoComplete="new-lastname"
                                       onChange={(e) => setFormState((prevState) => ({
                                           ...prevState,
                                           lastName: e.target.value
                                       }))}
                                       required/>
                                <input type="text" placeholder="Prénom" autoComplete="new-firstname"
                                       onChange={(e) => setFormState((prevState) => ({
                                           ...prevState,
                                           firstName: e.target.value
                                       }))}
                                       required/>
                                <input type="tel" placeholder="Numéro de téléphone" autoComplete="new-tel"
                                       pattern="[0-9]{10}"
                                       title="Le numéro de téléphone doit comporter 10 chiffres."
                                       onChange={(e) => setFormState((prevState) => ({
                                           ...prevState,
                                           phoneNumber: e.target.value
                                       }))}
                                       required/>
                                <input type="email" placeholder="Adresse e-mail"
                                       autoComplete="new-address-email"
                                       onChange={(e) => setFormState((prevState) => ({
                                           ...prevState,
                                           email: e.target.value
                                       }))}
                                       required/>
                                <input type="text" placeholder="Code Postal" autoComplete="new-zip-code"
                                       pattern="[0-9]{5}"
                                       title="Le code postal doit comporter 5 chiffres."
                                       onChange={(e) => setFormState((prevState) => ({
                                           ...prevState,
                                           codePostal: e.target.value
                                       }))}
                                       required/>
                                <Select
                                    options={countries.map(country => ({value: country, label: country}))}
                                    onChange={(selectedOption) => setFormState((prevState) => ({
                                        ...prevState,
                                        country: selectedOption ? selectedOption.value : ""
                                    }))}
                                    styles={{
                                        ...customStylesCitySelector,
                                        control: (base) => ({
                                            ...base,
                                            margin: "10px 0",
                                            height: '50px',
                                            backgroundColor: "#e2b259",
                                        })
                                    }}
                                    value={formState.country ? {
                                        value: formState.country,
                                        label: formState.country
                                    } : null}
                                    placeholder="Sélectionnez un pays"
                                />
                                <input type="password" placeholder="Mot de passe" autoComplete="new-password"
                                       required
                                       onPaste={(e) => e.preventDefault()}
                                       onCopy={(e) => e.preventDefault()}
                                       onChange={handlePasswordChange}/>
                                <input type="password" placeholder="Confirmer le mot de passe"
                                       autoComplete="new-password" required
                                       onPaste={(e) => e.preventDefault()}
                                       onCopy={(e) => e.preventDefault()}
                                       onChange={handleConfirmPasswordChange}/>
                                {formState.passwordError &&
                                    <p style={{color: "red"}}>{formState.passwordError}</p>}
                            </div>
                        )}
                        {formState.selectedTypeUser === "isProfessional" && (
                            <div className="info-personnels-new-user">
                                <h3 className="hide-on-large">Informations professionnels</h3>
                                <input type="text" placeholder="Nom Entreprise" autoComplete="new-company-name"
                                       required/>
                                <input type="text" placeholder="Prénom Contact"
                                       autoComplete="new-contact-firstname" required/>
                                <input type="tel" placeholder="Adresse" autoComplete="new-address" required/>
                                <CitySelector
                                    onChange={handleChangeCitySelector}
                                    value={formState.valueCitySelector}
                                    isMulti={true}
                                    placeholder={"Sélectionnez une ou plusieurs villes"}
                                    required
                                />
                            </div>
                        )}
                    </div>
                    {formState.selectedTypeUser !== null && (
                        <>
                            <div className="general-condition">
                                <div className="general-condition-check-box-label">
                                    <input type="checkbox" id="general-condition" name="general-condition"
                                           onClick={(e) => setFormState((prevState) => ({
                                               ...prevState,
                                               general_conditions: e.target.checked
                                           }))
                                           }
                                           value="general-condition"
                                           required/>
                                    <p>
                                        En cochant la case ci-contre, vous acceptez nos <span
                                        onClick={openModal} style={{
                                        color: "#e2b259",
                                        cursor: "pointer",
                                        textDecoration: "underline"
                                    }}>Conditions
                                                    Générales d'Utilisation</span>.
                                    </p>
                                </div>
                                <p>Cliquez <a href="/assets/documents/VIBEN-MUSIC-CONDITIONS-UTILISATION.pdf"
                                              download="conditions-generales.pdf" style={{
                                    color: "#e2b259",
                                    cursor: "pointer",
                                    textDecoration: "underline"
                                }}>ici</a> pour télécharger nos conditions générales.</p>

                            </div>

                            <input
                                type="submit"
                                value="Créez votre compte"
                                style={{
                                    backgroundColor: isFormValid ? "#e2b259" : "black",
                                    color: isFormValid ? "black" : "white",
                                }}
                            />
                        </>
                    )}
                </form>
            </section>
        </BasePagesBackoffice>
    );
};

export default NewAccountUser;