import { HIDE_FLASH_MESSAGE, SHOW_FLASH_MESSAGE } from "../actions/flashMessage.action";

const initialState = null;

export default function flashMessageReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_FLASH_MESSAGE:
            return action.payload;
        case HIDE_FLASH_MESSAGE:
            if (action.payload.redirectPath !== undefined) {
                return { ...state, redirectPath: action.payload.redirectPath };
            }
            return null;
        default:
            return state;
    }
}