import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {forgotPassword} from "../actions/user.action";
import {showFlashMessage} from "../actions/flashMessage.action";
import BasePagesBackoffice from "./BasePagesBackoffice";

const ForgetPassword = () => {
    const [email, setEmail] = useState("");
    const dispatch = useDispatch();

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(showFlashMessage("Un email pour réinitialiser votre mot de passe vous a été envoyé.", "green",
                5000, "/connexion"));
            await dispatch(forgotPassword(email));
        } catch (error) {
            console.error("Error sending reset email:", error);
        }
    };

    return (
        <BasePagesBackoffice navPath={"connexion"} stringBtn={"Retourner à la page de connexion"} navBarAdmin={false}>
            <section className="forget-password">
                <h2>Mot de passe oublié</h2>
                    <form onSubmit={(e) => handleFormSubmit(e)}
                    className="form-forget-password">
                        <input
                            type="email"
                            placeholder="Entrez votre adresse-email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="submit"
                            value="Envoyer un email de réinitialisation"
                            disabled={!email}
                            id={email ? "valid" : "invalid"}
                        />
                    </form>
            </section>
        </BasePagesBackoffice>
    );
};

export default ForgetPassword;