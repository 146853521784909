import React, {useState} from 'react';
import PropTypes from 'prop-types';
import DeleteConfirmationModal from "../layouts/AdminPopupSuppression";

const AdminTable = ({columns, data, onDelete, itemName}) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    const handleDeleteClick = (item) => {
        setItemToDelete(item);
        setShowDeleteModal(true);

        const row = document.querySelector(`tr[data-id="${item._id}"]`);
        if (row) {
            row.classList.add('deleted');
        }
    };

    const handleConfirmDelete = () => {
        onDelete(itemToDelete);
        setShowDeleteModal(false);
        setItemToDelete(null);

        const row = document.querySelector(`tr[data-id="${itemToDelete._id}"]`);
        if (row) {
            row.classList.remove('deleted');
        }
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setItemToDelete(null);

        const row = document.querySelector(`tr[data-id="${itemToDelete._id}"]`);
        if (row) {
            row.classList.remove('deleted');
        }
    };

    return (
        <>
            <table className="admin-table">
                <thead>
                <tr>
                    {columns.map((col, index) => (
                        <th key={index}>{col.header}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.map((item, index) => (
                    <tr key={index} data-id={item._id}>
                        {columns.map((col, colIndex) => (
                            <td key={colIndex} data-label={col.header}>
                                {col.header === "Suppression" ? (
                                    <button onClick={() => handleDeleteClick(item)}>Supprimer</button>
                                ) : (
                                    col.render(item)
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
            <DeleteConfirmationModal
                show={showDeleteModal}
                onClose={handleCloseDeleteModal}
                onConfirm={handleConfirmDelete}
                who={itemName}
            />
        </>
    );
};

AdminTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string.isRequired,
            render: PropTypes.func.isRequired,
        })
    ).isRequired,
    data: PropTypes.array.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default AdminTable;