import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "../assets/Utils";
import ImageGallery from "../components/ImageGallery";
import {useParams} from "react-router";
import {setCurrentCity} from "../actions/city.action";
import {getContentCityById} from "../actions/contentCity.action";
import {useDispatch} from "react-redux";
import {getMagazine} from "../actions/magazine.action";
import BasePages from "./BasePages";

const Magazines = () => {
    const {citysubDomain} = useParams();
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    const dataMagazine = useSelector((state) => state.magazineReducer);
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseCity = await fetch(`https://api.lesbonsplans.pro/city/search?subDomain=${citysubDomain}`);
                const cityData = await responseCity.json();
                dispatch(setCurrentCity(cityData));


                if (cityData.contentCity) {
                    await dispatch(getContentCityById(cityData.contentCity));
                }

                if (isEmpty(dataMagazine)) {
                    await dispatch(getMagazine());
                }
            } catch (error) {
                console.error("Erreur lors du chargement des données :", error);
            }
        };

        fetchData();
    }, [citysubDomain, dispatch, dataMagazine]);

    const handleMagazineClick = (mag) => {
        setSelectedImages(mag.pictures.map(picture => ({
            url: `https://api.lesbonsplans.pro/upload/partner/image/${picture.id}`
        })));
        setGalleryOpen(true);
    };

    return (
        <BasePages navPath={"magazines"} stringBtn={"Retourner aux magazines"}>
            <h1>Tous nos magazines</h1>
            <section className="magazines">
                {!isEmpty(contentCurrentCity.magazines) &&
                    Array.isArray(dataMagazine) &&
                    dataMagazine
                        .filter((mag) => contentCurrentCity.magazines.includes(mag._id))
                        .reverse()
                        .map((mag) => (
                            <div
                                key={mag._id}
                                className="magazine"
                                onClick={() => handleMagazineClick(mag)}
                                style={{
                                    backgroundImage: `url(https://api.lesbonsplans.pro/upload/upload/image/${mag.pictures[0]?.id})`,
                                }}
                            >
                                <div className="name-mag">
                                    <p>{mag.name}</p>
                                    <p>{mag.numberMag}</p>
                                </div>
                            </div>
                        ))}
                {galleryOpen && (
                    <ImageGallery
                        images={selectedImages}
                        onClose={() => setGalleryOpen(false)}
                    />
                )}
            </section>
        </BasePages>
    );
};

export default Magazines;