import React, {useState} from 'react';
import {useParams} from "react-router";
import {useDispatch} from "react-redux";
import {resetPassword} from "../actions/user.action";
import {showFlashMessage} from "../actions/flashMessage.action";
import BasePagesBackoffice from "./BasePagesBackoffice";

const ResetPassword = () => {
    const dispatch = useDispatch();
    const {token} = useParams();
    const [formState, setFormState] = useState({
        password: "",
        confirmPassword: "",
        passwordError: "",
    });

    const handlePasswordChange = (e) => {
        const password = e.target.value;
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        let passwordError = "";
        if (!passwordPattern.test(password)) {
            passwordError = "Le mot de passe doit comporter au moins 8 caractères, dont 1 majuscule, 1 minuscule, 1 chiffre et 1 ponctuation";
        } else if (formState.confirmPassword && password !== formState.confirmPassword) {
            passwordError = "Les mots de passe ne correspondent pas";
        }
        setFormState((prevState) => ({...prevState, password, passwordError}));
    };

    const handleConfirmPasswordChange = (e) => {
        const confirmPassword = e.target.value;
        let passwordError = "";
        if (formState.password && confirmPassword !== formState.password) {
            passwordError = "Les mots de passe ne correspondent pas";
        }
        setFormState((prevState) => ({...prevState, confirmPassword, passwordError}));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (formState.password !== formState.confirmPassword) {
            setFormState((prevState) => ({...prevState, passwordError: "Les mots de passe ne correspondent pas"}));
            return;
        }
        try {
            await dispatch(resetPassword(token, formState.password));
            dispatch(showFlashMessage("Mot de passe modifié avec succès.", "green", 5000, "/connexion"));
        } catch (error) {
            dispatch(showFlashMessage("Erreur lors de la modification du mot de passe.", "red", 5000, "/connexion"));
            console.error("Error resetting password:", error);
        }
    };

    return (
        <BasePagesBackoffice navPath={"connexion"} stringBtn={"Retourner à la page de connexion"} navBarAdmin={false}>
            <section className="reset-password">
                <h2>Réinitialiser le mot de passe</h2>
                <form onSubmit={(e) => handleFormSubmit(e)}
                      className="form-reset-password">
                    <input type="password" placeholder="Mot de passe" autoComplete="new-password"
                           required
                           onPaste={(e) => e.preventDefault()}
                           onCopy={(e) => e.preventDefault()}
                           onChange={handlePasswordChange}/>
                    <input type="password" placeholder="Confirmer le mot de passe"
                           autoComplete="new-password" required
                           onPaste={(e) => e.preventDefault()}
                           onCopy={(e) => e.preventDefault()}
                           onChange={handleConfirmPasswordChange}/>
                    {formState.passwordError &&
                        <p style={{color: "red"}}>{formState.passwordError}</p>}
                    <input
                        type="submit"
                        value="Réinitialiser le mot de passe"
                        disabled={!formState.confirmPassword}
                        id={formState.confirmPassword ? "valid" : "invalid"}
                    />
                </form>
            </section>
        </BasePagesBackoffice>
    );
};

export default ResetPassword;