import React from "react";
import BackButton from "../components/BackButton";
import AccountButton from "../components/AccountButton";
import InstallPWA from "../components/InstallPwa";

const Header = ({LogoComponent, navPath, stringBtn, installPWA}) => {
    return (
        <header>
            <div className="header-buttons">
                {installPWA ? (
                    <InstallPWA/>
                ) : (
                    <BackButton
                    navPath={navPath ? navPath : ""}
                    stringBtn={stringBtn ? stringBtn : "Retourner à l'accueil"}
                />
                )}
                <AccountButton/>
            </div>
            <section className={`logo-container ${installPWA ? 'pwa-active' : ''}`}>
                <LogoComponent/>
            </section>
        </header>
    );
};

export default Header;