import axios from "axios";
import { deleteElement } from "./partnerUploads.action";

export const GET_PARTNER = "GET_PARTNER";
export const SET_PARTNER = "SET_PARTNER";
export const UPDATE_PARTNER = "UPDATE_PARTNER";
export const DELETE_PARTNER = "DELETE_PARTNER";

const API_URL = process.env.REACT_APP_API_URL;

export const getPartner = () => {
    return async (dispatch) => {
        return axios
            .get(`${API_URL}/partner`)
            .then((res) => {
                dispatch({ type: GET_PARTNER, payload: res.data });
            })
            .catch((err) => console.log(err));
    };
};

export const setPartner = (partner) => {
    return async (dispatch) => {
        return axios
            .post(`${API_URL}/partner`, partner)
            .then((res) => {
                dispatch({ type: SET_PARTNER, payload: partner });
            })
            .catch((err) => console.log(err));
    };
};

export const updatePartner = (partner) => {
    return async (dispatch) => {
        return axios
            .put(`${API_URL}/partner/${partner._id}`, partner)
            .then((res) => {
                dispatch({
                    type: UPDATE_PARTNER,
                    payload: { ...res.data, ...partner },
                });
            })
            .catch((err) => console.log(err));
    };
};

export const deletePartner = (partner) => {
    return async (dispatch) => {
        dispatch(await deleteElement(partner.logo));
        for (const upload of partner.contentUploads) {
            dispatch(await deleteElement(upload.id));
        }

        return axios
            .delete(`${API_URL}/partner/${partner._id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_PARTNER,
                    payload: partner._id,
                });
            })
            .catch((err) => console.log(err));
    };
};