import React from "react";
import BasePages from "./BasePages";

const PrivacyPolicy = () => {
    return (
        <BasePages navPath={"contact"} stringBtn={"retour à la page contact"}>
            <section className="privacy-policy">
                <h1>Conditions générales de services</h1>
                <br/>
                <h2>Article 1 - Dispositions générales et objet des CGS</h2>
                <p>
                    Le document suivant (CGS) régi la relation entre vous en tant que
                    client et notre société lorsque vous interagissez avec notre site
                    internet https://lesbonsplans.pro et/ou notre application Les Bons
                    Plans.
                </p>
                <br/>
                <p>
                    La navigation et/ou l'interaction avec notre site internet et/ou notre
                    application signifie que vous acceptez sans réserve ou objections ces
                    CGS.
                </p>
                <br/>
                <p>
                    Notre société a le droit de modifier ou d'adapter ces CGS à tout
                    moment et sans préavis. Ces CGS sont applicables dès qu'elles sont
                    publiées sur notre site internet et/ou application et/ou envoyées par
                    tous moyens.
                </p>
                <br/>
                <p>
                    Veuillez lire ces CGS avec attention avant d'utiliser, d'interagir ou
                    d'accéder à notre site internet et/ou notre application.
                </p>
                <br/>
                <p>
                    En acceptant ces CGS vous certifiez avoir atteint au minimum la
                    majorité légale dans votre pays, état ou province de résidence. Si
                    vous êtes mineur vous certifiez avoir tous les droits et autorisations
                    de la part de vos représentants légaux ou tuteurs légaux. Si vous
                    n'avez pas atteint la majorité légale alors vous ne devez pas utiliser
                    nos Services.
                </p>
                <br/>
                <p>
                    Vous n'êtes pas autorisés à utiliser nos Services, site internet et/ou
                    application de manière illégale ou à des fins non autorisées.
                </p>
                <br/>
                <p>
                    Vous ne devez pas essayer de hacker, d'altérer l'utilisation des
                    fonctions de nos Services, d'envoyer des viruses ni de conduire ou de
                    tenter de conduire des attaques à l'encontre de nos services. Vous ne
                    devez pas non plus essayer d'attenter à l'intégrité de nos services.
                </p>
                <br/>
                <br/>
                <h2>Article 2 - Contenus et propriété intellectuelle</h2>
                <p>
                    Le contenu de nos Services peut être accessible gratuitement ou non.
                    L'accès à certains de nos contenus peut nécessiter d'être connecté à
                    votre compte ou d'avoir un abonnement en cours de validité (IAP,
                    contenus restreints ou sections).
                </p>
                <br/>
                <p>
                    Les contenus de nos Services sont uniquement pour un usage personnel
                    et non-commercial. Tous les contenus disponibles sur nos Services sont
                    protégés par des copyrights et/ou par des droits de propriété
                    intellectuelle.
                </p>
                <br/>
                <p>
                    De plus, certains de nos contenus peuvent être protégés par d'autres
                    droits tels que, des brevets, marques déposées, secrets industriels et
                    commerciales, droits des bases de données, droits sui generis ou
                    d'autres droits de type propriété intellectuelle.
                </p>
                <br/>
                <p>
                    L'utilisateur de nos Services n'est pas autorisé à reproduire de
                    manière totale ou partielle toute ou partie des contenus qui sont
                    rendus disponibles au travers de nos Services. L'utilisateur
                    s'interdit de reproduire les logos, noms, identité visuelle ou tout
                    autre signe distinctif, il s'interdit aussi de produire toute copie ou
                    reproduction ou copie-servile de nos Services.
                </p>
                <br/>
                <p>
                    L'utilisateur s'interdit de modifier, de copier, de traduire, de
                    vendre, d'exploiter, de transmettre gratuitement ou non tout contenu,
                    texte, images, dessins, contenus audio, podcast ou tout autre contenu
                    disponible sur nos Services.
                </p>
                <br/>
                <br/>
                <h2>Article 3 - Garanties</h2>
                <p>
                    Les contenus de nos Services sont fournis à nos utilisateurs « tels
                    quels » et « sans aucune garantie », nous ne pouvons pas garantir que
                    le contenu mis à disposition est exact, exempt d'erreur ou d'omission
                    ou vrai. L'utilisateur accède à nos Services « à ses risques et périls
                    ».
                </p>
                <br/>
                <p>
                    Notre société ne sera pas tenue responsable si le contenu est inexacte
                    ou erroné.
                </p>
                <br/>
                <br/>
                <h2>
                    Article 4 - Modération des contenus (chat, commentaires et autres) et
                    contenus générés par les utilisateur
                </h2>
                <p>
                    Si les utilisateurs téléchargent sur notre site internet et/ou
                    application, postent, envoient tout type de contenus sur nos Services
                    vous nous certifiez que vous disposez de tous les droits et
                    autorisations nécessaires.
                </p>
                <br/>
                <p>
                    Vous devez vous abstenir de publier, distribuer ou télécharger tout
                    contenu qui est considéré comme abusif, fausse nouvelle, obscène,
                    pornographique, illégal. De plus, vous ne devez pas usurper l'identité
                    de tiers ou utiliser une fausse identité de manière à vous servir ou à
                    publier des contenus sur nos Services.
                </p>
                <br/>
                <p>
                    Vous ne devez pas utiliser nos Services pour transmettre tout type de
                    virus, cryptolocker, ransomware ou logiciel espion.
                </p>
                <br/>
                <p>
                    Les utilisateurs s'engagent à ne pas menacer, ne pas abuser
                    verbalement les autres utilisateurs, ne pas envoyer de spam sur nos
                    Services. Les utilisateurs utilisent un langage respectueux, ils
                    s'interdisent de discriminer sur la base de la religion, la race, la
                    nationalité, le genre sexuel ou leur préférence sexuelle, l'âge, le
                    handicap ou toute autre caractéristique. Les discours haineux sont
                    interdits.
                </p>
                <br/>
                <p>
                    Notre Société se réserve le droit de supprimer, modifier, censurer ou
                    supprimer le contenu généré par le client ou son compte si l'une des
                    règles ci-dessus est violée. Ceci sera effectué sans justification ou
                    mise en garde préalable. Le client ne sera en aucun cas dédommagé.
                </p>
                <br/>
                <br/>
                <h2>Article 5 - Responsabilité</h2>
                <p>
                    Notre société ne sera pas tenue responsable en cas de problème de
                    réseau, de virus, d'accès extérieur, d'utilisation frauduleuse des
                    moyens de paiement ou de tout autre type de problème technique ou
                    d'accès frauduleux.
                </p>
                <br/>
                <br/>
                <h2>Article 6 - Liens de tiers et liens externes</h2>
                <p>
                    Certains des contenus accessibles sur notre site internet et/ou
                    application peuvent comporter des contenus émanant de tiers ou de
                    sources extérieures. Les liens tiers si notre site internet et/ou
                    application peuvent vous conduire vers des sites extérieurs qui ne
                    sont pas sous le contrôle de notre Société et qui n'ont pas de lien
                    avec notre Société. Nous ne sommes pas responsables pour le contrôle
                    de l'exactitude des contenus émanant de sites tiers ou externes.
                </p>
                <br/>
                <p>
                    Notre Société n'est pas responsable en cas de dommages ou de mésusage
                    lors de l'accès à des sites tiers ou de l'utilisation de liens
                    externes sur notre site et/ou application.
                </p>
                <br/>
                <p>
                    Veuillez lire notre politique de confidentialité pour connaître la
                    manière dont nous gérons les sites et les liens tiers.
                </p>
                <br/>
                <br/>
                <h2>Article 7 - Exclusion de garantie</h2>
                <p>
                    Lors de l'utilisation de notre site internet et/ou application vous
                    nous certifiez que nous ne serons pas tenus responsable si les données
                    de nos Services n'est pas exact, vrai, complet ou correct. Les
                    informations et les données figurant sur nos Services sont données à
                    titre d'illustration et d'information seulement, les utilisateurs ne
                    doivent pas prendre de décisions basées uniquement sur nos contenus.
                    Les utilisateurs doivent donc rechercher d'autres sources avant
                    d'effectuer tout action importante. Vous accédez à nos Services « à
                    vos risques et périls ».
                </p>
                <br/>
                <p>
                    Notre société se réserve le droit de modifier et/ou de supprimer tout
                    contenu sur nos Services sans préavis, mais notre Société n'a aucune
                    obligation de mettre à jour le contenu disponible sur nos Services.
                </p>
                <br/>
                <p>
                    De plus, notre Société ne garantit pas que l'utilisation de nos
                    Services se fera sans erreur, sera sécurisée ou ininterrompue.
                    L'utilisateur accepte que l'on puisse retirer des services de temps à
                    autre et d'en ajouter de nouveaux sans préavis.
                </p>
                <br/>
                <p>
                    Nos Services sont fournis à nos utilisateurs « tels quels » et « en
                    l'état » et ceci sans garantie.
                </p>
                <br/>
                <p>
                    Dans tous les cas, nos employés, personnel, agents, stagiaires ne
                    seront pas tenus responsables pour toute perte, réclamation, dommages
                    direct ou indirect, dommages et intérêts de tout type. Cela inclut
                    toute perte de profit, perte de revenu, perte de données que cela se
                    base sur la responsabilité contractuelle ou extracontractuelle.
                </p>
                <br/>
                <br/>
                <h2>Article 8 - Indemnisation</h2>
                <p>
                    En tant que client de notre société, vous acceptez de nous indemniser,
                    de nous défendre et de nous dégager de toute responsabilité en cas de
                    réclamation ou de demande, y compris les honoraires d'avocat, faite
                    par un tiers en raison de votre violation des présentes CGS ou de tout
                    autre document qui vous lie à notre société.
                </p>
                <br/>
                <br/>
                <h2>Article 9 - Divisibilité</h2>
                <p>
                    Si une partie, un article ou un document des présentes CGS ou de tout
                    autre document contraignant entre vous et notre société est jugé par
                    une juridiction compétente comme étant illégal, nul ou inapplicable,
                    cette disposition sera néanmoins applicable dans toute la mesure
                    permise par la loi applicable.
                </p>
                <br/>
                <p>
                    La partie inapplicable sera considérée comme séparée de ces CGS ;
                    cette décision n'affectera pas la validité et l'applicabilité des
                    autres dispositions restantes.
                </p>
                <br/>
                <br/>
                <h2>Article 10 - Résiliation</h2>
                <p>
                    Toutes les obligations et responsabilités des parties qui ont pris
                    effet avant la date de résiliation survivront à la résiliation du
                    présent accord.
                </p>
                <br/>
                <p>
                    Ces CGS sont en vigueur à moins qu'elles ne soient résiliées soit par
                    notre société, soit par l'utilisateur.
                </p>
                <br/>
                <p>
                    L'utilisateur peut notifier à notre société qu'il ne souhaite plus
                    utiliser nos services ou il peut simplement cesser d'utiliser et/ou
                    d'accéder à nos Services, sites web et/ou applications.
                </p>
                <br/>
                <p>
                    Notre société peut résilier le présent contrat à sa seule discrétion,
                    à tout moment et sans préavis, le client restant dès lors redevable de
                    tout montant restant dû à notre Société.
                </p>
                <br/>
                <br/>
                <h2>Article 11 - Droit applicable et juridiction compétente</h2>
                <p>
                    Toute question découlant des présentes CGS concernant, sans s'y
                    limiter, leur validité, leur interprétation, leur exécution, leurs
                    conséquences et ainsi de suite, sera plaidée devant la juridiction
                    compétente.
                </p>
                <br/>
                <p>
                    La juridiction compétente est celle des Tribunaux de Montpellier
                    (France)
                </p>
                <br/>
                <br/>
                <h2>Article 12 - Contact</h2>
                <p>
                    Si vous avez des questions concernant les présentes Conditions
                    Générales, vous pouvez nous contacter directement à l'adresse suivante
                    : <a href="mailto: info@viben.fr"> info@viben.fr </a>
                </p>
            </section>
        </BasePages>
    );
};

export default PrivacyPolicy;
