import React, {useEffect, useState} from 'react';
import CitySelector from "../components/CitySelector";
import {useDispatch, useSelector} from "react-redux";
import {pictureCompressor} from "../assets/utils/pictureCompressor";
import {deleteElement, resetReducer, setInterviewPhotoPartner, setLogoPartner} from "../actions/partnerUploads.action";
import {setAudioGuidedTour} from "../actions/contentGuidedTour.action";
import {updateAlbum} from "../actions/album.action";
import AdminNewMusic from "./AdminNewMusic";

const AdminUpdateAlbum = ({album, closePopup}) => {
    const [formState, setFormState] = useState({
        _id: album._id,
        isFormValid: false,
        valueCitySelector: [],
        logoUpdateAlbum: {file: album.logoAlbum, fileURL: null},
        logoUpdateGroupe: {file: album.logoGroupe, fileURL: null},
        name: album.name,
        description: album.description,
        costParticulier: album.cost.particulier,
        costPro: album.cost.pro,
        musics: album.content.map((music) => ({
            id: music._id,
            audioSrc: music.song,
            title: music.title,
            cost: music.cost,
            timePreview: music.timePreview
        }))
    });

    const [originalAlbum] = useState(album);
    const dataCity = useSelector((state) => state.cityReducer);
    const dataContentCity = useSelector((state) => state.contentCityReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        setFormState((prevState) => ({
            ...prevState,
            valueCitySelector: getCityByContentCity()
        }));
    }, []);

    useEffect(() => {
        const isFormValid = formState.name !== '' &&
            formState.description !== '' &&
            formState.valueCitySelector.length !== 0 &&
            formState.costParticulier !== '' &&
            formState.costPro !== '' &&
            formState.logoUpdateAlbum !== null &&
            formState.logoUpdateGroupe !== null &&
            !formState.musics.some(music => music.audioSrc === null) &&
            !formState.musics.some(music => music.title === '') &&
            !formState.musics.some(music => music.cost === '') &&
            !formState.musics.some(music => music.timePreview === '');

        if (formState.isFormValid !== isFormValid) {
            setFormState((prevState) => ({...prevState, isFormValid}));
        }
    }, [formState]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormState((prevState) => ({...prevState, [name]: value}));
    };

    const handleChangeCitySelector = (values) => {
        setFormState((prevState) => ({...prevState, valueCitySelector: values}));
    };

    const handleLogoUpload = async (e, logoType) => {
        const file = e.target.files[0];
        if (file) {
            const fileURL = URL.createObjectURL(file);
            setFormState((prevState) => ({
                ...prevState,
                [logoType]: {file, fileURL},
            }));
        }
    };

    const handleMusicChange = (musics) => {
        setFormState((prevState) => ({...prevState, musics}));
    };

    const getCityByContentCity = () => {
        let contentCities = dataContentCity.filter((contentCity) =>
            contentCity.albums && contentCity.albums.some(albums => albums === album._id)
        );
        return dataCity.filter((city) => contentCities.some(contentCity => contentCity._id === city.contentCity))
            .map(city => ({label: city.name, value: city.name}));
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formState.isFormValid) {
            alert("Veuillez remplir tous les champs obligatoires");
            return;
        }

        let updatesAlbum = {
            _id: formState._id,
            name: formState.name,
            description: formState.description,
            cost: {
                particulier: formState.costParticulier,
                pro: formState.costPro
            },
            content: []
        };
        let isChanged = false;

        if (formState.name !== originalAlbum.name) {
            isChanged = true;
        }
        if (formState.description !== originalAlbum.description) {
            isChanged = true;
        }
        if (formState.costParticulier !== originalAlbum.cost.particulier) {
            isChanged = true;
        }
        if (formState.costPro !== originalAlbum.cost.pro) {
            isChanged = true;
        }

        if (formState.logoUpdateAlbum.file !== originalAlbum.logoAlbum) {
            const formData = await pictureCompressor(formState.logoUpdateAlbum.file);
            const logoData = await dispatch(setLogoPartner(formData));
            dispatch(deleteElement(album.logoAlbum));
            updatesAlbum.logoAlbum = logoData.fileId;
            isChanged = true;
        }

        if (formState.logoUpdateGroupe.file !== originalAlbum.logoGroupe) {
            const formData = await pictureCompressor(formState.logoUpdateGroupe.file);
            const interviewPhotoData = await dispatch(setInterviewPhotoPartner(formData));
            dispatch(deleteElement(album.logoGroupe));
            updatesAlbum.logoGroupe = interviewPhotoData.fileId;
            isChanged = true;

        }

        // Analyser formState.musics et originalAlbum.content
        const originalMusicIds = originalAlbum.content.map(music => music._id);
        const formStateMusicIds = formState.musics.map(music => music.id);


        // Dispatcher les musics qui sont dans originalAlbum mais pas dans formState
        for (const originalMusic of originalAlbum.content) {
            if (!formStateMusicIds.includes(originalMusic._id)) {
                await dispatch(deleteElement(originalMusic.song));
                isChanged = true;
            }
        }

        // Ajouter à updatesAlbum les musics qui sont dans formState et originalAlbum
        for (const music of formState.musics) {
            if (originalMusicIds.includes(music.id)) {
                updatesAlbum.content.push({
                    id: music.id,
                    title: music.title,
                    song: music.audioSrc,
                    cost: music.cost,
                    timePreview: music.timePreview
                });
            }
        }

        if (formState.musics.some(music => music.audioSrc instanceof File)) {
            for (const music of formState.musics) {
                if (music.audioSrc instanceof File) {
                    const formData = new FormData();
                    formData.append("file", music.audioSrc);
                    const audioData = await dispatch(setAudioGuidedTour(formData));
                    updatesAlbum.content.push({
                        title: music.title,
                        song: audioData.fileId,
                        cost: music.cost,
                        timePreview: music.timePreview
                    });
                    isChanged = true;
                }
            }
        }


        let contentcityOld = dataContentCity
            .filter(contentCity => contentCity.albums && contentCity.albums.includes(album._id))
            .map(contentCity => contentCity._id);

        let contentcityNew = formState.valueCitySelector.map(city => getContentCityByCityName(city.value));

        let news = contentcityNew.filter(id => !contentcityOld.includes(id));
        let old = contentcityOld.filter(id => !contentcityNew.includes(id));


        if (isChanged || news.length > 0 || old.length > 0) {
            updatesAlbum._id = formState._id;
            console.log(updatesAlbum);
            dispatch(updateAlbum(updatesAlbum, news, old));
            dispatch(resetReducer());

        }
        closePopup();

    };


    const getContentCityByCityName = (cityName) => {
        const city = dataCity.find((city) => city.name === cityName);
        return city.contentCity;
    }

    const customStylesUpdateAlbum = {
        control: (provided) => ({
            ...provided,
            bottom: '5px',
            border: '2px solid black',
            borderRadius: '10px',
            boxShadow: 'none',
            '&:focus': {
                outline: 'none',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'black',
            fontSize: '15px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
            fontSize: '15px',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'black',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: "black",
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            backgroundColor: "#e2b259",
            color: "white",
            "&:hover": {
                backgroundColor: "red",
                color: "white",
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: 'black',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            border: '2px solid black',
            borderRadius: '10px',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'lightgray' : 'white',
            color: 'black',
            fontSize: '15px',
        }),
    };

    return (
        <section className="popup">
            <div className="popup-update-container">
                <div className="popup-update-header">
                    <h2>Modifier un album</h2>
                    <div className="cancel-button" onClick={closePopup}>
                        <p>Annuler</p>
                        <i className="bx bx-x-circle"></i>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className="form-admin-update-album">
                    <div className="info-album">
                        <div>
                            <div className="label-input">
                                <label htmlFor="name">Titre :</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formState.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="label-input">
                                <label htmlFor="city">Ville :</label>
                                <CitySelector
                                    onChange={handleChangeCitySelector}
                                    value={formState.valueCitySelector}
                                    isMulti={true}
                                    placeholder={"Sélectionnez une ou plusieurs villes"}
                                    customStyles={customStylesUpdateAlbum}
                                />
                            </div>
                        </div>
                        <div className="label-input">
                            <label htmlFor="description">Description :</label>
                            <textarea
                                id="description"
                                name="description"
                                value={formState.description}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div>
                            <div className="label-input">
                                <label htmlFor="costParticulier">Coût Particulier :</label>
                                <input
                                    type="number"
                                    id="costParticulier"
                                    name="costParticulier"
                                    value={formState.costParticulier}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="label-input">
                                <label htmlFor="costPro">Coût Pro :</label>
                                <input
                                    type="number"
                                    id="costPro"
                                    name="costPro"
                                    value={formState.costPro}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="info-photo">
                        <div className="popup-update-content-container">
                            <div className="label-input">
                                <label htmlFor="logoUpdateAlbum" className="button-label">
                                    Modifier le logo de l'album
                                </label>
                                <input type="file" id="logoUpdateAlbum"
                                       onChange={(e) => handleLogoUpload(e, 'logoUpdateAlbum')}
                                       hidden/>
                                {formState.logoUpdateAlbum && (
                                    <section className="uploaded-logo">
                                        <h3>Logo album ajouté</h3>
                                        <img
                                            src={formState.logoUpdateAlbum.fileURL ? formState.logoUpdateAlbum.fileURL : "https://api.lesbonsplans.pro/upload/partner/image/" + formState.logoUpdateAlbum.file}
                                            alt="Logo ajouté" width={200}/>
                                    </section>
                                )}
                            </div>

                            <div className="label-input">
                                <label htmlFor="logoUpdateGroupe" className="button-label">
                                    Modifier le logo du groupe
                                </label>
                                <input type="file" id="logoUpdateGroupe"
                                       onChange={(e) => handleLogoUpload(e, 'logoUpdateGroupe')}
                                       hidden/>
                                {formState.logoUpdateGroupe && (
                                    <section className="uploaded-logo">
                                        <h3>Logo groupe ajouté</h3>
                                        <img
                                            src={formState.logoUpdateGroupe.fileURL ? formState.logoUpdateGroupe.fileURL : "https://api.lesbonsplans.pro/upload/partner/image/" + formState.logoUpdateGroupe.file}
                                            alt="Logo ajouté" width={200}/>
                                    </section>
                                )}
                            </div>
                        </div>
                    </div>
                    <section className="new-music">
                        <AdminNewMusic musics={formState.musics} onMusicChange={handleMusicChange}/>
                    </section>
                    <div className="form-row">
                        <input
                            type="submit"
                            value="Mettre à jour l'album"
                            id={formState.isFormValid ? "valid" : "invalid"}
                        />
                    </div>
                </form>
            </div>
        </section>
    );
};

export default AdminUpdateAlbum;