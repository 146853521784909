import React from "react";
import Select from "react-select";
import CitySelector from "../components/CitySelector";
import {isEmpty} from "../assets/Utils";

const ProfessionalInfo = ({
                              formState,
                              handleStatusChange,
                              handleChangeCitySelector,
                              handleLogoUpload,
                              handleRedactorsChange,
                              dataUser,
                              customStylesCitySelector
                          }) => {
    return (
        <div className="info-professionels-new-user">
            <h3>Informations professionnelles</h3>
            <Select
                options={[
                    {value: 1, label: "Administrateur global"},
                    {value: 2, label: "Superviseur"},
                    {value: 3, label: "Rédacteur"},
                    {value: 4, label: "Particulier"},
                    {value: 5, label: "Professionnel"},
                ]}
                styles={customStylesCitySelector}
                name="statut"
                id="statut"
                placeholder="Statut"
                onChange={handleStatusChange}
                required
            />
            {(formState.selectedStatus && (formState.selectedStatus.value === 2 || formState.selectedStatus.value === 3)) && (
                <>
                    <input type="text" placeholder="Organe de presse" autoComplete="new-press" required/>
                    {formState.selectedStatus.value === 3 && (
                        <CitySelector
                            onChange={handleChangeCitySelector}
                            value={formState.valueCitySelector}
                            isMulti={true}
                            placeholder={"Sélectionnez une ou plusieurs villes"}
                            required
                        />
                    )}
                    <label htmlFor="picture-content" className="button-label">
                        {isEmpty(formState.logoNewUser) ? "Ajouter un logo pour l'organe de presse" : "Modifier le logo de l'organe de presse"}
                    </label>
                    <input type="file" id="picture-content" onChange={handleLogoUpload} hidden/>
                    {formState.logoNewUser && (
                        <section className="uploaded-logo">
                            <h3>Logo ajouté</h3>
                            <img src={formState.logoNewUser} alt="Logo ajouté" width={200}/>
                        </section>
                    )}
                </>
            )}
            {formState.selectedStatus && formState.selectedStatus.value === 2 && (
                <Select
                    options={dataUser
                        .filter((user) => Array.isArray(user.userLevels) && user.userLevels.includes(3))
                        .map((user) => ({
                            value: user._id,
                            label: `${user.lastName} ${user.firstName}`,
                        }))}
                    styles={customStylesCitySelector}
                    isMulti={true}
                    placeholder="Sélectionnez un ou plusieurs rédacteurs"
                    onChange={handleRedactorsChange}
                />
            )}
            {formState.selectedStatus && formState.selectedStatus.value === 5 && (
                <>
                    <input type="text" placeholder="Nom Entreprise" id="companyName" autoComplete="new-lastname"
                           required/>
                    <input type="tel" placeholder="Adresse" id="adress"
                           autoComplete="new-adress"
                           required/>
                    <CitySelector
                        onChange={handleChangeCitySelector}
                        value={formState.valueCitySelector}
                        isMulti={true}
                        placeholder={"Sélectionnez une ou plusieurs villes"}
                        required
                    />
                </>
            )
            }
        </div>
    )
        ;
};

export default ProfessionalInfo;