import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    CLEAR_CART,
    UPDATE_CART_ITEM,
    LOAD_CART_FROM_LOCAL_STORAGE,
} from "../actions/cart.action";

const loadCartFromLocalStorage = () => {
    const cart = localStorage.getItem("cart");
    return cart ? JSON.parse(cart) : { items: [] };
};

const initialState = loadCartFromLocalStorage();

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_CART_FROM_LOCAL_STORAGE:
            return loadCartFromLocalStorage();

        case ADD_TO_CART:
            const isAlreadyInCart = state.items.some(item => item._id === action.payload._id);
            if (isAlreadyInCart) {
                return state;
            }
            const newStateAdd = {
                ...state,
                items: [...state.items, action.payload],
            };
            localStorage.setItem("cart", JSON.stringify(newStateAdd));
            return newStateAdd;

        case REMOVE_FROM_CART:
            const newStateRemove = {
                ...state,
                items: state.items.filter((item) => item._id !== action.payload._id),
            };
            localStorage.setItem("cart", JSON.stringify(newStateRemove));
            return newStateRemove;

        case UPDATE_CART_ITEM:
            const newStateUpdate = {
                ...state,
                items: state.items.map((item) =>
                    item._id === action.payload._id ? action.payload : item
                ),
            };
            localStorage.setItem("cart", JSON.stringify(newStateUpdate));
            return newStateUpdate;

        case CLEAR_CART:
            const newStateClear = {
                ...state,
                items: [],
            };
            localStorage.setItem("cart", JSON.stringify(newStateClear));
            return newStateClear;

        default:
            return state;
    }
}