import axios from "axios";
import { deletePartner } from "./partner.action";
import { deletePictureGuidedTour } from "./pictureGuidedTour.action";
import { deleteContentCity } from "./contentCity.action";

const API_URL = process.env.REACT_APP_API_URL;
export const GET_CITY = "GET_CITY";
export const SET_CITY = "SET_CITY";
export const UPDATE_CITY = "UPDATE_CITY";
export const CURRENT_CITY = "CURRENT_CITY";
export const DELETE_CITY = "DELETE_CITY";

export const getCity = () => {
    return async (dispatch) => {
        return axios
            .get(`${API_URL}/city`)
            .then((res) => {
                dispatch({ type: GET_CITY, payload: res.data });
            })
            .catch((err) => console.log(err));
    };
};

export const setCity = (city) => {
    return async (dispatch) => {
        return axios
            .post(`${API_URL}/city`, city)
            .then((res) => {
                dispatch({ type: SET_CITY, payload: city });
            })
            .catch((err) => console.log(err));
    };
};

export const updateCity = (city) => {
    return async (dispatch) => {
        return axios
            .put(`${API_URL}/city/${city._id}`, city)
            .then((res) => {
                dispatch({ type: UPDATE_CITY, payload: { ...res.data, ...city } });
            })
            .catch((err) => console.log(err));
    };
};

export const setCurrentCity = (city) => {
    return async (dispatch) => {
        dispatch({ type: CURRENT_CITY, payload: city });
    };
};

export const deleteCity = (city, partners, contentCity) => {
    return async (dispatch) => {
        try {
            dispatch(await deletePictureGuidedTour(city.picture));
            dispatch(await deletePictureGuidedTour(city.logo));

            await Promise.all(partners.map((partner) => dispatch(deletePartner(partner))));

            dispatch(await deleteContentCity(contentCity));
            await axios.delete(`${API_URL}/city/${city._id}`);
            dispatch({
                type: DELETE_CITY,
                payload: city._id,
            });
        } catch (err) {
            console.log(err);
        }
    };
};