import React, {useEffect, useState} from 'react';
import CitySelector from "../components/CitySelector";
import {isEmpty} from "../assets/Utils";
import AdminSortingPartnerUploads from "../components/AdminSortingPartnerUploads";
import {deleteMessage, updateMessage} from "../actions/message.action";
import {
    deleteElement, reorderElements,
    resetReducer,
    setLogoPartner,
    setPicturePartner,
    setVideoPartner
} from "../actions/partnerUploads.action";
import {useDispatch, useSelector} from "react-redux";

const AdminUpdateMessage = ({message, closePopup}) => {
    const dataCity = useSelector((state) => state.cityReducer);
    const [deletedUploadIds, setDeletedUploadIds] = useState([]);
    const [valueCitySelector, setValueCitySelector] = useState([]);
    const [updatingMessage, setUpdatingMessage] = useState(message);
    const [logoUpdateMessage, setLogoUpdateMessage] = useState(message.logo);
    const [uploadsUpdateMessage, setUploadsUpdateMessage] = useState(
        message.contentUploads
    );
    const [links, setLinks] = useState(message.url || ['']);
    const [isFormValid, setIsFormValid] = useState(true);
    const contentUpdateMessage = useSelector(
        (state) => state.partnerUploadsReducer
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEmpty(contentUpdateMessage.uploads)) {
            setUploadsUpdateMessage(contentUpdateMessage.uploads);
        }
    }, [contentUpdateMessage.uploads]);

    useEffect(() => {
        if (!isEmpty(contentUpdateMessage.logo)) {
            setLogoUpdateMessage(contentUpdateMessage.logo);
        }
    }, [contentUpdateMessage.logo]);

    useEffect(() => {
        let tempDefaultCity = [];
        message.city.forEach((city) => {
            tempDefaultCity.push({
                label: city,
                value: city,
            });
        });
        setValueCitySelector(tempDefaultCity);
        dispatch(reorderElements(uploadsUpdateMessage));
    }, [dispatch, message.city, uploadsUpdateMessage]);

    const handleIsFormValid = () => {
        if (!isEmpty(updatingMessage.title)) {
            setIsFormValid(true);
            return;
        }
        setIsFormValid(false);
    };

    const handleChangeCitySelector = (values) => {
        setValueCitySelector(values);
        let tempCity = [];
        values.forEach((city) => {
            tempCity.push(city.value);
        });
        setUpdatingMessage({
            ...updatingMessage,
            city: tempCity,
        });
        handleIsFormValid();
    };

    const handleLogoUpload = async (e) => {
        const file = e.target.files[0];
        let formDataLogo = new FormData();
        formDataLogo.append("file", file);
        dispatch(await deleteElement(logoUpdateMessage));
        dispatch(await setLogoPartner(formDataLogo));
        handleIsFormValid();
    };

    const handlePictureUpload = async (e) => {
        const file = e.target.files[0];
        let formDataPicture = new FormData();
        formDataPicture.append("file", file);
        dispatch(await setPicturePartner(formDataPicture));
        setUploadsUpdateMessage([...uploadsUpdateMessage, { id: file.name, type: "picture" }]);
        handleIsFormValid();
    };

    const handleVideoUpload = async (e) => {
        const file = e.target.files[0];
        let formDataVideo = new FormData();
        formDataVideo.append("file", file);
        dispatch(await setVideoPartner(formDataVideo));
        handleIsFormValid();
    };

    const newPictureUpload = () => {
        const newUploads = contentUpdateMessage.uploads.filter(upload =>
            !message.contentUploads.some(messageUpload => messageUpload.id === upload.id)
        );
        return newUploads.map(upload => upload.id);
    };
    
    const resetUpdatePopup = async ()  => {
        const newPicture = newPictureUpload();
        for (const upload of newPicture) {
            dispatch(await deleteElement(upload));
        }
        dispatch(resetReducer());
        closePopup({});
    };

    const getContentCityByCityName = (cityName) => {
        const city = dataCity.find((city) => city.name === cityName);
        return city.contentCity;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isFormValid) return;
        const form = e.target;
        let updatesMessage = {};

        const formData = new FormData(form);
        let isChanged = false;
        
        if(updatingMessage.title !== formData.get("title")) {
            updatesMessage = {...updatesMessage, title: formData.get("title")};
            isChanged = true;
        }
        if(updatingMessage.content !== formData.get("description")) {
            updatesMessage = {...updatesMessage, content: formData.get("description")};
            isChanged = true;
        }
        if(logoUpdateMessage !== message.logo) {
            updatesMessage = {...updatesMessage, logo: contentUpdateMessage.logo};
            isChanged = true;
        }

        if (uploadsUpdateMessage !== message.contentUploads) {
            for (const upload1 of message.contentUploads
                .filter(upload => !uploadsUpdateMessage.some(updatedUpload => updatedUpload.id === upload.id))) {
                dispatch(await deleteElement(upload1.id));
            }

            updatesMessage = {
                ...updatesMessage,
                contentUploads: uploadsUpdateMessage
            };

            isChanged = true;
        }

        if (updatingMessage.city !== message.city) {
            if(updatingMessage.city.length === 0) {
                window.alert("Veuillez sélectionner au moins une ville");
                return;
            }
            updatesMessage = { ...updatesMessage, city: updatingMessage.city };
            isChanged = true;
        }

        let contentCityId = updatingMessage.city.map(city => getContentCityByCityName(city));


        if(updatingMessage.datePublication !== formData.get("datePublication")) {
            updatesMessage = {...updatesMessage, datePublication: formData.get("datePublication")};
            isChanged = true;
        }
        
        if(links !== message.url) {
            updatesMessage = {...updatesMessage, url: links};
            isChanged = true;
        }

        if (isChanged) {
            updatesMessage = { ...updatesMessage, _id: message._id };
            dispatch(updateMessage(updatesMessage, contentCityId));
        }
        dispatch(resetReducer());
        closePopup({});
    };

    const handleAddLink = () => {
        setLinks([...links, '']);
    };

    const handleLinkChange = (index, event) => {
        const UpdateLinks = [...links];
        UpdateLinks[index] = event.target.value;
        const filteredLinks = UpdateLinks.filter(link => link.trim() !== '');
        setLinks(filteredLinks);
    };

    const handleDeletedIdsChange = (ids) => {
        setDeletedUploadIds(ids);
        const updatedUploads = uploadsUpdateMessage.filter(upload => !ids.includes(upload.id));
        setUploadsUpdateMessage(updatedUploads);
    };

    const handleConfirmClick = () => {
        const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette visite guidée ?");
        if (!isConfirmed) {
            return;
        }
        let contentCityId = [];
        for(const name of message.city) {
            contentCityId.push(getContentCityByCityName(name));
        }
        dispatch(deleteMessage(message, contentCityId));
        closePopup();
    };

    return (
        <section className="popup">
            <div className="popup-update-container">
                <div className="popup-update-header">
                    <h2>Modification du message</h2>
                    <div className="cancel-button" onClick={resetUpdatePopup}>
                        <p>Annuler</p>
                        <i className="bx bx-x-circle"></i>
                    </div>
                </div>
                <form id="form-update-message" autoComplete="off" onSubmit={handleSubmit}>
                    <section className="update-message-setup">
                        <div className="info-update-message">
                            <h3>Informations</h3>
                            <label htmlFor="title">Titre : </label>
                            <input
                                type="text"
                                id="title"
                                name="title"
                                defaultValue={message.title}
                                placeholder="Titre du message"
                                required
                            />
                            <label htmlFor="datePublication">Date et heure de publication : </label>
                            <input
                                type="datetime-local"
                                id="datePublication"
                                name="datePublication"
                                defaultValue={new Date(message.datePublication).toISOString().slice(0, 16)}
                                required
                            />
                        </div>
                        <div className="city-update-message">
                            <h3>Ville</h3>
                            <CitySelector
                                onChange={handleChangeCitySelector}
                                value={valueCitySelector}
                                isMulti={true}
                                placeholder={"Sélectionnez une ou plusieurs villes"}
                            />
                        </div>
                        <div className="links-section">
                            <h3>Liens Url</h3>
                            {links.map((link, index) => (
                                <div key={index} className="link-input">
                                    <label htmlFor={`lien-${index}`}>Lien {index + 1} : </label>
                                    <input
                                        type="text"
                                        id={`lien-${index}`}
                                        placeholder="Ajouter une url"
                                        defaultValue={link}
                                        pattern="https?://.+"
                                        onChange={(e) => handleLinkChange(index, e)}
                                    />
                                </div>
                            ))}
                            <button type="button" onClick={handleAddLink}>+</button>
                        </div>
                    </section>
                    <div className="description-update-message">
                        <label htmlFor="description" id="label-description">
                            Rédaction du message :
                        </label>
                        <textarea
                            id="description"
                            name="description"
                            placeholder="Rédigez le corps du message"
                            defaultValue={message.content}
                            required
                        />
                    </div>
                    <section className="update-message-content">
                        <div className="popup-update-content-container">
                            <label htmlFor="logo-content">
                                Modifier le logo
                            </label>
                            <input
                                type="file"
                                id="logo-content"
                                name="logo-content"
                                onChange={handleLogoUpload}
                                hidden
                            />
                            <label htmlFor="picture-content">Ajouter une photo</label>
                            <input
                                type="file"
                                id="picture-content"
                                onChange={handlePictureUpload}
                                hidden
                                multiple
                            />
                            <label htmlFor="video-content">Ajouter une vidéo</label>
                            <input
                                type="file"
                                id="video-content"
                                onChange={handleVideoUpload}
                                hidden
                            />
                        </div>
                    </section>
                    {!isEmpty(logoUpdateMessage) && (
                        <section className="uploaded-logo">
                            <h3>Logo ajouté</h3>
                            <img
                                src={
                                    "https://api.lesbonsplans.pro" +
                                    "/upload/partner/image/" +
                                    logoUpdateMessage
                                }
                                alt="Logo ajouté"
                                title="logo actuel du message"
                                width={200}
                            />
                        </section>
                    )}
                    {!isEmpty(uploadsUpdateMessage) && (
                        <AdminSortingPartnerUploads
                            onDeleteIdsChange={handleDeletedIdsChange}
                            uploads={uploadsUpdateMessage}
                        />
                    )}
                    <div className="popup-footer">
                        <input type="submit" id="valid" value="Modifier le message"/>
                        <button type="button" className="popup-delete-button" onClick={handleConfirmClick}>Supprimer</button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default AdminUpdateMessage;