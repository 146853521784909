import React from 'react';
import Modal from 'react-modal';

const StatusUser = ({selectedTypeUser, handleTypeUserChange, openModal, closeModal, isModalOpen}) => {

    return (
        <div className="status-user">
            <div className="check-box-condition">
                <div className="checkbox-label">
                    <input
                        type="checkbox"
                        id="isUser"
                        name="isUser"
                        onChange={handleTypeUserChange}
                        checked={selectedTypeUser === "isUser"}
                    />
                    <label htmlFor="isUser">Vous êtes un Particulier</label>
                </div>
                <p>
                    Cliquez ici si vous allez utiliser l’oeuvre téléchargée dans un cadre privé exclusivement. Plus
                    d'information{' '}
                    <span onClick={openModal}
                          style={{color: "#e2b259", cursor: "pointer", textDecoration: "underline"}}>
                        ici
                    </span>.
                </p>
            </div>
            <div className="check-box-condition">
                <div className="checkbox-label">
                    <input
                        type="checkbox"
                        id="isProfessional"
                        name="isProfessional"
                        onChange={handleTypeUserChange}
                        checked={selectedTypeUser === "isProfessional"}
                    />
                    <label htmlFor="isProfessional">Vous êtes un Professionnel</label>
                </div>
                <p>
                    Cliquez ici si vous allez utiliser l’oeuvre téléchargée dans un cadre professionnel en votre
                    établissement comme musique d’ambiance. Dans ce cas, sachez que vous n’aurez à payer aucun droit
                    auprès des organismes collectant les droits d’auteur (comme la SACEM en France, la SABAM en
                    Belgique…), une économie annuelle non négligeable ! Plus d'informations{' '}
                    <span onClick={openModal}
                          style={{color: "#e2b259", cursor: "pointer", textDecoration: "underline"}}>
                        ici
                    </span>.
                </p>
            </div>

            <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Conditions Générales d'Utilisation">
                <h2 style={{marginBottom: '20px'}}>Avertissement relatif aux droits d'auteur et conditions
                    d'utilisation</h2>
                <p style={{marginBottom: '20px', textAlign: 'justify'}}>
                    Les chansons et les vidéos que vous souhaitez télécharger, ci-après désignées ensemble comme
                    "l'Œuvre", sont protégées par les lois en vigueur sur les droits d'auteur et la propriété
                    intellectuelle. L'Œuvre est la propriété exclusive de la société VIBEN SAS, sise Résidence Le Jean
                    Bart (Bat B / Rdc) 25 Quai Charles de Gaulle à F-34280 La Grande Motte (France), ci-après désignée
                    comme "le Propriétaire".
                </p>
                <h3 style={{marginBottom: '20px'}}>A. Conditions d'utilisation :</h3>
                <h4 style={{marginBottom: '20px'}}>1. Utilisation par les particuliers (personnes physiques ou morales)
                    :</h4>
                <p style={{marginBottom: '20px', textAlign: 'justify'}}>
                    ▪ L'Œuvre peut être téléchargée et utilisée uniquement dans un cadre privé et à titre gratuit.<br/>
                    ▪ Cela signifie que l'Œuvre ne peut être utilisée que pour un usage personnel, non commercial, et ne
                    doit pas être partagée, diffusée, ou exploitée dans un contexte public ou professionnel sans
                    l'autorisation écrite préalable du Propriétaire.<br/>
                    ▪ Toute modification, redistribution, ou utilisation publique de l'Œuvre est strictement interdite.
                </p>
                <h4 style={{marginBottom: '20px'}}>2. Utilisation par les professionnels (personnes physiques ou
                    morales) :</h4>
                <p style={{marginBottom: '20px', textAlign: 'justify'}}>
                    ▪ L'Œuvre peut être utilisée dans le cadre d'une activité professionnelle comme musique d'ambiance
                    et ce, uniquement au sein de l’établissement ayant téléchargé l’Œuvre. Dans ce cadre, Aucun droit
                    d'auteur ne devra être acquitté auprès des organismes de gestion collective de ces droits (tels que
                    la SACEM en France) pour cette utilisation spécifique.<br/>
                    ▪ L’utilisation de l’Œuvre pour une diffusion en radio, en télévision, en discothèque, en concert,
                    en soirées dansantes, en soirées karaoké ou pour toutes autres diffusions assimilées ne sera pas
                    quant à elle dispensée de l’acquittement des droits d’auteur auprès des organismes de gestion
                    collective de ces droits si ces derniers le jugent obligatoire.<br/>
                    ▪ L'utilisation de l'Œuvre dans un cadre professionnel ne dispense pas l'utilisateur de respecter
                    les droits moraux de l'auteur, notamment en ne portant pas atteinte à l'intégrité de l’Œuvre.
                </p>
                <h4 style={{marginBottom: '20px'}}>3. Interdiction de modification et de redistribution :</h4>
                <p style={{marginBottom: '20px', textAlign: 'justify'}}>
                    ▪ Il est interdit de modifier, altérer, ou transformer l'Œuvre de quelque manière que ce soit sans
                    l’autorisation écrite préalable expresse du Propriétaire.<br/>
                    ▪ La redistribution ou le partage de l'Œuvre, sous quelque forme que ce soit (en ligne, via des
                    plateformes de partage ou de téléchargement, entre amis ou autres, etc.), est strictement interdite,
                    que ce soit à titre gratuit ou payant, sans l'autorisation écrite préalable du Propriétaire.
                </p>
                <h4 style={{marginBottom: '20px'}}>4. Sanctions en cas de violation :</h4>
                <p style={{marginBottom: '20px', textAlign: 'justify'}}>
                    ▪ Toute violation de ces conditions d'utilisation pourra entraîner des poursuites judiciaires et des
                    demandes de dommages et intérêts conformément aux lois sur la propriété intellectuelle en
                    vigueur.<br/>
                    ▪ En cas de litige, seuls les tribunaux de Montpellier (France) seront compétents pour trancher le
                    différend.
                </p>
                <h3 style={{marginBottom: '20px'}}>B. Avertissement :</h3>
                <p style={{marginBottom: '20px', textAlign: 'justify'}}>
                    1. Le téléchargement et l'utilisation de l'Œuvre impliquent l'acceptation pleine et entière des
                    présentes conditions. Si vous ne souhaitez pas vous conformer à ces conditions, vous êtes prié de ne
                    pas télécharger ou utiliser l’Œuvre.
                </p>
                <p style={{marginBottom: '20px', textAlign: 'justify'}}>
                    2. Pour toute demande d'utilisation commerciale, de licence, ou d'autorisation spécifique, veuillez
                    contacter le Propriétaire à l'adresse suivante : contact@viben.fr.
                </p>
                <p style={{marginBottom: '20px', textAlign: 'justify'}}>© 2025 VIBEN / Tous droits réservés.</p>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <button style={{backgroundColor: '#e2b259', padding: "20px", borderRadius: "20px"}}
                            onClick={closeModal}>
                        Fermer
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default StatusUser;