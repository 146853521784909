import React from "react";
import {useSelector} from "react-redux";

const NewsList = ({creationChoose, newsList}) => {
    const userConnect = useSelector((state) => state.authReducer);

    return (
        <div className={`news-list-container ${creationChoose === "manual" ? "manual" : "link"}`}>
            <h3>Vos News</h3>
            {userConnect.user && userConnect.user.news ? (
                newsList
                    .filter(news => userConnect.user.news.includes(news._id))
                    .sort((a, b) => new Date(b.datePublication) - new Date(a.datePublication))
                    .map((news, index) => (
                        <div key={index} className="news-item">
                            {news.photo_news && (
                                <img src={
                                    "https://api.lesbonsplans.pro" +
                                    "/upload/upload/image/" +
                                    news.photo_news
                                } alt={news.title} width="100%"/>
                            )}
                            <h4>{news.title}</h4>
                            <p>{new Date(news.datePublication).toLocaleDateString()} {new Date(news.datePublication).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit'
                            })}</p>
                        </div>
                    ))
            ) : (
                <p>Aucune news créée pour le moment.</p>
            )}
        </div>
    );
};

export default NewsList;