import React, {useEffect} from "react";
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../assets/Utils";
import {getMagazine} from "../actions/magazine.action";
import BasePages from "./BasePages";

const MagazineRedirect = () => {
    const currentCity = useSelector((state) => state.cityReducer);
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMagazine());
    }, [dispatch]);

    return (
        <BasePages navPath={"decouverte"} stringBtn={"Retourner à Découverte"}>
            <h1>Les magazines</h1>
            <section className="magazine-redirect">
                <ul>
                    {!isEmpty(contentCurrentCity.magazines) && (
                        <li>
                            <NavLink to={`/nos-magazines/${currentCity.subDomain}`}>
                                <i className="bx bx-book-open"></i>Nos magazines
                            </NavLink>
                        </li>
                    )}
                    {contentCurrentCity.cityMagLink && (
                        <li>
                            <NavLink to="/magazine-de-la-ville" state={{
                                Link: contentCurrentCity.cityMagLink,
                                title: "Magazine de la ville",
                                headerTitle: "Retourner aux magazines",
                                headerRedirect: "magazines"
                            }}>
                                <i className="bx bx-podcast"></i>Magazine de la ville
                            </NavLink>
                        </li>
                    )}
                </ul>
            </section>
        </BasePages>
    );
};

export default MagazineRedirect;
