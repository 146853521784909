import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCity } from "../actions/city.action";
import { getContentCity } from "../actions/contentCity.action";

const BackButton = ({ navPath, stringBtn }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (navPath === "ma-ville") {
      dispatch(getCity());
      dispatch(getContentCity());
    }
  };

  return (
    <div className="back-button">
      <NavLink to={navPath ? `/${navPath}` : "/accueil"} onClick={handleClick}>
        <button>{stringBtn}</button>
      </NavLink>
    </div>
  );
};

export default BackButton;