import React, {useEffect, useState} from "react";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCity, setCurrentCity} from "../actions/city.action";
import {getContentCity, getContentCityById} from "../actions/contentCity.action";
import {isEmpty} from "../assets/Utils";
import BasePages from "./BasePages";

const Home = () => {
    const {citySubDomain} = useParams();
    const currentCity = useSelector((state) => state.cityReducer);
    const dataMessage = useSelector((state) => state.messageReducer);
    const partners = useSelector((state) => state.partnerReducer);
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [hasPartnerCity, setHasPartnerCity] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("contentCityMemo")) {
            localStorage.removeItem("contentCityMemo");
            navigate("/");
            window.location.reload();
        }
    }, [navigate]);

    useEffect(() => {
        const checkPartnerCity = async () => {
            const result = await verifPartnerCity();
            setHasPartnerCity(result);
        };
        checkPartnerCity();
    }, [currentCity, partners]);

    useEffect(() => {
        const fetchCity = async () => {
            try {
                const response = await fetch(`https://api.lesbonsplans.pro/city/search?subDomain=${citySubDomain}`);
                const data = await response.json();
                if(data.isActive) {
                    dispatch(setCurrentCity(data));
                    dispatch(getContentCityById(data.contentCity));
                }else{
                    navigate("/");
                }
            } catch (error) {
                console.error("Error fetching city data:", error);
            }
        };

        if (Array.isArray(currentCity)) {
            fetchCity();
        }
    }, [citySubDomain, currentCity, dispatch]);

    const onClickRedirectCities = async () => {
        dispatch(getCity());
        dispatch(getContentCity());
    };

    const verifPartnerCity = async () => {
        if (isEmpty(currentCity)) {
            await dispatch(getCity());
        }
        return partners.some((partner) => partner.city === currentCity._id && partner.isActive);
    }

    const mostRecentMessage = dataMessage
        .filter((message) => new Date(message.datePublication) <= new Date())
        .sort((a, b) => new Date(b.datePublication) - new Date(a.datePublication))[0];

    const isMostRecentMessageRead = () => {
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('readMessageId='))
            ?.split('=')[1];
        return cookieValue === mostRecentMessage?._id;
    };

    const hasActivePartnerWithInterview = () => {
        return partners.some(partner =>
            partner.city === currentCity._id &&
            partner.isActive &&
            partner.interviewName
        );
    };

    return (
        <BasePages navPath={"ma-ville"} stringBtn={"Retourner aux choix des villes"}>
            <section className="home">
                <nav className="nav-categories">
                    <ul>
                        <li>
                            <NavLink to="/" onClick={() => onClickRedirectCities()}>
                                <i className="bx bxs-home"></i>Villes
                            </NavLink>
                        </li>
                        {hasPartnerCity && (
                            <li>
                                <NavLink to="/categories">
                                    <i className="bx bxs-heart"></i>Les Bonnes Adresses
                                </NavLink>
                            </li>
                        )}
                        <li>
                            <NavLink to="/decouverte">
                                <i className="bi bi-search-heart"></i>Découverte
                            </NavLink>
                        </li>
                        {!isEmpty(contentCurrentCity.albums) && (
                            <li>
                                <NavLink to="/albums">
                                    <i className='bx bx-music'></i>Musiques
                                </NavLink>
                            </li>
                        )}
                        {!isEmpty(contentCurrentCity.cityNewsLink) && (
                            <li>
                                <NavLink to="/les-actualites">
                                    <i className="bx bxs-book-open"></i>Les Actualités
                                </NavLink>
                            </li>
                        )}
                        {!isEmpty(contentCurrentCity.calendarLink) && (
                            <li>
                                <NavLink to="/agenda" state={{
                                    Link: contentCurrentCity.calendarLink,
                                    title: "Agenda",
                                    headerTitle: "Retourner à l'accueil",
                                    headerRedirect: "accueil"
                                }}>
                                    <i className="bx bxs-calendar"></i>Agenda
                                </NavLink>
                            </li>
                        )}
                        {hasActivePartnerWithInterview() && (
                            <li>
                                <NavLink to="/interviewList">
                                    <i className="bx bxs-microphone"></i>Interviews
                                </NavLink>
                            </li>
                        )}
                        {!isEmpty(contentCurrentCity.messages) && (
                            <li>
                                <NavLink to="/nos-messages">
                                    <i className="bx bxs-envelope"
                                       style={{color: !isMostRecentMessageRead() ? "red" : "white"}}></i>Nos Messages
                                </NavLink>
                            </li>
                        )}
                        <li>
                            <NavLink to="/suivez-nous">
                                <i className="bx bxs-binoculars"></i>Suivez-nous !
                            </NavLink>
                        </li>
                        {!isEmpty(contentCurrentCity.guidePrestigeLink) && (
                            <li>
                                <NavLink to="/GuidePrestige" state={{
                                    Link: contentCurrentCity.guidePrestigeLink,
                                    title: "Guide Prestige",
                                    headerTitle: "Retourner à l'accueil",
                                    headerRedirect: "accueil"
                                }}>
                                    <i className="bx bxs-gift"></i>Guide Prestige
                                </NavLink>
                            </li>
                        )}
                    </ul>
                </nav>
            </section>
        </BasePages>
    );
};

export default Home;