import axios from "axios";
import {deleteElement} from "./partnerUploads.action";
import {updateContentCity} from "./contentCity.action";

export const GET_ALBUM = "GET_ALBUM";
export const SET_ALBUM = "SET_ALBUM";
export const UPDATE_ALBUM = "UPDATE_ALBUM";
export const DELETE_ALBUM = "DELETE_ALBUM";

const API_URL = process.env.REACT_APP_API_URL;

export const getAlbum = () => {
    return async (dispatch) => {
        return axios
            .get(`${API_URL}/album`)
            .then((res) => {
                dispatch({type: GET_ALBUM, payload: res.data});
            })
            .catch((err) => console.log(err));
    };
};

export const setAlbum = (album, contentCityId) => {
    console.log(contentCityId);
    return async (dispatch) => {
        const formattedAlbum = {
            ...album,
            content: album.content.map(item => ({
                ...item,
                cost: Number(item.cost),
                timePreview: Number(item.timePreview),
            })),
            cost: {
                particulier: Number(album.cost.particulier),
                pro: Number(album.cost.pro),
            }
        };

        return axios
            .post(`${API_URL}/album`, formattedAlbum)
            .then(async (res) => {
                const newAlbum = res.data;
                dispatch({type: SET_ALBUM, payload: newAlbum});

                for (const contentCitiesId of contentCityId) {
                    const contentCity = await axios.get(`${API_URL}/contentCity/${contentCitiesId}`);
                    const updatedContentCity = {
                        ...contentCity.data,
                        albums: [...contentCity.data.albums, newAlbum._id]
                    };
                    dispatch(updateContentCity(updatedContentCity));
                }
            })
            .catch((err) => console.log(err));
    };
};

export const updateAlbum = (album, contentCityNew, contentCityOld) => {
    return async (dispatch) => {
        const formattedAlbum = {
            ...album,
            content: Array.isArray(album.content) ? album.content.map(item => ({
                ...item,
                cost: Number(item.cost),
                timePreview: Number(item.timePreview),
            })) : [],
            cost: {
                particulier: Number(album.cost.particulier),
                pro: Number(album.cost.pro),
            }
        };

        return axios
            .put(`${API_URL}/album/${album._id}`, formattedAlbum)
            .then(async (res) => {
                const updatedAlbum = res.data;
                console.log("updatedAlbum");
                console.log(updatedAlbum);
                dispatch({type: UPDATE_ALBUM, payload: updatedAlbum});

                // Add album ID to all contentCity in contentCityNew
                for (const contentCityId of contentCityNew) {
                    const contentCity = await axios.get(`${API_URL}/contentCity/${contentCityId}`);
                    const updatedContentCity = {
                        ...contentCity.data,
                        albums: [...contentCity.data.albums, updatedAlbum._id]
                    };
                    dispatch(updateContentCity(updatedContentCity));
                }

                // Remove album ID from all contentCity in contentCityOld
                for (const contentCityId of contentCityOld) {
                    const contentCity = await axios.get(`${API_URL}/contentCity/${contentCityId}`);
                    const updatedContentCity = {
                        ...contentCity.data,
                        albums: contentCity.data.albums.filter(albumId => albumId !== updatedAlbum._id)
                    };
                    dispatch(updateContentCity(updatedContentCity));
                }
            })
            .catch((err) => console.log(err));
    };
};

export const deleteAlbum = (album, contentCityIds) => {
    return async (dispatch) => {
        dispatch(await deleteElement(album.logoAlbum));
        dispatch(await deleteElement(album.logoGroupe));
        for (const upload of album.content) {
            dispatch(await deleteElement(upload.song));
        }

        // Remove album ID from all contentCity in contentCityIds
        for (const contentCityId of contentCityIds) {
            const contentCity = await axios.get(`${API_URL}/contentCity/${contentCityId}`);
            const updatedContentCity = {
                ...contentCity.data,
                albums: contentCity.data.albums.filter(albumId => albumId !== album._id)
            };
            dispatch(updateContentCity(updatedContentCity));
        }

        return axios
            .delete(`${API_URL}/album/${album._id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_ALBUM,
                    payload: album._id,
                });
            })
            .catch((err) => console.log(err));
    };
};