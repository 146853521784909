import React, {useEffect, useState} from "react";
import CitySelector from "../components/CitySelector";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../assets/Utils";
import {updateCity} from "../actions/city.action";
import {
    getContentCity,
    getContentCityById,
    setContentCity,
    updateContentCity,
} from "../actions/contentCity.action";
import AdminNewGuidedTour from "../layouts/AdminNewGuidedTour";
import AdminUpdateGuidedTour from "../layouts/AdminUpdateGuidedTour";
import {getGuidedTour} from "../actions/guidedTour.action";
import {resetAudioGuidedTour} from "../actions/contentGuidedTour.action";
import {resetPictureGuidedTour} from "../actions/pictureGuidedTour.action";
import AdminNewMagazine from "../layouts/AdminNewMagazine";
import {getMagazine} from "../actions/magazine.action";
import AdminUpdateMagazine from "../layouts/AdminUpdateMagazine";
import {
    deleteElement,
    resetReducer,
    setLogoPartner,
} from "../actions/partnerUploads.action";
import {pictureCompressor} from "../assets/utils/pictureCompressor";
import BasePagesBackoffice from "./BasePagesBackoffice";

const AdminContent = () => {
    const dataCity = useSelector((state) => state.cityReducer);
    const dataContentCity = useSelector((state) => state.contentCityReducer);
    const dataGuidedTour = useSelector((state) => state.guidedTourReducer);
    const dataMagazine = useSelector((state) => state.magazineReducer);
    const [valueCitySelector, setValueCitySelector] = useState({});
    const [citySelected, setCitySelected] = useState({});
    const [weatherLink, setWeatherLink] = useState("");
    const [infoCityLink, setInfoCityLink] = useState("");
    const [calendarLink, setCalendarLink] = useState("");
    const [liveCamLink, setLiveCamLink] = useState("");
    const [newsLink, setNewsLink] = useState("");
    const [newspaperLinks, setNewspaperLinks] = useState([{url: "", image: ""}]);
    const [deleteNewspaperLinks, setDeleteNewspaperLinks] = useState([]);
    const [newNewspaperLinks, setNewNewspaperLinks] = useState([]);

    const [magLink, setMagLink] = useState("");
    const [guidePrestigeLink, setGuidePrestigeLink] = useState("");
    const [interactCarteLink, setInteractCarteLink] = useState("");
    const [someNumberLink, setSomeNumberLink] = useState("");
    const [popupNewGuidedTour, setPopupNewGuidedTour] = useState(false);
    const [viewGuidedTour, setviewGuidedTour] = useState(false);
    const [viewMagazine, setviewMagazine] = useState(false);

    const [popupNewMagazine, setPopupNewMagazine] = useState(false);
    const [popupUpdateMagazine, setpopupUpdateMagazine] = useState(false);
    const [popupUpdateGuidedTour, setPopupUpdateGuidedTour] = useState(false);
    const [selectedElement, setselectedElement] = useState(null);
    const contentNewspaper = useSelector((state) => state.partnerUploadsReducer);
    const dispatch = useDispatch();

    const resetForm = () => {
        setValueCitySelector({});
        setCitySelected({});
        setWeatherLink("");
        setInfoCityLink("");
        setCalendarLink("");
        setLiveCamLink("");
        setNewsLink("");
        setMagLink("");
        setGuidePrestigeLink("");
        setInteractCarteLink("");
        setSomeNumberLink("");
        setNewspaperLinks([{url: "", image: ""}]);
        setDeleteNewspaperLinks([]);
    };


    useEffect(() => {
        if (
            isEmpty(citySelected.contentCity) &&
            !Array.isArray(dataContentCity) &&
            dataContentCity._id !== citySelected.contentCity &&
            citySelected._id !== undefined
        ) {
            dispatch(
                updateCity({
                    _id: citySelected._id,
                    contentCity: dataContentCity._id,
                })
            );
        }
    }, [dataContentCity]);

    useEffect(() => {
        if (
            !isEmpty(dataGuidedTour) &&
            !Array.isArray(dataGuidedTour) &&
            !Array.isArray(dataContentCity) &&
            !isEmpty(citySelected.contentCity)
        ) {
            let guidedTourID = dataGuidedTour._id;
            let updatedGuidedTour = [...dataContentCity.guidedTour, guidedTourID];
            const updatedContentCity = {
                _id: citySelected.contentCity,
                guidedTour: [...updatedGuidedTour],
            };
            dispatch(updateContentCity(updatedContentCity));
            dispatch(getGuidedTour());
        }
    }, [dataGuidedTour]);

    useEffect(() => {
        if (
            !isEmpty(dataMagazine) &&
            !Array.isArray(dataMagazine) &&
            !Array.isArray(dataContentCity) &&
            !isEmpty(citySelected.contentCity)
        ) {
            let magazineID = dataMagazine._id;
            let updatedMagazines = [...dataContentCity.magazines, magazineID];
            const updatedContentCity = {
                _id: citySelected.contentCity,
                magazines: [...updatedMagazines],
            };
            dispatch(updateContentCity(updatedContentCity));
            dispatch(getMagazine());
        }
    }, [dataMagazine]);

    useEffect(() => {
        dispatch(getContentCity());
        dispatch(getGuidedTour());
        dispatch(getMagazine());
        dataCity.forEach((city) => {
            if (city.name === valueCitySelector.value) {
                setCitySelected(city);
            }
        });
    }, [valueCitySelector]);

    useEffect(() => {
        if (Array.isArray(dataContentCity) && !isEmpty(citySelected.contentCity)) {
            dispatch(getContentCityById(citySelected.contentCity));
        }
        if (
            !isEmpty(citySelected.contentCity) &&
            !Array.isArray(dataContentCity) &&
            citySelected.contentCity === dataContentCity._id
        ) {
            setWeatherLink(dataContentCity.weatherLink);
            setInfoCityLink(dataContentCity.infoCityLink);
            setCalendarLink(dataContentCity.calendarLink);
            setLiveCamLink(dataContentCity.liveCamLink);
            setNewsLink(dataContentCity.cityNewsLink);
            setMagLink(dataContentCity.cityMagLink);
            setGuidePrestigeLink(dataContentCity.guidePrestigeLink);
            setInteractCarteLink(dataContentCity.interactCarteLink);
            setSomeNumberLink(dataContentCity.someNumberLink);
            setNewspaperLinks(dataContentCity.newspapers);
        }
    }, [citySelected, dataContentCity]);

    const handleChangeCitySelector = (value) => {
        resetForm();
        setValueCitySelector(value);
    };

    const handleAddNewspaperLink = () => {
        setNewspaperLinks([...newspaperLinks, {url: "", image: ""}]);
    };

    const handleNewspaperLinkChange = (index, field, value) => {
        const updatedLinks = newspaperLinks.map((link, i) => {
            if (i === index) {
                if (field === "image") {
                    const oldImage = link.image;
                    if (oldImage && !(oldImage instanceof File)) {
                        setDeleteNewspaperLinks([...deleteNewspaperLinks, oldImage]);
                    }
                    return {...link, [field]: value};
                } else {
                    return {...link, [field]: value};
                }
            }
            return link;
        });
        setNewspaperLinks(updatedLinks);
    };


    const deleteNewspaperLink = async () => {
        for (const id of deleteNewspaperLinks) {
            dispatch(await deleteElement(id));
        }
        setDeleteNewspaperLinks([]);
    };

    const handleDeleteNewspaperLink = (index) => {
        const link = newspaperLinks[index];
        if (link.image && !(link.image instanceof File)) {
            setDeleteNewspaperLinks([...deleteNewspaperLinks, link.image]);
        }
        const updatedLinks = newspaperLinks.filter((_, i) => i !== index);
        setNewspaperLinks(updatedLinks);
    };

    const processNewspaperLinks = async () => {
        const newNewspaperLinksSubmit = newspaperLinks
            .map((link, index) => link.image instanceof File ? {id: index, image: link.image} : null)
            .filter(link => link !== null);


        if (newNewspaperLinksSubmit.length > 0) {
            setNewNewspaperLinks(newNewspaperLinksSubmit[0]);
            const compressedImages = await pictureCompressor(newNewspaperLinksSubmit[0].image);
            dispatch(await setLogoPartner(compressedImages));
        } else {
            await onSubmitContent();
        }
    }

    const onSubmitContentPrev = async (e) => {
        e.preventDefault();
        await processNewspaperLinks(e);
    }

    useEffect(() => {
        if (!isEmpty(newNewspaperLinks) && !isEmpty(contentNewspaper.logo) && newspaperLinks[newNewspaperLinks.id]?.image instanceof File) {
            const updatedNewspaperLinks = newspaperLinks.map((link, index) => {
                if (index === newNewspaperLinks.id) {
                    return {...link, image: contentNewspaper.logo};
                }
                return link;
            });

            setNewspaperLinks(updatedNewspaperLinks);
        }
    }, [newNewspaperLinks, contentNewspaper, newspaperLinks]);

    useEffect(() => {
        if (newNewspaperLinks.id !== undefined && !isEmpty(contentNewspaper.logo) && newspaperLinks[newNewspaperLinks.id].image === contentNewspaper.logo) {
            dispatch(resetReducer());
            setNewNewspaperLinks([]);
            processNewspaperLinks();

        }
    }, [contentNewspaper, newspaperLinks]);


    const onSubmitContent = async () => {
        let contentCity = {
            weatherLink,
            infoCityLink,
            calendarLink,
            liveCamLink,
            cityNewsLink: newsLink,
            cityMagLink: magLink,
            guidePrestigeLink: guidePrestigeLink,
            interactCarteLink: interactCarteLink,
            someNumberLink: someNumberLink,
            newspapers: newspaperLinks,
        };
        if (isEmpty(citySelected.contentCity)) {
            dispatch(setContentCity(contentCity, citySelected._id));
        } else {
            contentCity = {
                ...contentCity,
                _id: citySelected.contentCity,
            };
            dispatch(updateContentCity(contentCity));
        }
        await deleteNewspaperLink();
        resetForm();
        dispatch(resetReducer());
    };


    const handleGuidedTourClick = (tour) => {
        setselectedElement(tour);
        setPopupUpdateGuidedTour(true);
    };

    const handleMagazineClick = (mag) => {
        setselectedElement(mag);
        setpopupUpdateMagazine(true);
    };


    const closePopup = (action) => {
        if (action === "post") {
            setPopupNewGuidedTour(false);
        } else if (action === "update") {
            setPopupUpdateGuidedTour(false);
        } else if (action === "magazine") {
            setPopupNewMagazine(false);
        } else if (action === "updateMagazine") {
            setpopupUpdateMagazine(false);
        }
        dispatch(resetReducer());
        dispatch(resetAudioGuidedTour());
        dispatch(resetPictureGuidedTour());
    };


    return (
        <BasePagesBackoffice navPath={"accueil"} stringBtn={"Retourner à la page d'accueil"}>
            <section className="admin-content">
                <h2>Choisir la ville</h2>
                <div className="selector-container">
                    <CitySelector
                        onChange={handleChangeCitySelector}
                        value={valueCitySelector}
                    />
                </div>
                {!isEmpty(citySelected) && (
                    <form autoComplete="off" onSubmit={onSubmitContentPrev} className="form-admin-content">
                        <h3>Ajouter du contenu</h3>
                        <div className="weather-link-container link-container">
                            <label htmlFor="weather-link">
                                Lien vers la météo de la ville
                            </label>
                            <input
                                type="text"
                                id="weather-link"
                                value={weatherLink}
                                onChange={(e) => setWeatherLink(e.target.value)}
                                pattern="https?://.+"
                            />
                        </div>
                        <div className="info-city-link-container link-container">
                            <label htmlFor="info-city-link">
                                Lien vers les informations pratiques de la ville
                            </label>
                            <input
                                type="text"
                                id="info-city-link"
                                value={infoCityLink}
                                onChange={(e) => setInfoCityLink(e.target.value)}
                                pattern="https?://.+"
                            />
                        </div>
                        <div className="calendar-city-link-container link-container">
                            <label htmlFor="calendar-city-link">
                                Lien vers l'agenda des festivités de la ville
                            </label>
                            <input
                                type="text"
                                id="calendar-city-link"
                                value={calendarLink}
                                onChange={(e) => setCalendarLink(e.target.value)}
                                pattern="https?://.+"
                            />
                        </div>
                        <div className="live-cam-link-container link-container">
                            <label htmlFor="live-cam-link">
                                Lien vers les LiveCam de la ville
                            </label>
                            <input
                                type="text"
                                id="live-cam-link"
                                value={liveCamLink}
                                onChange={(e) => setLiveCamLink(e.target.value)}
                                pattern="https?://.+"
                            />
                        </div>
                        <div className="guide-prestige-link-container link-container">
                            <label htmlFor="guide-prestige-link">
                                Lien vers Les Bons Plans
                            </label>
                            <input
                                type="text"
                                id="guide-prestige-link"
                                value={guidePrestigeLink}
                                onChange={(e) => setGuidePrestigeLink(e.target.value)}
                                pattern="https?://.+"
                            />
                        </div>
                        <div className="interact-carte-link-container link-container">
                            <label htmlFor="interact-carte-link">
                                Lien vers une carte interactive de la ville
                            </label>
                            <input
                                type="text"
                                id="interact-carte-link"
                                value={interactCarteLink}
                                onChange={(e) => setInteractCarteLink(e.target.value)}
                                pattern="https?://.+"
                            />
                        </div>
                        <div className="some-number-link-container link-container">
                            <label htmlFor="some-number-link">
                                Lien vers quelques chiffres de la ville
                            </label>
                            <input
                                type="text"
                                id="some-number-link"
                                value={someNumberLink}
                                onChange={(e) => setSomeNumberLink(e.target.value)}
                                pattern="https?://.+"
                            />
                        </div>
                        <div className="mag-city-link-container link-container">
                            <label htmlFor="mag-city-link">
                                Lien vers les magasines de la ville
                            </label>
                            <input
                                type="text"
                                id="mag-city-link"
                                value={magLink}
                                onChange={(e) => setMagLink(e.target.value)}
                                pattern="https?://.+"
                            />
                        </div>
                        <section className="news-city-linkNewspaper">
                            <h3>News de la ville</h3>
                            <div className="news-city-link-container link-container">
                                <label htmlFor="news-city-link">
                                    Lien vers les actualités de la ville
                                </label>
                                <input
                                    type="text"
                                    id="news-city-link"
                                    value={newsLink}
                                    onChange={(e) => setNewsLink(e.target.value)}
                                    pattern="https?://.+"
                                />
                            </div>
                            <div className="news-city-linkNewspaper-container link-container">
                                {newspaperLinks.map((link, index) => (
                                    <div key={index}>
                                        <label htmlFor={`news-city-link-${index}`}>
                                            {index === 0 ? " Lien vers google" : "Lien vers un journal"}
                                        </label>
                                        <input
                                            type="text"
                                            id={`news-city-link-${index}`}
                                            value={link.url}
                                            onChange={(e) => handleNewspaperLinkChange(index, "url", e.target.value)}
                                            pattern="https?://.+"
                                        />
                                        <label className="label-btn" htmlFor={`interview-photo-content-${index}`}>
                                            {isEmpty(link.image) ? "Ajouter un logo" : "Modifier le logo"}
                                        </label>
                                        <input
                                            type="file"
                                            id={`interview-photo-content-${index}`}
                                            onChange={(e) => handleNewspaperLinkChange(index, "image", e.target.files[0])}
                                            hidden
                                        />
                                        {link.image && (
                                            <div className="newspaper-photo-delete">
                                                <img
                                                    src={link.image instanceof File ? URL.createObjectURL(link.image) : `https://api.lesbonsplans.pro/upload/partner/image/${link.image}`}
                                                    alt="image-journal"
                                                />
                                                <button type="button"
                                                        onClick={() => handleDeleteNewspaperLink(index)}>Supprimer
                                                </button>
                                            </div>

                                        )}
                                    </div>
                                ))}
                                <div className="news-city-linkNewspaper-add">
                                    <label>
                                        Ajouter un nouveau journal
                                    </label>
                                    <button type="button" onClick={handleAddNewspaperLink}>+</button>
                                </div>
                            </div>
                        </section>
                        {!isEmpty(citySelected.contentCity) && (
                            <div className="label-guided-tour-magazine">
                                <label
                                    className="label-btn"
                                    onClick={() => setPopupNewGuidedTour(true)}
                                >
                                    Ajouter une visite guidée
                                </label>
                                <label
                                    className="label-btn"
                                    onClick={() => setviewGuidedTour(!viewGuidedTour)}
                                >
                                    {viewGuidedTour
                                        ? "Cacher les visités guidées"
                                        : "Afficher les visités guidées"}
                                </label>
                                <label
                                    className="label-btn"
                                    onClick={() => setPopupNewMagazine(true)}
                                >
                                    Ajouter un magazine
                                </label>
                                <label
                                    className="label-btn"
                                    onClick={() => setviewMagazine(!viewMagazine)}
                                >
                                    {viewMagazine
                                        ? "Cacher les magazines"
                                        : "Afficher les magazines"}
                                </label>
                            </div>
                        )}
                        <input type="submit" value="Valider le contenu"/>
                    </form>
                )}
                <section className="guided-tour-overview">
                    {!isEmpty(dataContentCity.guidedTour) &&
                        Array.isArray(dataGuidedTour) &&
                        viewGuidedTour &&
                        dataGuidedTour
                            .filter((tour) => dataContentCity.guidedTour.includes(tour._id))
                            .map((tour) => (
                                <div
                                    key={tour._id}
                                    className="guided-tour"
                                    onClick={() => handleGuidedTourClick(tour)}
                                >
                                    <img
                                        src={
                                            "https://api.lesbonsplans.pro/upload/upload/image/" +
                                            tour.picture
                                        }
                                        alt="image visite"
                                    />
                                    <p>{tour.name}</p>
                                </div>
                            ))}
                </section>
                <section className="magazine-overview">
                    {!isEmpty(dataContentCity.magazines) &&
                        Array.isArray(dataMagazine) &&
                        viewMagazine &&
                        dataMagazine
                            .filter((mag) => dataContentCity.magazines.includes(mag._id))
                            .reverse()
                            .map((mag) => (
                                <div
                                    key={mag._id}
                                    className="magazine"
                                    onClick={() => handleMagazineClick(mag)}
                                    style={{
                                        backgroundImage: `url(https://api.lesbonsplans.pro/upload/upload/image/${mag.pictures[0]?.id})`,
                                    }}
                                >
                                    <div className="name-mag">
                                        <p>{mag.name}</p>
                                        <p>{mag.numberMag}</p>
                                    </div>
                                </div>
                            ))}
                </section>
                {popupNewGuidedTour && (
                    <AdminNewGuidedTour resetPopup={() => closePopup("post")}/>
                )}
                {popupUpdateGuidedTour && selectedElement && (
                    <AdminUpdateGuidedTour
                        contentCityId={citySelected.contentCity}
                        guidedTour={selectedElement}
                        closePopup={() => closePopup("update")}
                    />
                )}
                {popupNewMagazine && (
                    <AdminNewMagazine closePopup={() => closePopup("magazine")}/>
                )}
                {popupUpdateMagazine && selectedElement && selectedElement.pictures && (
                    <AdminUpdateMagazine
                        contentCityId={citySelected.contentCity}
                        magazine={selectedElement}
                        closePopup={() => closePopup("updateMagazine")}
                    />
                )}
            </section>
        </BasePagesBackoffice>
    );
};

export default AdminContent;