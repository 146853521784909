import React from 'react';
import Header from "../layouts/Header";
import {useSelector} from "react-redux";
import Logo from "../components/LogoCity";

const BasePages = ({children, navPath, stringBtn}) => {
    const currentCity = useSelector((state) => state.cityReducer);

    const cityBackground = {
        background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
            currentCity.picture
        }) fixed center bottom/cover`,
    };

    return (
        <div className="frontoffice-container" style={cityBackground}>
            <Header LogoComponent={Logo} navPath={navPath} stringBtn={stringBtn}/>
            {children}
        </div>
    );
};

export default BasePages;