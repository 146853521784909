import React from 'react';
import Header from "../layouts/Header";
import LogoLbp from "../components/LogoLbp";
import NavbarAdmin from "../components/NavbarAdmin";

const BasePagesBackoffice = ({children, navPath, stringBtn, navBarAdmin = true, installPWA}) => {

    return (
        <div className="backoffice-container">
            <Header LogoComponent={LogoLbp} navPath={navPath} stringBtn={stringBtn} installPWA={installPWA}/>
            <section className="backoffice-content-container">
                {navBarAdmin && (
                    <NavbarAdmin/>
                )}
                <div className="backoffice-content">
                    {children}
                </div>
            </section>
        </div>
    );
};

export default BasePagesBackoffice;