import axios from "axios";

export const SET_PICTURE_PARTNER = "SET_PICTURE_PARTNER";
export const SET_LOGO_PARTNER = "SET_LOGO_PARTNER";
export const SET_VIDEO_PARTNER = "SET_VIDEO_PARTNER";
export const SET_INTERVIEW_PHOTO_PARTNER = "SET_INTERVIEW_PHOTO_PARTNER";
export const DELETE_ELEMENT = "DELETE_ELEMENT";
export const RESET = "RESET";
export const REORDER_ELEMENTS = "REORDER_ELEMENTS";
export const INITIALIZE_UPLOADS = "INITIALIZE_UPLOADS";

const API_URL = process.env.REACT_APP_API_URL;

export const setPicturePartner = (data) => {
    return async (dispatch) => {
        try {
            const res = await axios.post(`${API_URL}/upload/partner`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            dispatch({type: SET_PICTURE_PARTNER, payload: res.data});
            return res.data;
        } catch (err) {
            console.log(err);
        }
    };
};

export const setLogoPartner = (data) => {
    return async (dispatch) => {
        try {
            const res = await axios.post(`${API_URL}/upload/partner`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            dispatch({type: SET_LOGO_PARTNER, payload: res.data});
            return res.data;
        } catch (err) {
            console.log(err);
        }
    };
};

export const setInterviewPhotoPartner = (data) => {
    return async (dispatch) => {
        try {
            const res = await axios.post(`${API_URL}/upload/partner`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            dispatch({type: SET_INTERVIEW_PHOTO_PARTNER, payload: res.data});
            return res.data;
        } catch (err) {
            console.log(err);
        }
    };
};

export const setVideoPartner = (data) => {
    return async (dispatch) => {
        try {
            const res = await axios.post(`${API_URL}/upload/partner`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            dispatch({type: SET_VIDEO_PARTNER, payload: res.data});
            return res.data;
        } catch (err) {
            console.log(err);
        }
    };
};

export const deleteElement = (id) => {
    return async (dispatch) => {
        try {
            await axios.delete(`${API_URL}/upload/partner/${id}`);
            dispatch({type: DELETE_ELEMENT, payload: id});
        } catch (err) {
            console.log(err);
        }
    };
};

export const resetReducer = () => {
    return (dispatch) => {
        dispatch({type: RESET});
    };
};

export const reorderElements = (data) => ({
    type: REORDER_ELEMENTS,
    payload: data,
});

export const initializeUploads = (uploads) => {
    return {
        type: INITIALIZE_UPLOADS,
        payload: uploads,
    };
};