import React, {useState, useRef, useEffect} from 'react';

const AdminNewMusic = ({musics, onMusicChange}) => {
    const [audioKeys, setAudioKeys] = useState({});
    const audioRefs = useRef({});

    const handleAudioUpload = (e, id) => {
        const file = e.target.files[0];
        const updatedMusics = musics.map((music) =>
            music.id === id ? {...music, audioSrc: file} : music
        );
        onMusicChange(updatedMusics);
        setAudioKeys((prevKeys) => ({...prevKeys, [id]: Date.now()}));
    };

    const handleInputChange = (e, id) => {
        const {name, value} = e.target;
        const updatedMusics = musics.map((music) =>
            music.id === id ? {...music, [name]: value} : music
        );
        onMusicChange(updatedMusics);
    };

    const handleDeleteMusic = (id) => {
        const updatedMusics = musics.filter((music) => music.id !== id);
        onMusicChange(updatedMusics);
    };

    const addNewMusic = () => {
        onMusicChange([...musics, {id: Date.now(), audioSrc: null}]);
    };

    useEffect(() => {
        musics.forEach(music => {
            if (music.timePreview && audioRefs.current[music.id]) {
                audioRefs.current[music.id].currentTime = 0;
                audioRefs.current[music.id].ontimeupdate = () => {
                    if (audioRefs.current[music.id] && audioRefs.current[music.id].currentTime >= music.timePreview) {
                        audioRefs.current[music.id].pause();
                        audioRefs.current[music.id].currentTime = 0;
                    }
                };
            }
        });
    }, [musics]);

    return (
        <div>
            <h3>Création des nouvelles musiques</h3>
            {musics.map((music, index) => (
                <div key={music.id || index}>
                    <div className="title-delete">
                        <h4>Music Numéro {index + 1}</h4>
                        <button type="button" onClick={() => handleDeleteMusic(music.id)}>X</button>
                    </div>
                    <div className="info-music">
                        <div>
                            <div className="label-input">
                                <label htmlFor={`title-${music.id}`}>Titre de la musique</label>
                                <input
                                    type="text"
                                    id={`title-${music.id}`}
                                    name="title"
                                    value={music.title || ''}
                                    onChange={(e) => handleInputChange(e, music.id)}
                                />
                            </div>
                            <div className="label-input">
                                <label htmlFor={`costMusic-${music.id}`}>Coût de la musique</label>
                                <input
                                    type="number"
                                    id={`costMusic-${music.id}`}
                                    name="cost"
                                    value={music.cost || ''}
                                    onChange={(e) => handleInputChange(e, music.id)}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="label-input">
                                <label htmlFor={`song-${music.id}`} className="button-label">
                                    Ajouter un son
                                </label>
                                <input
                                    type="file"
                                    id={`song-${music.id}`}
                                    accept="audio/*"
                                    onChange={(e) => handleAudioUpload(e, music.id)}
                                    hidden
                                />
                            </div>
                            <div className="label-input">
                                <label htmlFor={`timePreview-${music.id}`}>Temps de demo (secondes)</label>
                                <input
                                    type="number"
                                    id={`timePreview-${music.id}`}
                                    name="timePreview"
                                    value={music.timePreview || ''}
                                    onChange={(e) => handleInputChange(e, music.id)}
                                />
                            </div>
                        </div>
                    </div>
                    {music.audioSrc && (
                        <section className="uploaded-audio">
                            <h3>Son ajouté</h3>
                            <audio
                                key={audioKeys[music.id]}
                                controls
                                ref={(el) => (audioRefs.current[music.id] = el)}
                            >
                                <source
                                    src={music.audioSrc instanceof File ? URL.createObjectURL(music.audioSrc) : "https://api.lesbonsplans.pro/upload/upload/audio/" + music.audioSrc}
                                    type="audio/mpeg"/>
                                Votre navigateur ne supporte pas l'élément audio.
                            </audio>
                        </section>
                    )}
                </div>
            ))}
            <button type="button" onClick={addNewMusic}>
                Ajouter une nouvelle musique
            </button>
        </div>
    );
};

export default AdminNewMusic;