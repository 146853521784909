import React, { useState } from 'react';

const PaginatedList = ({ items, renderItem, initialVisibleCount = 5 }) => {
    const [visibleCount, setVisibleCount] = useState(initialVisibleCount);

    const loadMore = () => {
        setVisibleCount(prevCount => prevCount + 5);
    };

    return (
        <section className="paginated-list">
            <section className="paginated-list-entry">
                {items.slice(0, visibleCount).map((item) => renderItem(item))}
            </section>
            {visibleCount < items.length && (
                <button onClick={loadMore} className="load-more-button">Afficher plus</button>
            )}
        </section>
    );
};

export default PaginatedList;