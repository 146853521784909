import React, {useRef, useState, useEffect} from 'react';
import {useLocation, useNavigate} from "react-router";
import BasePages from "./BasePages";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay, faPause} from '@fortawesome/free-solid-svg-icons';
import {useSelector, useDispatch} from "react-redux";
import {addToCart, removeFromCart} from "../actions/cart.action";
import {showFlashMessage} from "../actions/flashMessage.action";

const Album = () => {
    const location = useLocation();
    const {album} = location.state || {};
    const userConnect = useSelector((state) => state.authReducer);
    const dataCart = useSelector((state) => state.cartReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const audioRefs = useRef({});
    const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
    const [durations, setDurations] = useState({});

    useEffect(() => {
        if (album && album.content) {
            album.content.forEach((audio) => {
                const audioElement = new Audio(`https://api.lesbonsplans.pro/upload/upload/audio/${audio.song}`);

                audioElement.addEventListener('error', (e) => {
                    console.error(`Erreur de chargement de l'audio ${audio._id}:`, e);
                    setDurations((prevDurations) => ({
                        ...prevDurations,
                        [audio._id]: null,
                    }));
                });

                audioElement.addEventListener('loadedmetadata', () => {
                    if (audioElement.duration !== Infinity && !isNaN(audioElement.duration)) {
                        setDurations((prevDurations) => ({
                            ...prevDurations,
                            [audio._id]: audioElement.duration,
                        }));
                    } else {
                        console.warn(`Durée non disponible pour l'audio ${audio._id}`);
                        setDurations((prevDurations) => ({
                            ...prevDurations,
                            [audio._id]: null,
                        }));
                    }
                });

                audioElement.load();
            });
        }
    }, [album]);

    const handlePlayPause = (audioId) => {
        const audio = audioRefs.current[audioId];

        if (currentlyPlaying && currentlyPlaying !== audioId) {
            const previousAudio = audioRefs.current[currentlyPlaying];
            previousAudio.pause();
            previousAudio.currentTime = 0;
        }

        if (audio.paused) {
            audio.play();
            setCurrentlyPlaying(audioId);
        } else {
            audio.pause();
            setCurrentlyPlaying(null);
        }
    };

    const handleTimeUpdate = (audioId, timePreview) => {
        const audio = audioRefs.current[audioId];
        if (audio.currentTime >= timePreview) {
            audio.pause();
            audio.currentTime = 0;
            setCurrentlyPlaying(null);
        }
    };

    const formatTime = (seconds) => {
        if (seconds === null || isNaN(seconds) || seconds === Infinity) {
            return 'N/a';
        }
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const handleAddToCart = (audio) => {
        const isAlreadyInCart = dataCart.items.some(item => item._id === audio._id);

        if (isAlreadyInCart) {
            dispatch(showFlashMessage('Cet article est déjà dans le panier', 'orange', 3000));
        } else {
            dispatch(addToCart(audio));
            dispatch(showFlashMessage('Article ajouté au panier', 'green', 3000));
        }
    };

    const price = userConnect.user
        ? (userConnect.user.userLevels[0] !== 5 ? album.cost.particulier : album.cost.pro)
        : (localStorage.getItem('selectedTypeUser') === "isUser" ? album.cost.particulier : album.cost.pro);

    const navigateToCart = async () => {
        const cart = JSON.parse(localStorage.getItem("cart")) || {items: []};
        const items = cart.items || [];
        const albums = items.filter(item => item.name);
        const removedTitles = [];

        albums.forEach(album => {
            album.content.forEach(audio => {
                const index = items.findIndex(item => item._id === audio._id);
                if (index !== -1) {
                    removedTitles.push(audio.title);
                    items.splice(index, 1);
                    dispatch(removeFromCart(audio));
                }
            });
        });

        await localStorage.setItem("cart", JSON.stringify({items}));
        navigate('/panier', {state: {removedTitles}});
    }


    return (
        <BasePages navPath={"albums"} stringBtn={"Retour aux albums"}>
            <section className="album">
                <img className="logoGroup" src={`https://api.lesbonsplans.pro/upload/partner/image/${album.logoGroupe}`}
                     alt="album"/>
                <div className="album-content-text">
                    <p>{album.description}</p>
                </div>
                <img src={`https://api.lesbonsplans.pro/upload/partner/image/${album.logoAlbum}`} alt={album.name}/>
                <section className="album-content-audio">
                    <h2>Audio</h2>
                    <div className="list-audio">
                        {album.content.map((audio) => (
                            <div className="audio-content" key={audio._id}>
                                <p>{audio.title}</p>
                                <p className="audio-content-data"><span>Durée:</span>
                                    {formatTime(durations[audio._id])}</p>
                                <p className="audio-content-data"><span>Prix:</span>
                                    {audio.cost.toFixed(2)}€</p>
                                <div className="timepreview">
                                    <p className="text-demo">Demo <span>:</span>
                                    </p>
                                    <p>{formatTime(audio.timePreview)}</p>
                                </div>
                                <div className="button-album">
                                    <button
                                        onClick={() => handlePlayPause(audio._id)}
                                        data-playing={currentlyPlaying === audio._id}
                                    >
                                        {currentlyPlaying === audio._id ? (
                                            <FontAwesomeIcon icon={faPause}/>
                                        ) : (
                                            <FontAwesomeIcon icon={faPlay}/>
                                        )}
                                    </button>
                                    <audio
                                        ref={(el) => (audioRefs.current[audio._id] = el)}
                                        src={`https://api.lesbonsplans.pro/upload/upload/audio/${audio.song}`}
                                        onTimeUpdate={() => handleTimeUpdate(audio._id, audio.timePreview)}
                                        onEnded={() => setCurrentlyPlaying(null)}
                                    />
                                    {(userConnect.user && userConnect.user.userLevels[0] !== 5) ||
                                    localStorage.getItem('selectedTypeUser') === "isUser" ? (
                                        <button onClick={() => handleAddToCart(audio)}>
                                            <i className='bx bxs-cart-add custom-cart-icon'></i>
                                        </button>
                                    ) : null}
                                </div>
                            </div>
                        ))}
                        <div className="audio-content">
                            <p>Album complet ({album.content.length} morceaux)</p>
                            <p className="price"><span>Prix:</span>{price}€</p>
                            <div className="button-album">
                                <button onClick={() => handleAddToCart(album)}>
                                    <i className='bx bxs-cart-add custom-cart-icon'></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="album-content-karaok"></section>
                <section className="album-content-video"></section>
                <div className="album-download">
                    <button className="button-download" onClick={navigateToCart}>Voir la panier</button>
                </div>
            </section>
        </BasePages>
    );
}
export default Album;

