import axios from "axios";

        export const SET_PICTURE_GUIDED_TOUR = "SET_PICTURE_GUIDED_TOUR";
        export const DELETE_PICTURE_GUIDED_TOUR = "DELETE_PICTURE_GUIDED_TOUR";
        export const RESET_PICTURE_GUIDED_TOUR = "RESET_PICTURE_GUIDED_TOUR";

        const API_URL = process.env.REACT_APP_API_URL;

        export const setPictureGuidedTour = (pictureGuidedTour) => {
          return async (dispatch) => {
            return axios
              .post(
                `${API_URL}/upload/upload`,
                pictureGuidedTour,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((res) => {
                dispatch({ type: SET_PICTURE_GUIDED_TOUR, payload: res.data });
              })
              .catch((err) => console.log(err));
          };
        };

        export const deletePictureGuidedTour = (pictureGuidedTour) => {
          return async (dispatch) => {
            return axios
              .delete(
                `${API_URL}/upload/upload/${pictureGuidedTour}`
              )
              .then((res) => {
                dispatch({ type: DELETE_PICTURE_GUIDED_TOUR, payload: res.data });
              })
              .catch((err) => console.log(err));
          };
        };

        export const resetPictureGuidedTour = () => {
          return async (dispatch) => {
            dispatch({ type: RESET_PICTURE_GUIDED_TOUR });
          };
        };