import React, {useState} from 'react';
import {useLocation} from "react-router";
import {isEmpty} from "../assets/Utils";
import BasePages from "./BasePages";

const Message = () => {
    const location = useLocation();
    const currentMessage = location.state;
    const [enlargedImage, setEnlargedImage] = useState(null);


    const handleImageClick = (imageId) => {
        setEnlargedImage(enlargedImage === imageId ? null : imageId);
    };

    return (
        <BasePages navPath={"nos-messages"} stringBtn={"Retourner aux messages"}>
            <h1>Message</h1>
            <section className="message">
                <div className="infos-message">
                    <h3>{currentMessage.title}</h3>
                    <img
                        src={
                            "https://api.lesbonsplans.pro" +
                            "/upload/upload/image/" +
                            currentMessage.logo
                        }
                        alt={`Image de "${currentMessage.logo}"`}
                        className={enlargedImage === currentMessage.logo ? 'enlarged' : ''}
                        onClick={() => handleImageClick(currentMessage.logo)}
                    />
                </div>
                <div className="content-message">
                    <p>{currentMessage.content}</p>

                    {!isEmpty(currentMessage.url) && (
                        <>
                            <h4>Plus d'informations :</h4>
                            {currentMessage.url.map((link, index) => (
                                <ul key={index}>
                                    <li>
                                        <button
                                            onClick={() => window.open(link, "_blank")}
                                        >
                                            Lien {index + 1}
                                        </button>
                                    </li>
                                </ul>
                            ))}
                        </>
                    )}
                </div>
                <div className="message-uploads">
                    {currentMessage.contentUploads.map((upload, index) => (
                        <img
                            key={index}
                            src={
                                "https://api.lesbonsplans.pro" +
                                "/upload/upload/image/" +
                                upload.id
                            }
                            alt={`Image ${index}`}
                            className={enlargedImage === upload.id ? 'enlarged' : ''}
                            onClick={() => handleImageClick(upload.id)}
                        />
                    ))}
                </div>
            </section>
        </BasePages>
    );
};

export default Message;