import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {removeFromCart, clearCart} from '../actions/cart.action';
import {finalizeOrder} from '../actions/user.action';
import BasePages from "./BasePages";
import {useLocation} from 'react-router';
import {showFlashMessage} from '../actions/flashMessage.action';

const Cart = () => {
    const cart = useSelector((state) => state.cartReducer);
    const userConnect = useSelector((state) => state.authReducer);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.removedTitles && location.state.removedTitles.length > 0) {
            dispatch(showFlashMessage(
                `Afin d'éviter de payer 2 fois des musiques déjà présentes dans un album, 
                les musiques suivantes ont été supprimées du panier: ${location.state.removedTitles.join(', ')}`,
                "green", 8000));
        }

        if ((userConnect.user && userConnect.user.userLevels === 5) || localStorage.getItem('selectedTypeUser') === "isProfessional") {
            const albums = cart.items.filter(item => !item.title);
            const musicItems = cart.items.filter(item => item.title);

            musicItems.forEach(item => {
                dispatch(removeFromCart(item));
            });

            localStorage.setItem("cart", JSON.stringify({items: albums}));
        }
    }, [cart.items, dispatch, location.state]);

    const handleRemoveFromCart = (item) => {
        dispatch(removeFromCart(item));
    };

    const handleClearCart = () => {
        dispatch(clearCart());
    };

    const calculateTotal = () => {
        const total = cart.items.reduce((total, item) => {
            if (typeof item.cost === 'object') {
                return total + albumPrice(item);
            } else {
                return total + item.cost;
            }
        }, 0);

        return parseFloat(total.toFixed(2));
    };

    const albumPrice = (item) => {
        return userConnect.user
            ? (userConnect.user.userLevels[0] !== 5 ? item.cost.particulier : item.cost.pro)
            : (localStorage.getItem('selectedTypeUser') === "isUser" ? item.cost.particulier : item.cost.pro);
    };

    const handleFinalizeOrder = async () => {
        if (!userConnect.isAuthenticated) {
            dispatch(showFlashMessage(
                "Connectez-vous ou inscrivez-vous afin de pouvoir finaliser votre commande.",
                "orange",
                null,
                "/connexion"
            ));
            return;
        }

        const purchaseDetails = cart.items.map(item => ({
            id: item._id,
            description: item.title ? "Music" : "Album"
        }));

        const orderDetails = {
            type: "Music",
            items: purchaseDetails
        };

        try {
            await dispatch(finalizeOrder(userConnect.user._id, orderDetails));
            dispatch(clearCart());
            dispatch(showFlashMessage(
                "Votre commande a été finalisée avec succès!",
                "green"
            ));
        } catch (error) {
            dispatch(showFlashMessage(
                "Une erreur est survenue lors de la finalisation de votre commande.",
                "red"
            ));
        }
    };

    return (
        <BasePages navPath={"albums"} stringBtn={"Retour aux albums"}>
            <h1>Votre Panier</h1>
            <section className="cart">
                {cart.items ? (
                    cart.items.map((item, index) => (
                        <div key={index} className="cart-item">
                            <p className="cart-item-title">{item.title ? item.title : "Album complet (" + item.name + ")"}</p>
                            <p className="cart-item-price">
                                {typeof item.cost === 'object' ? albumPrice(item).toFixed(2) : item.cost.toFixed(2)}€
                            </p>
                            <button className="bx bx-trash" onClick={() => handleRemoveFromCart(item)}></button>
                        </div>
                    ))
                ) : (
                    <p>Votre panier est vide.</p>
                )}
                <div className="cart-summary">
                    <p>Total : {calculateTotal()}€</p>
                    <div className="cart-button">
                        <button onClick={handleClearCart}>Vider le panier</button>
                        <button onClick={handleFinalizeOrder}>Finaliser la commande</button>
                    </div>
                </div>
            </section>
        </BasePages>
    );
};

export default Cart;