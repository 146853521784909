import React from 'react';
import BasePagesBackoffice from "./BasePagesBackoffice";

const Statistiques = () => {


    return (
        <BasePagesBackoffice navPath={"accueil"} stringBtn={"Retourner à la page d'accueil"}>
            <section className="statistique">
                <h2>Analytics Data</h2>
                <div className="button-container">
                    <button className="btn btn-link"
                            onClick={() => window.open("https://analytics.google.com/", '_blank')}>
                        Si le graphique ne s'affiche pas ou qu'il s'affiche mal, cliquez ici.
                    </button>
                </div>
            </section>
        </BasePagesBackoffice>
    );
};

export default Statistiques;