import React from "react";
import logoPj from "../assets/images/logo-pages-jaunes.svg";
import BasePagesBackoffice from "./BasePagesBackoffice";

const WidgetPagesJaunes = () => {
    return (
        <section className="widget-pages-jaunes">
        <BasePagesBackoffice navPath={"decouverte"} stringBtn={"Retourner à Découverte"} navBarAdmin={false}>
            <h2>Pages Jaunes</h2>
            <section className="pages-jaunes">
                <figure className="logo-top">
                    <img src={logoPj} alt="Logo Pages Jaunes"/>
                </figure>
                <h1 className="pj_title_main">
                    PagesJaunes : l'annuaire des professionnels
                </h1>
                <p>
                    Vous cherchez le numéro d'un garagiste, d'une poste ou de votre
                    banque&nbsp;? Avec <strong>PagesJaunes</strong> et{" "}
                    <strong>La Grande Motte</strong>, trouvez les coordonnés des
                    professionnels en un clic.
                </p>
                <div className="pj-search-wrapper">
                    <div className="pj-search">
                        <h3 className="title-form">Que recherchez-vous&nbsp;?</h3>
                        <form
                            action="https://www.pagesjaunes.fr/annuaire/chercherlespros"
                            method="GET"
                            target="_blank"
                            autoComplete="off"
                        >
                            <input type="hidden" name="at_medium" value="affiliate"/>
                            <input type="hidden" name="at_campaign" value="LA_GRANDE_MOTTE"/>
                            <input name="at_creation" type="hidden" value="Widget_PJ"/>
                            <input
                                id="quoiqui"
                                name="quoiqui"
                                type="text"
                                className="what"
                                placeholder="De quoi avez-vous besoin ?"
                            />
                            <input
                                id="pj-ou"
                                name="ou"
                                type="text"
                                maxlength="256"
                                className="where"
                                placeholder="Adresse, quartier, ville, département"
                            />
                            <input name="univers" type="hidden" value="pagesjaunes"/>
                            <input name="idOu" type="hidden" value=""/>
                            <button type="submit" title="Trouver sur PagesJaunes">
                                <span className="value">Trouver</span>
                            </button>
                        </form>
                    </div>
                    <p>
                        L'annuaire téléphonique <strong>PagesJaunes</strong> vous propose
                        également des plans d'accès, des informations commerciales ou des
                        itinéraires.
                    </p>
                </div>
                <h2 className="pj_title_sub">
                    Avec PagesBlanches, trouvez les coordonnées d’un particulier.
                </h2>
                <div className="pj-search-wrapper">
                    <div className="pj-search">
                        <h3 className="title-form">PagesBlanches</h3>
                        <form
                            target="_blank"
                            method="GET"
                            action="https://www.pagesjaunes.fr/pagesblanches/recherche"
                            autoComplete="off"
                        >
                            <input name="at_medium" type="hidden" value="affiliate"/>
                            <input name="at_campaign" type="hidden" value="LA_GRANDE_MOTTE"/>
                            <input name="at_creation" type="hidden" value="Widget_PB"/>
                            <input
                                id="nom"
                                name="quoiqui"
                                type="text"
                                className="what"
                                placeholder="Qui : Marie Durand"
                            />
                            <input
                                id="ouPB"
                                name="ou"
                                type="text"
                                className="where"
                                maxlength="256"
                                autocomplete="off"
                                placeholder="Où : Lille, Alsace, Bd Voltaire, ..."
                            />
                            <input name="univers" type="hidden" value="pagesblanches"/>
                            <input name="idOu" type="hidden" value=""/>
                            <button type="submit" title="Trouver sur PagesBlanches">
                                <span className="value">Trouver</span>
                            </button>
                        </form>
                    </div>
                </div>
                <h2 className="pj_title_sub">
                    Avec L’Annuaire inversé, trouvez le propriétaire d’un numéro de
                    téléphone.
                </h2>
                <div className="pj-search-wrapper">
                    <div className="pj-search">
                        <h3 className="title-form">Annuaire inversé</h3>
                        <form
                            target="_blank"
                            method="GET"
                            action="https://www.pagesjaunes.fr/annuaireinverse/recherche"
                            autoComplete="off"
                        >
                            <input name="at_medium" type="hidden" value="affiliate"/>
                            <input name="at_campaign" type="hidden" value="LA_GRANDE_MOTTE"/>
                            <input name="at_creation" type="hidden" value="Widget_INV"/>
                            <input
                                id="quoiqui"
                                name="quoiqui"
                                type="text"
                                className="pj-inputText"
                                placeholder="Ex : 01 23 45 67 89"
                            />
                            <input name="univers" type="hidden" value="annuaireinverse"/>
                            <input name="idOu" type="hidden" value=""/>
                            <button type="submit" title="Trouver sur L’Annuaire inversé">
                                <span className="value">Trouver</span>
                            </button>
                        </form>
                    </div>
                </div>
                <figure className="logo-bottom">
                    <img src={logoPj} alt="Logo Pages Jaunes"/>
                </figure>
            </section>
        </BasePagesBackoffice>
        </section>
    );
};

export default WidgetPagesJaunes;
