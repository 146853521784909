import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../actions/user.action";
import { pictureCompressor } from "../assets/utils/pictureCompressor";
import { resetReducer, setLogoPartner } from "../actions/partnerUploads.action";
import { isEmpty } from "../assets/Utils";
import ProfessionalInfo from "../components/ProfessionalInfo";

const AdminNewUser = () => {
    const dataUser = useSelector((state) => state.userReducer);
    const dispatch = useDispatch();
    const contentNewUser = useSelector((state) => state.partnerUploadsReducer);
    const [formState, setFormState] = useState({
        logoNewUser: "",
        compressedLogo: null,
        selectedStatus: null,
        isFormValid: false,
        password: "",
        confirmPassword: "",
        valueCitySelector: [],
        passwordError: "",
        selectedRedactors: [],
        tempNewUser: null,
    });

    useEffect(() => {
        const form = document.getElementById("form-new-user");
        const handleFormChange = () => {
            setFormState((prevState) => ({
                ...prevState,
                isFormValid: form.checkValidity() && !isEmpty(formState.valueCitySelector),
            }));
        };
        form.addEventListener('input', handleFormChange);
        return () => {
            form.removeEventListener('input', handleFormChange);
        };
    }, [formState.valueCitySelector]);

    const handleStatusChange = (selectedOption) => {
        setFormState((prevState) => ({ ...prevState, selectedStatus: selectedOption }));
    };

    const handleRedactorsChange = (selectedOptions) => {
        setFormState((prevState) => ({ ...prevState, selectedRedactors: selectedOptions }));
    };

    const handleLogoUpload = async (e) => {
        const file = e.target.files[0];
        const formDataLogo = await pictureCompressor(file);
        setFormState((prevState) => ({
            ...prevState,
            compressedLogo: formDataLogo,
            logoNewUser: URL.createObjectURL(file),
        }));
    };

    const customStylesCitySelector = {
        control: (provided) => ({
            ...provided,
            margin: "20px 0",
            padding: "5px",
            backgroundColor: "#e2b259",
            border: "2px solid #fff",
            boxShadow: "none",
            transition: "0.2s ease",
            "&:hover": {
                border: "2px solid #ccc",
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#b1852d" : "#e2b259",
            color: state.isFocused ? "white" : "black",
            ":active": {
                backgroundColor: "white",
                color: "black",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "black",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: "white",
            transition: "0.2s ease",
            "&:hover": {
                color: "#ccc",
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "#e2b259",
            border: "2px solid white",
            borderRadius: "4px",
            marginTop: "4px",
            zIndex: 9999,
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "black",
            padding: "2px 8px",
            borderRadius: "4px",
        }),
    };

    const handlePasswordChange = (e) => {
        const password = e.target.value;
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        let passwordError = "";
        if (!passwordPattern.test(password)) {
            passwordError = "Le mot de passe doit comporter au moins 8 caractères, dont 1 majuscule, 1 minuscule, 1 chiffre et 1 ponctuation";
        } else if (formState.confirmPassword && password !== formState.confirmPassword) {
            passwordError = "Les mots de passe ne correspondent pas";
        }
        setFormState((prevState) => ({ ...prevState, password, passwordError }));
    };

    const handleConfirmPasswordChange = (e) => {
        const confirmPassword = e.target.value;
        let passwordError = "";
        if (formState.password && confirmPassword !== formState.password) {
            passwordError = "Les mots de passe ne correspondent pas";
        }
        setFormState((prevState) => ({ ...prevState, confirmPassword, passwordError }));
    };

    const handleChangeCitySelector = (value) => {
        setFormState((prevState) => ({ ...prevState, valueCitySelector: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formState.password !== formState.confirmPassword) {
            setFormState((prevState) => ({ ...prevState, passwordError: "Les mots de passe ne correspondent pas" }));
            return;
        }

        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        if (!passwordPattern.test(formState.password)) {
            setFormState((prevState) => ({ ...prevState, passwordError: "Le mot de passe doit comporter au moins 8 caractères, dont 1 majuscule, 1 minuscule, 1 chiffre et 1 ponctuation" }));
            return;
        }

        const form = e.target;
        const newUser = {
            lastName: form[0].value,
            firstName: form[1].value,
            phoneNumber: form[2].value,
            email: form[3].value,
            password: form[4].value,
            userLevels: formState.selectedStatus ? formState.selectedStatus.value : null,
            general_conditions: false,

            pressAgency: formState.selectedStatus && (formState.selectedStatus.value === 2 || formState.selectedStatus.value === 3) ? form.querySelector('input[placeholder="Organe de presse"]').value : null,
            redactors: formState.selectedRedactors.map(redactor => redactor.value),

            companyName: formState.selectedStatus && formState.selectedStatus.value === 5 ? form.querySelector('#companyName').value : null,
            address: formState.selectedStatus && formState.selectedStatus.value === 5 ? form.querySelector('#adress').value : null,
            assignedCities: formState.selectedStatus && formState.selectedStatus.value === 5 ? formState.valueCitySelector.map(city => city.value) : null,

        };

        if (formState.selectedStatus) {
            if (formState.selectedStatus.value === 1 || formState.selectedStatus.value === 4 ){
                formState.compressedLogo = null;
                newUser.pressAgency = null;
                newUser.assignedCities = null;
                newUser.redactors = null;
                newUser.companyName = null;
                newUser.address = null;

            } else if (formState.selectedStatus.value === 2) {
                newUser.assignedCities = null;
            } else if (formState.selectedStatus.value === 3) {
                newUser.redactors = null;
            }
        }

        setFormState((prevState) => ({ ...prevState, tempNewUser: newUser }));
        if (formState.compressedLogo) {
            dispatch(await setLogoPartner(formState.compressedLogo));
        } else {
            dispatch(createUser(newUser));
            resetForm();
        }
    };

    const resetForm = () => {
        setFormState({
            logoNewUser: "",
            compressedLogo: null,
            selectedStatus: null,
            isFormValid: false,
            password: "",
            confirmPassword: "",
            valueCitySelector: [],
            passwordError: "",
            selectedRedactors: [],
            tempNewUser: null,
        });
        document.getElementById("form-new-user").reset();
        dispatch(resetReducer());
    };

    useEffect(() => {
        if (formState.tempNewUser && contentNewUser.logo) {
            const newUser = { ...formState.tempNewUser, logoPressAgency: contentNewUser.logo };
            dispatch(createUser(newUser));
            resetForm();
        }
    }, [contentNewUser.logo, formState.tempNewUser, dispatch]);

    return (
        <section className="admin-new-user">
            <h2>Création d'un nouvel utilisateur</h2>
            <form id="form-new-user" onSubmit={handleSubmit} className="form-admin-new-user">
                <div className="info-new-user">
                    <div className="info-personnels-new-user">
                        <h3>Informations personnelles</h3>
                        <input type="text" placeholder="Nom" autoComplete="new-lastname" required />
                        <input type="text" placeholder="Prénom" autoComplete="new-firstname" required />
                        <input type="tel" placeholder="Numéro de téléphone" autoComplete="new-tel" required={!formState.selectedStatus || formState.selectedStatus.value !== 1} pattern="[0-9]{10}" />
                        <input type="email" placeholder="Adresse e-mail" autoComplete="new-address-email" required={!formState.selectedStatus || formState.selectedStatus.value !== 1} />
                        <input type="password" placeholder="Mot de passe" autoComplete="new-password" required onPaste={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()} onChange={handlePasswordChange} />
                        <input type="password" placeholder="Confirmer le mot de passe" autoComplete="new-password" required onPaste={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()} onChange={handleConfirmPasswordChange} />
                        {formState.passwordError && <p style={{ color: "red" }}>{formState.passwordError}</p>}
                    </div>

                    <ProfessionalInfo
                        formState={formState}
                        handleStatusChange={handleStatusChange}
                        handleChangeCitySelector={handleChangeCitySelector}
                        handleLogoUpload={handleLogoUpload}
                        handleRedactorsChange={handleRedactorsChange}
                        dataUser={dataUser}
                        customStylesCitySelector={customStylesCitySelector}
                    />
                </div>
                <input
                    type="submit"
                    value={`Créer un ${formState.selectedStatus ? formState.selectedStatus.label : "utilisateur"}`}
                    style={{
                        backgroundColor: formState.isFormValid ? "#e2b259" : "black",
                        color: formState.isFormValid ? "black" : "white",
                    }}
                />
            </form>
        </section>
    );
};

export default AdminNewUser;