import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateUser} from "../actions/user.action";
import { getUser } from "../actions/user.action";
import Select from "react-select";
import CitySelector from "../components/CitySelector";
import {pictureCompressor} from "../assets/utils/pictureCompressor";
import {deleteElement, setLogoPartner} from "../actions/partnerUploads.action";

const AdminUpdateUser = ({user, closePopup}) => {
    const [updatingUser, setUpdatingUser] = useState(user);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [assignedCities, setAssignedCities] = useState([]);
    const [logoPressAgency, setLogoPressAgency] = useState(user.logoPressAgency || null);
    const [selectedRedactors, setSelectedRedactors] = useState([]);
    const contentNewUser = useSelector((state) => state.partnerUploadsReducer);
    const dispatch = useDispatch();
    const dataUser = useSelector((state) => state.userReducer);
    const [formState, setFormState] = useState({
        logoNewUser: "",
        compressedLogo: null,
        updateUser: null,
    });


    useEffect(() => {
        setUpdatingUser(user);
        setSelectedStatus(user.userLevels ? {
            value: user.userLevels[0],
            label: getStatusLabel(user.userLevels[0])
        } : null);
        setAssignedCities(user.assignedCities || []);
        setSelectedRedactors(getRedactorNames(user.redactors));
        setLogoPressAgency(user.logoPressAgency || null);
    }, [user]);

    useEffect(() => {
        let tempDefaultCity = [];
        if (user.assignedCities) {
            user.assignedCities.forEach((city) => {
                tempDefaultCity.push({
                    label: city,
                    value: city,
                });
            });
        }
        setAssignedCities(tempDefaultCity);
    }, [user]);

    const getRedactorNames = (redactorIds) => {
        if (!redactorIds) return [];
        return redactorIds.map(id => {
            const redactor = dataUser.find(user => user._id === id);
            return redactor ? {value: redactor._id, label: `${redactor.lastName} ${redactor.firstName}`} : null;
        }).filter(redactor => redactor !== null);
    };

    const handleRefreshUsers = () => {
        dispatch(getUser());
    };


    const handleChangeCitySelector = (values) => {
        setAssignedCities(values);
        let tempCity = [];
        values.forEach((city) => {
            tempCity.push(city.value);
        });
        setUpdatingUser({
            ...updatingUser,
            city: tempCity,
        });
    };

    const getStatusLabel = (level) => {
        switch (level) {
            case 1:
                return "Administrateur global";
            case 2:
                return "Superviseur";
            case 3:
                return "Rédacteur";
            default:
                return "";
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (password !== e.target.value) {
            setPasswordError("Les mots de passe ne correspondent pas");
        } else {
            setPasswordError("");
        }
    };

    const handleStatusChange = (selectedOption) => {
        setSelectedStatus(selectedOption);
    };


    const handleLogoUpload = async (e) => {
        const file = e.target.files[0];
        const formDataLogo = await pictureCompressor(file);
        setFormState((prevState) => ({
            ...prevState,
            compressedLogo: formDataLogo,
            logoNewUser: URL.createObjectURL(file),
        }));
    };

    const handleRedactorsChange = (selectedOptions) => {
        setSelectedRedactors(selectedOptions);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let updatedUser = {};
        let isChanged = false;


        if (password && password !== confirmPassword) {
            setPasswordError("Les mots de passe ne correspondent pas");
            return;
        }

        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        if (password && !passwordPattern.test(password)) {
            setPasswordError("Le mot de passe doit comporter au moins 8 caractères, dont 1 majuscule, 1 minuscule, 1 chiffre et 1 ponctuation");
            return;
        }

        if (updatingUser.lastName !== user.lastName) {
            updatedUser = {...updatedUser, lastName: updatingUser.lastName};
            isChanged = true;
        }
        if (updatingUser.firstName !== user.firstName) {
            updatedUser = {...updatedUser, firstName: updatingUser.firstName};
            isChanged = true;
        }
        if (updatingUser.phoneNumber !== user.phoneNumber) {
            updatedUser = {...updatedUser, phoneNumber: updatingUser.phoneNumber};
            isChanged = true;
        }
        if (updatingUser.email !== user.email) {
            updatedUser = {...updatedUser, email: updatingUser.email};
            isChanged = true;
        }
        if (updatingUser.userName !== user.userName) {
            updatedUser = {...updatedUser, userName: updatingUser.userName};
            isChanged = true;
        }
        if (password) {
            updatedUser = {...updatedUser, password};
            isChanged = true;
        }
        if (selectedStatus && selectedStatus.value !== user.userLevels[0]) {
            updatedUser = {...updatedUser, userLevels: [selectedStatus.value]};
            isChanged = true;
        }
        if (updatingUser.pressAgency !== user.pressAgency) {
            updatedUser = {...updatedUser, pressAgency: updatingUser.pressAgency};
            isChanged = true;
        }
        if (assignedCities !== user.assignedCities) {
            updatedUser = {...updatedUser, assignedCities: assignedCities.map(city => city.value)};
            isChanged = true;
        }
        if (selectedRedactors !== user.redactors) {
            updatedUser = {...updatedUser, redactors: selectedRedactors.map(redactor => redactor.value)};
            isChanged = true;
        }

        if (selectedStatus) {
            if (selectedStatus.value === 1) {
                updatedUser = {...updatedUser, pressAgency: null};
                updatedUser = {...updatedUser, assignedCities: null};
                updatedUser = {...updatedUser, redactors: null};
                updatedUser = {...updatedUser, logoPressAgency: null };
                setFormState((prevState) => ({ ...prevState, compressedLogo: null }));
                dispatch(await deleteElement(user.logoPressAgency));
            } else if (selectedStatus.value === 2) {
                updatedUser = {...updatedUser, assignedCities: null};
            } else if (selectedStatus.value === 3) {
                updatedUser = {...updatedUser, redactors: null};
            }
        }

        if (formState.compressedLogo && selectedStatus.value !== 1) {
            if(user.logoPressAgency){
                dispatch(await deleteElement(user.logoPressAgency));
            }
            dispatch(await setLogoPartner(formState.compressedLogo));
            isChanged = true;
            updatedUser = {...updatedUser, _id: user._id};
            setFormState((prevState) => ({ ...prevState, updateUser: updatedUser }));
        }

        if (isChanged && !formState.compressedLogo) {
            updatedUser = {...updatedUser, _id: user._id};
            dispatch(updateUser(updatedUser));
            handleRefreshUsers();
            closePopup();
        }


    };

    useEffect(() => {
        if (formState.updateUser && contentNewUser.logo) {
            console.log("photo");
            const updatedUser = { ...formState.updateUser, logoPressAgency: contentNewUser.logo };
            dispatch(updateUser(updatedUser));
            closePopup();
        }
    }, [contentNewUser.logo, formState.updateUser, dispatch]);

    return (
        <section className="popup">
            <div className="popup-update-container">
                <div className="popup-update-header">
                    <h2>Modifier un utilisateur</h2>
                    <div className="cancel-button" onClick={closePopup}>
                        <p>Annuler</p>
                        <i className="bx bx-x-circle"></i>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className="form-admin-update-user">
                    <section className="user-info">
                        <h3>Informations personnelles</h3>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="lastName">Nom :</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    value={updatingUser.lastName}
                                    onChange={(e) => setUpdatingUser({...updatingUser, lastName: e.target.value})}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="firstName">Prénom :</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    value={updatingUser.firstName}
                                    onChange={(e) => setUpdatingUser({...updatingUser, firstName: e.target.value})}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="phoneNumber">Numéro de téléphone :</label>
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    value={updatingUser.phoneNumber}
                                    onChange={(e) => setUpdatingUser({...updatingUser, phoneNumber: e.target.value})}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Adresse e-mail :</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={updatingUser.email}
                                    onChange={(e) => setUpdatingUser({...updatingUser, email: e.target.value})}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="userName">Nom d'utilisateur :</label>
                                <input
                                    type="text"
                                    id="userName"
                                    value={updatingUser.userName}
                                    onChange={(e) => setUpdatingUser({...updatingUser, userName: e.target.value})}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Mot de passe :</label>
                                <input
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="confirmPassword">Confirmer le mot de passe :</label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                />
                                {passwordError && <p style={{color: "red"}}>{passwordError}</p>}
                            </div>
                        </div>
                    </section>
                    <section className="info-professionels">
                        <h3>Informations professionnelles</h3>
                        <div className="form-group">
                            <label htmlFor="status">Statut :</label>
                            <Select
                                options={[
                                    {value: 1, label: "Administrateur global"},
                                    {value: 2, label: "Superviseur"},
                                    {value: 3, label: "Rédacteur"},
                                ]}
                                value={selectedStatus}
                                onChange={handleStatusChange}
                            />
                        </div>

                        {(selectedStatus && (selectedStatus.value === 2 || selectedStatus.value === 3)) && (
                            <>
                                <div className="form-row">
                                    <div className="form-group">
                                        <label htmlFor="pressAgency">Organe de presse :</label>
                                        <input
                                            type="text"
                                            id="pressAgency"
                                            value={updatingUser.pressAgency}
                                            onChange={(e) => setUpdatingUser({...updatingUser, pressAgency: e.target.value})}

                                        />
                                    </div>
                                    {selectedStatus && selectedStatus.value === 3 && (
                                        <div className="form-group">
                                            <h3>Ville</h3>
                                            <CitySelector
                                                onChange={handleChangeCitySelector}
                                                value={assignedCities}
                                                isMulti={true}
                                                placeholder={"Sélectionnez une ou plusieurs villes"}
                                            />
                                        </div>
                                    )}
                                    {selectedStatus && selectedStatus.value === 2 && (
                                        <div className="form-row">
                                            <div className="form-group">
                                                <label htmlFor="redactors">Rédacteurs liés :</label>
                                                <Select
                                                    options={dataUser
                                                        .filter((user) => Array.isArray(user.userLevels) && user.userLevels.includes(3))
                                                        .map((user) => ({
                                                            value: user._id,
                                                            label: `${user.lastName} ${user.firstName}`,
                                                        }))}
                                                    value={selectedRedactors}
                                                    onChange={handleRedactorsChange}
                                                    isMulti={true}
                                                    placeholder="Sélectionnez un ou plusieurs rédacteurs"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="popup-update-content-container">
                                    <label htmlFor="logo-content">
                                        {logoPressAgency !== null  ? "Ajouter un logo pour l'organe de presse" : "Modifier le logo de l'organe de presse"}
                                    </label>
                                    <input
                                        type="file"
                                        id="logo-content"
                                        name="logo-content"
                                        onChange={handleLogoUpload}
                                        hidden
                                    />
                                    {formState.logoNewUser && (
                                        <section className="uploaded-logo">
                                            <h3>Logo ajouté</h3>
                                            <img
                                                src={formState.logoNewUser}
                                                alt="Logo ajouté"
                                                title="logo actuel du message"
                                                width={200}
                                            />
                                        </section>
                                    )}
                                    {(!formState.logoNewUser && logoPressAgency) && (
                                        <section className="uploaded-logo">
                                            <h3>Logo ajouté</h3>
                                            <img
                                                src={
                                                    "https://api.lesbonsplans.pro" +
                                                    "/upload/partner/image/" +
                                                    logoPressAgency
                                                }
                                                alt="Logo ajouté"
                                                title="logo actuel du message"
                                                width={200}
                                            />
                                        </section>
                                    )}
                                </div>
                            </>
                        )}
                    </section>
                    <div className="form-row">
                        <input type="submit"
                               value="Mettre à jour l'utilisateur"
                               id={formState.isFormValid ? "valid" : "invalid"}/>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default AdminUpdateUser;