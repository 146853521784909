import React from "react";
import BasePagesBackoffice from "./BasePagesBackoffice";

const HomeAdmin = () => {
    return (
        <BasePagesBackoffice navPath={"accueil"} stringBtn={"Retourner à la page d'accueil"}>
            <section className="home-admin">
                <h2>
                    Bienvenue dans votre espace d'administration. Choisissez une catégorie
                    pour commencer.
                </h2>
            </section>
        </BasePagesBackoffice>
    );
};

export default HomeAdmin;
