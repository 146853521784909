import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {getDistance} from "geolib";
import PaginatedList from '../components/PaginatedList';
import useLocation from '../assets/utils/useLocation';
import InterviewListEntry from "../components/InterviewListEntry";
import BasePages from "./BasePages";

const InterviewList = () => {
    const currentCity = useSelector((state) => state.cityReducer);
    const partners = useSelector((state) => state.partnerReducer);
    const currentLocation = useLocation();
    const [sortedPartners, setSortedPartners] = useState([]);

    useEffect(() => {
        if (partners.length > 0) {
            const filteredPartners = partners.filter(partner => partner.city === currentCity._id);
            let partnersWithDistance = filteredPartners.filter((partner) => partner.interviewName && partner.isActive);

            if (currentLocation) {
                const partnersWithCoordinates = partnersWithDistance.filter(partner => partner.coordinates && partner.coordinates[0] != null && partner.coordinates[1] != null);
                const partnersWithoutCoordinates = partnersWithDistance.filter(partner => !partner.coordinates || partner.coordinates[0] == null || partner.coordinates[1] == null);

                const sortedPartnersWithCoordinates = partnersWithCoordinates.map((partner) => {
                    const distance = getDistance(
                        {latitude: currentLocation.latitude, longitude: currentLocation.longitude},
                        {latitude: partner.coordinates[0], longitude: partner.coordinates[1]}
                    ) / 1000;
                    return {...partner, distance};
                }).sort((a, b) => a.distance - b.distance);

                partnersWithDistance = [...sortedPartnersWithCoordinates, ...partnersWithoutCoordinates];
            }

            setSortedPartners(partnersWithDistance);
        }
    }, [partners, currentLocation, currentCity]);

    return (
        <BasePages>
            <h1>À la rencontre de ...</h1>
            <section className="interview-list">
                <PaginatedList
                    items={sortedPartners}
                    renderItem={(partner) => <InterviewListEntry key={partner._id} interview={partner}/>}
                />
            </section>
        </BasePages>
    );
};

export default InterviewList;