import React, {useEffect, useState} from "react";
import AdminNewUser from "../layouts/AdminNewUser";
import AdminUpdateUser from "../layouts/AdminUpdateUser";
import {useSelector, useDispatch} from "react-redux";
import {getUser, deleteUser} from "../actions/user.action";
import AdminTable from "../components/AdminTable";
import BasePagesBackoffice from "./BasePagesBackoffice";

const AdminUsers = () => {
    const dataUser = useSelector((state) => state.userReducer);
    const dataCity = useSelector((state) => state.cityReducer);
    const [users, setUsers] = useState(dataUser);
    const [selectedUser, setSelectedUser] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setUsers(dataUser);
    }, [dataUser]);

    const handleRefreshUsers = () => {
        dispatch(getUser());
        window.alert("Tableau des utilisateurs actualisé.");
    };

    const handleEditUser = (user) => {
        setSelectedUser(user);
    };

    const closeUpdateUserModal = () => {
        setSelectedUser(null);
        dispatch(getUser());
    };

    const handleDeleteClick = (user) => {
        dispatch(deleteUser(user));
        setUsers(users.filter(u => u._id !== user._id));
    };

    const columns = [
        {header: "Nom", render: (user) => user.lastName},
        {header: "Prénom", render: (user) => user.firstName},
        {header: "Nom d'utilisateur", render: (user) => user.userName},
        {
            header: "Villes attitrées",
            render: (user) => Array.isArray(user.assignedCities) && user.assignedCities.length > 0
                ? user.assignedCities
                    .map((city) => {
                        const cityData = dataCity.find((data) => data.name === city);
                        return cityData ? cityData.acronym : "";
                    })
                    .filter((city) => city !== "")
                    .join(", ")
                : "Pas de ville"
        },
        {
            header: "Niveaux d'administration",
            render: (user) => Array.isArray(user.userLevels) && user.userLevels.length > 0
                ? user.userLevels
                    .map((level) => {
                        switch (level) {
                            case 1:
                                return "Administrateur global";
                            case 2:
                                return "Superviseur";
                            case 3:
                                return "Rédacteur";
                            case 4:
                                return "Particulier";
                            case 5:
                                return "Professionnel";
                            default:
                                return "";
                        }
                    })
                    .join(", ")
                : <span style={{color: "red"}}>Merci d'actualiser la page</span>
        },
        {
            header: "Modifications",
            render: (user) => <button onClick={() => handleEditUser(user)}>Modifier</button>
        },
        {
            header: "Suppression",
            render: (user) => <button onClick={() => handleDeleteClick(user)}>Supprimer</button>
        }
    ];

    return (
        <BasePagesBackoffice navPath={"accueil"} stringBtn={"Retourner à la page d'accueil"}>
            <section className="admin-users">
                <AdminNewUser/>
                <section className="user-management">
                    <h2>Gestion des utilisateurs</h2>
                    <button className="btn-refresh" onClick={handleRefreshUsers}>
                        Actualiser <i className="bx bx-refresh"></i>
                    </button>
                    <AdminTable
                        columns={columns}
                        data={users}
                        onEdit={handleEditUser}
                        onDelete={handleDeleteClick}
                        itemName="cet utilisateur"
                    />
                </section>
                {selectedUser && <AdminUpdateUser user={selectedUser} closePopup={closeUpdateUserModal} />}
            </section>
        </BasePagesBackoffice>
    );
};

export default AdminUsers;