import {
  GET_ALBUM,
  SET_ALBUM,
  UPDATE_ALBUM,
  DELETE_ALBUM,
} from "../actions/album.action";

const initialState = [];

export default function albumReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALBUM:
      return action.payload;
    case SET_ALBUM:
      return [action.payload, ...state];
    case UPDATE_ALBUM:
      return state.map((album) =>
        album._id === action.payload._id ? action.payload : album
      );
    case DELETE_ALBUM:
      return state.filter((album) => album._id !== action.payload);
    default:
      return state;
  }
}