import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const useLocation = () => {
    const [currentLocation, setCurrentLocation] = useState(null);

    const collectDataLocation = (pos) => {
        const crd = pos.coords;
        const data = {
            latitude: crd.latitude,
            longitude: crd.longitude,
        };
        Cookies.set("currentLocation", JSON.stringify(data), { expires: 1/96 }); // 15 minutes
        setCurrentLocation(data);
    };

    const checkCookieForLocation = () => {
        const savedLocation = Cookies.get("currentLocation");
        if (savedLocation) {
            const { latitude, longitude } = JSON.parse(savedLocation);
            setCurrentLocation({ latitude, longitude });
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (!checkCookieForLocation()) {
            navigator.geolocation.getCurrentPosition(collectDataLocation, () => setCurrentLocation(null));
        }
    }, []);

    return currentLocation;
};

export default useLocation;