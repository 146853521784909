import React from "react";
import {NavLink} from "react-router-dom";
import QRCode from "../assets/images/QRCode-LesBonsPlans.png";
import BasePages from "./BasePages";

const Contact = () => {

    return (
        <BasePages navPath="suivez-nous" stringBtn="Retourner aux contacts">
            <section className="contact">
                <div className="QRCode">
                    <h1>Pour télécharger l'application :</h1>
                    <img
                        src={QRCode}
                        alt="QRCode Les Bons Plans"
                    />
                </div>
                <h1>Pour nous contacter :</h1>
                <a href="mailto:info@viben.fr">
                    <i className="bx bx-mail-send"></i> Envoyez-nous un mail à info@viben.fr
                </a>
                <a href="https://www.google.com/maps/place/LA+GRANDE+MOTTE+ET+SES+BONS+PLANS/@43.5591771,4.0821515,17z/data=!4m14!1m7!3m6!1s0x12b6978ccdb52c7f:0xd2d41c96d84a104!2sLA+GRANDE+MOTTE+ET+SES+BONS+PLANS!8m2!3d43.5591771!4d4.0847318!16s%2Fg%2F11g62jn7wv!3m5!1s0x12b6978ccdb52c7f:0xd2d41c96d84a104!8m2!3d43.5591771!4d4.0847318!16s%2Fg%2F11g62jn7wv?entry=ttu">
                    <i className="bx bxs-been-here"></i>Editeur Responsable :
                    <br/>VIBEN
                    <br/>Résidence LE JEAN BART,
                    <br/>Bat B / Rdc
                    <br/>25, Quai Charles de Gaulle
                    <br/>F-34280 La Grande-Motte
                    <br/>France
                </a>
                <NavLink to="/politique-confidentialité">
                    <i className="bx bx-spreadsheet"></i>Consultez notre politique de
                    confidentialité
                </NavLink>
            </section>
        </BasePages>
    );
};

export default Contact;