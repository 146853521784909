import React from 'react';
import { NavLink } from "react-router-dom";

const MessageListEntry = ({ message, isMostRecentMessageRead, handleClick, truncateText }) => {
    const mostRecentMessage = message;

    return (
        <div key={message._id} className="message-entry">
            <NavLink to={"/message"} state={message} onClick={() => handleClick(message._id)}>
                <h3 style={{ color: !isMostRecentMessageRead() && message._id === mostRecentMessage._id ? 'red' : 'white' }}>
                    {message.title}
                </h3>
                <div className="message-content">
                    <img
                        src={"https://api.lesbonsplans.pro/upload/partner/image/" + message.logo}
                        alt="image visite"
                        loading="lazy"
                    />
                    <p>{truncateText(message.content, 300)}</p>
                </div>
                <div className="read-more">
                    Lire la suite
                    <i className="arrow"></i>
                </div>
                <div className="publication-date">
                    Publié le {new Date(message.datePublication).toLocaleDateString()}
                </div>
            </NavLink>
        </div>
    );
};

export default MessageListEntry;