export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const LOAD_CART_FROM_LOCAL_STORAGE = "LOAD_CART_FROM_LOCAL_STORAGE"; // Nouvelle action

export const addToCart = (item) => ({
    type: ADD_TO_CART,
    payload: item,
});

export const removeFromCart = (item) => ({
    type: REMOVE_FROM_CART,
    payload: item,
});

export const clearCart = () => ({
    type: CLEAR_CART,
});

export const updateCartItem = (item) => ({
    type: UPDATE_CART_ITEM,
    payload: item,
});

export const loadCartFromLocalStorage = () => ({
    type: LOAD_CART_FROM_LOCAL_STORAGE, // Action pour charger le panier depuis le localStorage
});