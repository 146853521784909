import React, {useEffect, useState} from "react";
import axios from "axios";
import {isEmpty} from "../assets/Utils";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteAudioGuidedTour,
    resetAudioGuidedTour,
    setAudioGuidedTour,
} from "../actions/contentGuidedTour.action";
import {
    deletePictureGuidedTour,
    resetPictureGuidedTour,
    setPictureGuidedTour,
} from "../actions/pictureGuidedTour.action";
import {setGuidedTour} from "../actions/guidedTour.action";
import {pictureCompressor} from "../assets/utils/pictureCompressor";

const AdminNewGuidedTour = ({resetPopup}) => {
    const [dataCountries, setDataCountries] = useState([]);
    const [searchCountry, setSearchCountry] = useState("");
    const [selectedCountry, setSelectedCountry] = useState([]);
    const pictureNewGuidedTour = useSelector(
        (state) => state.pictureGuidedTourReducer
    );
    const [currentCountryUploaded, setCurrentCountryUploaded] = useState("");
    const audioUploaded = useSelector((state) => state.contentGuidedTourReducer);
    const [allAudioUploaded, setAllAudioUploaded] = useState({});
    const [isAppleDevice, setIsAppleDevice] = useState(false);
    const dispatch = useDispatch();

    const handleRemoveCountry = (country) => {
        setSelectedCountry(selectedCountry.filter((c) => c.cca3 !== country.cca3));
    };

    useEffect(() => {
        if (!isEmpty(audioUploaded)) {
            setAllAudioUploaded({
                ...allAudioUploaded,
                [currentCountryUploaded]: audioUploaded,
            });
        }
    }, [audioUploaded]);

    useEffect(() => {
        axios
            .get("https://restcountries.com/v3.1/all")
            .then((res) => setDataCountries(res.data));
    }, []);

    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const appleDevices = ["iphone", "ipad", "macintosh"];
        setIsAppleDevice(appleDevices.some((device) => userAgent.includes(device)));
    }, []);

    const handlePictureUploaded = async (e) => {
        const picture = e.target.files[0];
        let formData = await pictureCompressor(picture);
        dispatch(await deletePictureGuidedTour(pictureNewGuidedTour));
        dispatch(await setPictureGuidedTour(formData));
    };

    const audioCompressor = async (audio) => {
        console.log(`Taille de l'audio avant compression : ${audio.size / 1024} Ko`);

        const formData = new FormData();
        formData.append("file", audio);
        formData.append("upload_preset", "my_audio_preset");
        formData.append("resource_type", "video");

        try {
            const response = await axios.post(
                "https://api.cloudinary.com/v1_1/dzyujvgoj/video/upload",
                formData
            );

            console.log("Audio compressé disponible à :", response.data.secure_url);
            return response.data.secure_url;
        } catch (err) {
            console.error("Erreur de compression audio :", err);
            throw new Error("Erreur lors de la compression de l'audio.");
        }
    };

    const handleAudioUploaded = async (e, countryCca3) => {
        const audio = e.target.files[0];
        try {
            const compressedAudioUrl = await audioCompressor(audio);
            const response = await axios.get(compressedAudioUrl, {responseType: 'blob'});
            const compressedAudioFile = new File([response.data], audio.name, {type: audio.type});

            console.log(`Taille de l'audio après compression : ${compressedAudioFile.size / 1024} Ko`);

            const formData = new FormData();
            formData.append("file", compressedAudioFile);
            setCurrentCountryUploaded(countryCca3);

            dispatch(setAudioGuidedTour(formData));
        } catch (error) {
            console.error("Erreur lors du traitement de l'audio :", error);
        }
    };

    const handleOpenAudio = (langCca3) => {
        const audioUrl =
            "https://api.lesbonsplans.pro" +
            "/upload/upload/audio/" +
            allAudioUploaded[langCca3];
        window.open(audioUrl, "_blank");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        let newGuidedTour = {
            name: form["name-guided-tour"].value,
            picture: pictureNewGuidedTour,
            contentAudio: allAudioUploaded,
        };

        newGuidedTour.coordinates = [
            parseFloat(form["latitude-guided-tour"].value.replace(",", ".")),
            parseFloat(form["longitude-guided-tour"].value.replace(",", ".")),
        ];

        dispatch(setGuidedTour(newGuidedTour));
        dispatch(resetAudioGuidedTour());
        dispatch(resetPictureGuidedTour());
        resetPopup();
    };

    const handleDeleteAudioPicture = () => {
        selectedCountry.forEach((country) => {
            dispatch(deleteAudioGuidedTour(allAudioUploaded[country.cca3]));
        });
        dispatch(deletePictureGuidedTour(pictureNewGuidedTour));
    };

    return (
        <section className="popup">
            <div className="popup-update-container">
                <div className="popup-update-header">
                    <h2>Créer une visite guidée</h2>
                    <div className="cancel-button" onClick={() => {
                        handleDeleteAudioPicture();
                        resetPopup();
                    }}>
                        <p>Annuler</p>
                        <i className="bx bx-x-circle"></i>
                    </div>
                </div>
                <form autoComplete="off" onSubmit={handleSubmit} className="form-admin-new-guided-tour">
                    <section className="guided-tour-infos">
                        <div className="label-info-guided-tour-container">
                            <div className="label-input-guided-tour">
                                <label htmlFor="name-guided-tour">Nom de la visite</label>
                                <input
                                    type="text"
                                    id="name-guided-tour"
                                    name="name-guided-tour"
                                    required
                                />
                            </div>
                            <div className="label-input-guided-tour">
                                <label htmlFor="coordinates-guided-tour">Latitude du lieu</label>
                                <input
                                    type="text"
                                    id="latitude-guided-tour"
                                    name="latitude-guided-tour"
                                    required
                                />
                            </div>
                            <div className="label-input-guided-tour">
                                <label htmlFor="coordinates-guided-tour">Longitude du lieu</label>
                                <input
                                    type="text"
                                    id="longitude-guided-tour"
                                    name="longitude-guided-tour"
                                    required
                                />
                            </div>
                            <label className="label-btn" htmlFor="picture-guided-tour">
                                Importer une image d'illustration
                            </label>
                            <input
                                type="file"
                                id="picture-guided-tour"
                                name="picture-guided-tour"
                                accept="image/*"
                                onChange={handlePictureUploaded}
                                required
                                hidden
                            />
                        </div>
                        {!isEmpty(pictureNewGuidedTour) && (
                            <img
                                src={
                                    "https://api.lesbonsplans.pro" +
                                    "/upload/upload/image/" +
                                    pictureNewGuidedTour
                                }
                                alt="Image de la visite guidée"
                            />
                        )}
                    </section>
                    <section className="guided-tour-audio">
                        <div className="searchbar-country">
                            <label htmlFor="language-audio">Chercher un pays :</label>
                            <input
                                type="text"
                                id="language-audio"
                                value={searchCountry}
                                onChange={(e) => setSearchCountry(e.target.value)}
                            />
                        </div>
                        <div className="flags-container">
                            {dataCountries &&
                                dataCountries
                                    .filter((country) =>
                                        country.translations.fra.common
                                            .toLowerCase()
                                            .includes(searchCountry.toLowerCase())
                                    )
                                    .filter(
                                        (country) =>
                                            !selectedCountry.some((c) => c.cca3 === country.cca3)
                                    )
                                    .sort((a, b) => b.population - a.population)
                                    .slice(0, 30)
                                    .map((country) => (
                                        <div
                                            key={country.cca3}
                                            onClick={() => {
                                                setSelectedCountry([...selectedCountry, country]);
                                                setSearchCountry("");
                                            }}
                                        >
                                            <label htmlFor={country.cca3}>
                                                {country.translations.fra.common}
                                            </label>
                                            <img
                                                src={country.flags.png}
                                                alt={"Drapeau " + country.translations.fra.common}
                                            />
                                        </div>
                                    ))}
                        </div>
                        <h3>Ajoutez un audio pour les pays</h3>
                        <div className="audio-each-language">
                            {!isEmpty(selectedCountry) &&
                                selectedCountry.map((country) => (
                                    <div key={country.cca3}>
                                        <i
                                            onClick={() => handleRemoveCountry(country)}
                                            className="bx bx-x-circle"
                                        ></i>
                                        <div className="center-audio-country">
                                            <label htmlFor={country.cca3}>
                                                {country.translations.fra.common}
                                                <img
                                                    src={country.flags.png}
                                                    alt={"Drapeau " + country.translations.fra.common}
                                                />
                                            </label>
                                            {allAudioUploaded[country.cca3] && (
                                                <>
                                                    {isAppleDevice ? (
                                                        <button
                                                            onClick={() => handleOpenAudio(country.cca3)}
                                                            className="download-button"
                                                        >
                                                            ▶ Ouvrir l'audio
                                                        </button>
                                                    ) : (
                                                        <audio controls>
                                                            <source
                                                                src={
                                                                    "https://api.lesbonsplans.pro" +
                                                                    "/upload/upload/audio/" +
                                                                    allAudioUploaded[country.cca3]
                                                                }
                                                                type="audio/mp3"
                                                            />
                                                        </audio>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <input
                                            type="file"
                                            id={country.cca3}
                                            name={country.cca3}
                                            accept="audio/*"
                                            onChange={(e) => handleAudioUploaded(e, country.cca3)}
                                            hidden
                                            required
                                        />
                                    </div>
                                ))}
                        </div>
                    </section>
                    <input type="submit"
                           value="Créer la visite guidée"
                           id="valid"/>
                </form>
            </div>
        </section>
    );
};

export default AdminNewGuidedTour;