import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {isEmpty} from "../assets/Utils";
import BasePages from "./BasePages";
import MessageListEntry from "../components/MessageListEntry";

const MessagesList = () => {
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    const dataMessages = useSelector((state) => state.messageReducer);
    const [visibleCount, setVisibleCount] = useState(5);

    const currentDate = new Date();

    const filteredAndSortedMessages = dataMessages
        .filter((message) =>
            contentCurrentCity.messages.includes(message._id) &&
            new Date(message.datePublication) <= currentDate
        )
        .sort((a, b) => new Date(b.datePublication) - new Date(a.datePublication));

    const mostRecentMessage = filteredAndSortedMessages[0];

    const isMostRecentMessageRead = () => {
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('readMessageId='))
            ?.split('=')[1];
        return cookieValue === mostRecentMessage._id;
    };

    const handleClick = (messageId) => {
        if (messageId === mostRecentMessage._id) {
            const date = new Date();
            date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
            document.cookie = `readMessageId=${messageId}; expires=${date.toUTCString()}; path=/;`;
        }
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const loadMore = () => {
        setVisibleCount(prevCount => prevCount + 5);
    };

    return (
        <BasePages>
            <h1>Nos Messages</h1>
            <section className="message-list">
                {!isEmpty(contentCurrentCity.messages) &&
                    Array.isArray(dataMessages) &&
                    filteredAndSortedMessages.slice(0, visibleCount).map((message) => (
                        <MessageListEntry
                            key={message._id}
                            message={message}
                            isMostRecentMessageRead={isMostRecentMessageRead}
                            handleClick={handleClick}
                            truncateText={truncateText}
                        />
                    ))}
                {visibleCount < filteredAndSortedMessages.length && (
                    <button onClick={loadMore} className="load-more-button">Afficher plus</button>
                )}
            </section>
        </BasePages>
    );
};

export default MessagesList;