export const customStylesCitySelector = {
    control: (provided) => ({
        ...provided,
        margin: "20px 0",
        padding: "5px",
        backgroundColor: "#e2b259",
        border: "2px solid #fff",
        boxShadow: "none",
        transition: "0.2s ease",
        "&:hover": {
            border: "2px solid #ccc",
        },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#b1852d" : "#e2b259",
        color: state.isFocused ? "white" : "black",
        ":active": {
            backgroundColor: "white",
            color: "black",
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "black",
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: "white",
        transition: "0.2s ease",
        "&:hover": {
            color: "#ccc",
        },
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: "#e2b259",
        border: "2px solid white",
        borderRadius: "4px",
        marginTop: "4px",
        zIndex: 9999,
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "black",
        padding: "2px 8px",
        borderRadius: "4px",
    }),
};