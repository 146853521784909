import {
  CURRENT_CITY,
  GET_CITY,
  SET_CITY,
  UPDATE_CITY,
  DELETE_CITY
} from "../actions/city.action";

const initialState = {};

export default function cityReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CITY:
      return action.payload;
    case SET_CITY:
      return [action.payload, ...state];
    case UPDATE_CITY:
      return state.map((city) =>
        city._id === action.payload._id ? action.payload : city
      );
    case CURRENT_CITY:
      return action.payload;
    case  DELETE_CITY:
      return state.filter((city) => city._id !== action.payload);
    default:
      return state;
  }
}
