import React, {useState} from "react";
import {handleUserConnection, login} from "../actions/auth.action";
import store from "..";
import {getUser, getUserById} from "../actions/user.action";
import {useNavigate} from "react-router";
import {showFlashMessage} from "../actions/flashMessage.action";
import {useDispatch} from "react-redux";
import BasePagesBackoffice from "./BasePagesBackoffice";


const Connexion = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            await store.dispatch(handleUserConnection(email, password));
            if (store.getState().authReducer.auth.response) {
                const userId = store.getState().authReducer.auth.id;
                await store.dispatch(getUserById(userId));
                const user = store.getState().userReducer;
                store.dispatch(login(user));
                store.dispatch(getUser());
                localStorage.removeItem('selectedTypeUser');
                dispatch(showFlashMessage(
                    "Connexion réussie!",
                    "green",
                    5000,
                    "/administration/accueil"
                ));
                return;
            }
            dispatch(showFlashMessage(
                store.getState().authReducer.auth.message,
                "red"
            ));
        } catch (error) {
            dispatch(showFlashMessage(
                "Une erreur est survenue lors de la connexion",
                "red"
            ));
        }
    };

    const handleForgotPassword = () => {
        navigate("/mot-de-passe-oublie");
    };

    const handleCreateAccount = () => {
        navigate("/creation-du-compte");
    };

    return (
        <BasePagesBackoffice navBarAdmin={false}>
            <section className="connexion">
                <h2>Connexion</h2>
                    <form onSubmit={(e) => handleFormSubmit(e)}
                    className="form-connexion">
                        <input
                            type="email"
                            placeholder="Entrez votre adresse-email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Entrez votre mot de passe"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <input
                            type="submit"
                            value="Se connecter"
                            disabled={!email || !password}
                            id={email && password ? "valid" : "invalid"}

                        />
                    </form>
                    <div className="form-links">
                        <p onClick={handleForgotPassword}>Mot de passe oublié ?</p>
                        <p onClick={handleCreateAccount}>Créer un compte</p>
                    </div>
            </section>
        </BasePagesBackoffice>
    );
};

export default Connexion;