import axios from "axios";
import { updateCity } from "./city.action";
import { deleteGuidedTour, getGuidedTourById } from "./guidedTour.action";

export const GET_CONTENTCITY = "GET_CONTENTCITY";
export const GET_CONTENTCITY_BY_ID = "GET_CONTENTCITY_BY_ID";
export const SET_CONTENTCITY = "SET_CONTENTCITY";
export const UPDATE_CONTENTCITY = "UPDATE_CONTENTCITY";
export const DELETE_CONTENTCITY = "DELETE_CONTENTCITY";

const API_URL = process.env.REACT_APP_API_URL;

export const getContentCity = () => {
    return async (dispatch) => {
        return axios
            .get(`${API_URL}/contentCity`)
            .then((res) => {
                dispatch({ type: GET_CONTENTCITY, payload: res.data });
            })
            .catch((err) => console.log(err));
    };
};

export const getContentCityById = (contentCityId) => {
    return async (dispatch) => {
        return axios
            .get(`${API_URL}/contentCity/${contentCityId}`)
            .then((res) => {
                dispatch({ type: GET_CONTENTCITY_BY_ID, payload: res.data });
            })
            .catch((err) => console.log(err));
    };
};

export const setContentCity = (contentCity, cityId) => {
    return async (dispatch) => {
        return axios
            .post(`${API_URL}/contentCity`, contentCity)
            .then((res) => {
                const newContentCity = res.data;
                dispatch({ type: SET_CONTENTCITY, payload: newContentCity });
                dispatch(updateCity({ _id: cityId, contentCity: newContentCity._id }));
            })
            .catch((err) => console.log(err));
    };
};

export const updateContentCity = (contentCity) => {
    return async (dispatch) => {
        return axios
            .put(`${API_URL}/contentCity/${contentCity._id}`, contentCity)
            .then((res) => {
                dispatch({
                    type: UPDATE_CONTENTCITY,
                    payload: { ...res.data, ...contentCity },
                });
            })
            .catch((err) => console.log(err));
    };
};

export const deleteContentCity = (contentCity) => {
    return async (dispatch) => {
        try {
            if (Array.isArray(contentCity.guidedTour)) {
                console.log(contentCity.guidedTour);
                for (const upload of contentCity.guidedTour) {
                    const guidedTour = await dispatch(getGuidedTourById(upload));
                    dispatch(deleteGuidedTour(guidedTour));
                }
            } else {
                console.warn("contentCity.guidedTour is not an array:", contentCity.guidedTour);
            }

            await axios.delete(`${API_URL}/contentCity/${contentCity._id}`);
            dispatch({
                type: DELETE_CONTENTCITY,
                payload: contentCity._id,
            });
        } catch (err) {
            console.log(err);
        }
    };
};